/** @jsxImportSource @emotion/react */
import LaunchIcon from '@mui/icons-material/Launch';
import { useNavigate } from 'react-router-dom';

const SERVICES_NAME = ['plicar', 'plicarzero', 'payment', '3dpresso'];

const Dashboard = (props: any) => {
  /*----------------------------------------
								Default Template
	 ----------------------------------------*/
  return (
    <div css={{ padding: '24px', height: '100%' }}>
      <h4
        css={{
          fontSize: '34px',
          fontWeight: '400',
          fontStyle: 'normal',
          fontFamily: 'Noto Sans, sans-serif',
          lineHeight: '123.5%',
          letterSpacing: '0.25px',
          color: 'rgba(0, 0, 0, 0.87)',
        }}
      >
        서비스 현황
      </h4>
      <div
        css={{
          marginTop: '38px',
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
        }}
      >
        {/* 각 서비스들의 정보는 constanst/admintoolConfig 에서 변경해야합니다 */}
        {SERVICES_NAME.map((serviceName: string, idx: number) => (
          <ServiceCard key={idx} serviceName={serviceName} status={''} serviceInfo={''} mau={''} />
        ))}
      </div>
    </div>
  );
};

export default Dashboard;

type ServiceCardProps = {
  serviceName: string;
  status: string;
  serviceInfo?: string;
  mau?: string;
};

const ServiceCard = (props: ServiceCardProps) => {
  const navigate = useNavigate();

  const openService = (serviceName: any) => {
    if (serviceName === 'payment') {
      navigate('/admin/dashboard/payment');
    } else if (serviceName === '3dpresso') {
      navigate('/admin/dashboard/3dpresso');
    } else {
      navigate(`/admin/dashboard/detail?serviceName=${serviceName}`);
    }
  };
  
  return (
    <div
      css={{
        border: '1px solid #000000',
        width: '396px',
        height: '206px',
        display: 'inline-block',
      }}
    >
      <div css={{ padding: '15px' }}>
        <div
          css={{
            fontSize: '24px',
            lineHeight: '32px',
            color: '#000000',
          }}
        >
          {props.serviceName}
          <span
            css={{ marginLeft: '5px', cursor: 'pointer' }}
            onClick={() => {
              openService(props.serviceName);
            }}
          >
            <LaunchIcon sx={{ color: '#00000068', width: '18px', height: '18px' }}></LaunchIcon>
          </span>
        </div>
        <div
          css={{
            marginTop: '24px',
            fontSize: '16px',
            lineHeight: '24px',
            color: '#000000',
          }}
        >
          <div css={{ display: 'flex', flexDirection: 'row' }}>
            <div css={{ flex: '1' }}>상태</div>
            <div css={{ flex: '1' }}>{props.status}</div>
          </div>
          <div css={{ marginTop: '24px', display: 'flex', flexDirection: 'row' }}>
            <div css={{ flex: '1' }}>서비스별 핵심정보</div>
            <div css={{ flex: '1' }}>{props.serviceInfo}</div>
          </div>
          <div css={{ marginTop: '24px', display: 'flex', flexDirection: 'row' }}>
            <div css={{ flex: '1' }}>MAU</div>
            <div css={{ flex: '1' }}>{props.mau}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
