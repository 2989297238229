import React, { useContext } from 'react';
import { UploadProgressContext } from '../context/UploadProgressContextProvider';

const UploadProgress = () => {
  const { uploadFiles } = useContext(UploadProgressContext);
  return (
    <div>
      <div>upload progress</div>
      <table>
        <thead>
          <tr>
            <th style={{ width: '200px', maxWidth: '200px' }}>user</th>
            <th style={{ width: '150px', maxWidth: '150px' }}>filename</th>
            <th style={{ width: '70px' }}>progress</th>
          </tr>
        </thead>
        <tbody>
          {uploadFiles.map((uploadFile: any, idx: number) => (
            <tr key={idx}>
              <td style={{ width: '200px', maxWidth: '200px', overflow: 'hidden' }}>{uploadFile.user.user_email}</td>
              <td style={{ width: '150px', maxWidth: '150px', overflow: 'hidden' }}>{uploadFile.file.name}</td>
              <td style={{ width: '70px' }}>{uploadFile.progress}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UploadProgress;
