/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';

type ServiceDetailTapberProps = {
  selectedTab: string;
  tapClicked: Function;
};

const ServiceDetailTapbar = (props: ServiceDetailTapberProps) => {
  const queryParams = new URLSearchParams(window.location.search);
  const [service, setService] = useState(queryParams.get('serviceName'));

  const tapClicked = (type: string) => {
    props.tapClicked(type);
  };

  return (
    <>
      <Tab tabName="status" tabText="현황" selectedTab={props.selectedTab} tapClicked={tapClicked} />
      <Tab tabName="maintenance" tabText="점검 관리" selectedTab={props.selectedTab} tapClicked={tapClicked} />
      <Tab tabName="email" tabText="이메일 발송" selectedTab={props.selectedTab} tapClicked={tapClicked} />
      <Tab tabName="popup" tabText="팝업 게시" selectedTab={props.selectedTab} tapClicked={tapClicked} />
      <Tab tabName="user" tabText="고객 관리" selectedTab={props.selectedTab} tapClicked={tapClicked} />
      {service === '3dpresso' && (
        <>
          <Tab tabName="showcase" tabText="쇼케이스" selectedTab={props.selectedTab} tapClicked={tapClicked} />
          <Tab tabName="bean" tabText="Bean" selectedTab={props.selectedTab} tapClicked={tapClicked} />
        </>
      )}
    </>
  );
};

const Tab = ({
  tabName,
  tabText,
  selectedTab,
  tapClicked,
}: {
  tabName: string;
  tabText: string;
  selectedTab: string;
  tapClicked: Function;
}) => {
  return (
    <div
      css={{
        display: 'inline-block',
        border: selectedTab === tabName ? '1px solid #000000' : '',
        width: '180px',
        textAlign: 'center',
        lineHeight: '70px',
        fontFamily: 'Noto Sans, sans-serif',
        fontWeight: '500',
        fontSize: '20px',
        letterSpacing: '0.15px',
        color: '#000000',
        cursor: 'pointer',
      }}
      onClick={() => tapClicked(tabName)}
    >
      {tabText}
    </div>
  );
};

export default ServiceDetailTapbar;
