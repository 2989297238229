import React, { useEffect, useRef, useState } from 'react';
import { User } from '../../../../../../api/3dpresso/user';
import { putBean, putBeanAllUser } from '../../../../../../api/3dpresso/bean';
import { Stage } from '../../../../../../types';
import { RDSDropdown, RDSInputField, RDSOption, RDSToast } from '@reconlabs/reconlabs-fe-components';

const BeanPutModal = ({ stage, user, closeModal }: { stage: Stage; user: any; closeModal: Function }) => {
  // const now = new Date()
  const [beanAmount, setBeanAmount] = useState(0);
  const [toast, setToast] = useState<string>('');
  const [hour, setHour] = useState(0);
  const [date, setDate] = useState<string>(``);
  const [ttl, setTtl] = useState<number>(new Date().getTime());
  const [detail, setDetail] = useState('');
  const hours = Array.from(Array(24).keys());
  const handleChange = (event: any) => {
    setBeanAmount(event.target.value);
  };
  const inputRef = useRef<any>(null);
  const handleClick = async () => {
    if (
      !confirm(
        `유저: ${user.user_email}에게 ${beanAmount}빈을 소멸기한: ${new Date(
          ttl,
        ).toUTCString()}, 세부사항:${detail} 으로 지급하시는게 맞나요?`,
      )
    ) {
      return;
    }

    try {
      setToast('bean_put_loading');
      if (user == true) {
        await putBeanAllUser(stage, beanAmount);
        alert('bean 충전메시지 전송 성공! 잠시만 기다리신후 결제내역을 확인하시면 충전이 완료될 예정입니다');
      } else {
        await putBean(stage, user.idx, beanAmount, ttl, detail);
        alert('bean 충전메시지 전송 성공! 잠시만 기다리신후 결제내역을 확인하시면 충전이 완료될 예정입니다');
      }
      setToast('');
      closeModal();
    } catch (error) {
      setToast('');
      console.error(error);
      alert('실패');
    }
  };
  useEffect(() => {
    setTtl(new Date(date).setUTCHours(hour));
  }, [hour, date]);

  useEffect(() => {
    console.log(inputRef.current);
    inputRef.current.focus();
  }, []);
  return (
    <div style={{ display: 'flex', flexDirection: 'column', minWidth: '250px' }}>
      <RDSToast.Overlay
        type="loadingSpinner"
        toastOverlayId="bean_put_loading"
        openedToastOverlay={toast}
        setOpenedToastOverlay={setToast}
      />
      <div style={{ padding: '10px' }}>bean 지급</div>
      {user === true ? (
        <div>
          <span style={{ fontWeight: 700, color: 'red' }}>유저 전부</span> <br />
          에게 bean을 지급하시겠습니까?
        </div>
      ) : (
        <div>
          <span style={{ fontWeight: 700, color: 'red' }}> {user && user.user_email}</span>
          <br />
          에게 bean을 지급하시겠습니까?
        </div>
      )}
      <br />
      <span style={{ color: 'blue' }}>소멸기한 (선택시 UTC기준)</span>
      <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <input
          style={{ width: '100px' }}
          type="date"
          value={date}
          onChange={(e) => {
            const date = e.currentTarget.value;
            setDate(date);
          }}
        />
        시간선택
        <RDSDropdown
          customStyles={{ width: '40px' }}
          size="small"
          value={hour}
          onChange={(value) => {
            setHour(Number(value));
          }}
        >
          {hours.map((hr) => (
            <RDSOption value={hr}>{hr}</RDSOption>
          ))}
        </RDSDropdown>
      </div>
      <br />
      <span style={{ color: 'blue' }}>bean 갯수</span>
      <br />
      <input ref={inputRef} type="number" value={beanAmount} onChange={handleChange} />
      <br />
      <span style={{ color: 'blue' }}>세부사항</span>
      <br />
      <RDSInputField.Text
        value={detail}
        // title={'고객 추가'}
        handleInputChange={(e) => {
          setDetail(e.target.value);
        }}
      ></RDSInputField.Text>{' '}
      <br />
      <button style={{ padding: '10px' }} onClick={handleClick}>
        Confirm
      </button>
    </div>
  );
};

export default BeanPutModal;
