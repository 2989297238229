import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { RDSButton, RDSModal, RDSToast, RDSToastAlertObject } from '@reconlabs/reconlabs-fe-components';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  model,
  changeShowcaseOrder,
  deleteShowcaseModel,
  getShowcaseList,
  addShowcaseModel,
} from '../../../../lib/showcase';
import { Stage } from '../../../../types';

const Showcase = ({ stage }: { stage: Stage }) => {
  const [modelList, setModelList] = useState<model[]>([]);
  const [isAddModelModalOpen, setIsAddModelModalOpen] = useState(false);
  const [isDeleteModelModalOpen, setIsDeleteModelModalOpen] = useState(false);
  const [selectedModel, setSelectedModel] = useState<model>();
  const [isHover, setIsHover] = useState('');
  const [loading, setLoading] = useState('');
  const [alert, setAlert] = useState<RDSToastAlertObject>();

  useEffect(() => {
    (async () => {
      setModelList(await getShowcaseList(stage));
    })();
  }, [stage]);

  //drag and drop으로 순서 변경시 재정렬
  const reorder = (list: model[], originIndex: number, destinationIndex: number): model[] => {
    //추출
    const result = Array.from(list);
    const [removed] = result.splice(originIndex, 1);
    result.splice(destinationIndex, 0, removed);

    //목적지 index에 따라 순서 재지정
    let newOrder;
    //맨앞
    if (destinationIndex - 1 < 0) newOrder = Math.ceil(result[destinationIndex + 1].order) - 1;
    //맨뒤
    else if (destinationIndex + 1 >= result.length) newOrder = Math.floor(result[destinationIndex - 1].order) + 1;
    //중간
    else newOrder = (result[destinationIndex - 1].order + result[destinationIndex + 1].order) / 2;

    result[destinationIndex].order = newOrder;
    return result;
  };

  //dragEnd(drop) event handler - 순서 변경
  const onDragEnd = async (result: any) => {
    if (!result.destination) return; // dropped outside the list
    const newModelList = reorder(modelList, result.source.index, result.destination.index);
    setModelList(newModelList);
    console.log(newModelList);
  };

  //순서 변경 저장(db 반영)
  const saveShowcase = async () => {
    setLoading('loading');
    const res = await changeShowcaseOrder(stage, modelList);
    console.log(res);
    setLoading('');
    setAlert({ type: 'success', message: '쇼케이스 저장 성공' });
  };

  const deleteHandler = async () => {
    if (!selectedModel) return;
    setLoading('loading');
    const res = await deleteShowcaseModel(stage, selectedModel.seq);
    if (res.result === 'success') {
      setModelList(modelList.filter((model) => model.seq !== selectedModel.seq));
    }
    setLoading('');
    setAlert({ type: 'success', message: '쇼케이스 모델 삭제 성공' });
    setIsDeleteModelModalOpen(false);
  };

  return (
    <div style={{}}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={{ padding: '20px 0px', display: 'flex', overflow: 'auto', maxWidth: '1100px' }}
            >
              {modelList.map((model: model, index) => (
                <Draggable key={model.seq} draggableId={model.seq} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getModelCardStyle(snapshot.isDragging, provided.draggableProps.style)}
                      onMouseEnter={() => setIsHover(model.seq)}
                      onMouseLeave={() => setIsHover('')}
                    >
                      <img
                        src={model.pngUrl}
                        style={{
                          height: '100px',
                          objectFit: 'cover',
                          marginBottom: '10px',
                          transition: 'transform 0.3s ease',
                        }}
                      />
                      {model.userName}
                      {isHover === model.seq && (
                        <button
                          style={getCloseButtonStyle()}
                          onClick={() => {
                            setSelectedModel(model);
                            setIsDeleteModelModalOpen(true);
                          }}
                        >
                          x
                        </button>
                      )}
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div style={{ display: 'flex', margin: '10px', gap: '10px' }}>
        <RDSButton color="grey_dark" onClick={() => setIsAddModelModalOpen(true)}>
          모델 추가
        </RDSButton>
        <RDSButton color="grey_dark" onClick={saveShowcase}>
          쇼케이스 저장
        </RDSButton>
      </div>
      <AddModelModal
        stage={stage}
        modelList={modelList}
        setModelList={setModelList}
        setAlert={setAlert}
        isOpen={isAddModelModalOpen}
        onClose={() => setIsAddModelModalOpen(false)}
      />
      <RDSModal
        open={isDeleteModelModalOpen}
        onClose={() => setIsDeleteModelModalOpen(false)}
        enableCloseButton={false}
        title="쇼케이스 모델 삭제하기"
        supportingText={
          <div>
            {`유저 ${selectedModel?.userName}의 모델을 쇼케이스에서 즉시 삭제하시겠습니까? 삭제를 누르면 바로 적용됩니다.`}
            <br />
            {` (seq : ${selectedModel?.seq})`}
          </div>
        }
        buttonType="noBox"
        button1Label="취소"
        button1Color="grey"
        button1Fn={() => setIsDeleteModelModalOpen(false)}
        button2Label="삭제"
        button2Color="warning"
        button2Fn={async () => await deleteHandler()}
      ></RDSModal>
      <RDSToast.Overlay
        type="loadingSpinner"
        openedToastOverlay={loading}
        toastOverlayId="loading"
        setOpenedToastOverlay={setLoading}
      />
      <RDSToast.AlertArea openedAlert={alert} />
    </div>
  );
};

const getModelCardStyle = (isDragging: boolean, draggableStyle: any) => ({
  userSelect: 'none',
  padding: '15px',
  margin: `0 4px`,
  minWidth: '120px',
  borderRadius: '15px',
  color: 'white',
  position: 'relative',
  background: isDragging ? '#FFA559' : '#454545',
  ...draggableStyle,
});

const getCloseButtonStyle = (): React.CSSProperties => {
  return {
    position: 'absolute',
    top: '8px',
    right: '8px',
    width: '20px',
    height: '20px',
    backgroundColor: 'red',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontWeight: 'bold',
    padding: '0 1px 2px 0',
    borderRadius: '5px',
    opacity: 0.8,
    transition: 'opacity 0.3s ease-in-out',
  };
};

const AddModelModal = ({
  stage,
  modelList,
  setModelList,
  setAlert,
  isOpen,
  onClose,
}: {
  stage: Stage;
  modelList: model[];
  setModelList: React.Dispatch<React.SetStateAction<model[]>>;
  setAlert: React.Dispatch<React.SetStateAction<RDSToastAlertObject | undefined>>;
  isOpen: boolean;
  onClose: Function;
}) => {
  if (!isOpen) return null;

  const [seq, setSeq] = useState('');
  const [userName, setUserName] = useState('');
  const [png, setPng] = useState<File | null>(null);
  const [gif, setGif] = useState<File | null>(null);
  const [checkDB, setCheckDB] = useState(false);
  const [checkName, setCheckName] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  //모달 입력시 상태 저장
  const handleSeqInputChange = (e: ChangeEvent<HTMLInputElement>) => setSeq(e.target.value);
  const handleNameInputChange = (e: ChangeEvent<HTMLInputElement>) => setUserName(e.target.value);
  const handlePngInputChange = (e: ChangeEvent<HTMLInputElement>) => e.target.files && setPng(e.target.files[0]);
  const handleGifInputChange = (e: ChangeEvent<HTMLInputElement>) => e.target.files && setGif(e.target.files[0]);
  const handleCheckDB = (e: ChangeEvent<HTMLInputElement>) => {
    setCheckDB(e.target.checked);
    setCheckName(false);
  };
  const handleCheckName = (e: ChangeEvent<HTMLInputElement>) => setCheckName(e.target.checked);

  const handleConfirm = async () => {
    if (seq === '' || !png) {
      setAlert({ type: 'failure', message: 'seq, name, png file 모두 필요합니다.' });
      return;
    }

    const formData = new FormData();
    formData.append('stage', stage);
    formData.append('seq', seq);
    formData.append('userName', userName);
    formData.append('pngFile', png);
    // formData.append('gifFile', gif);
    formData.append('option', JSON.stringify({ checkDB, checkName }));
    try {
      const res = await addShowcaseModel(formData);
      if (res.status === 200) {
        setModelList([...modelList, res.data.newModel]);
        setAlert({ type: 'success', message: '쇼케이스 모델 추가 성공' });
        onClose();
      } else setAlert({ type: 'failure', message: '쇼케이스 모델 추가 실패' });
    } catch (err: any) {
      if (err.response.status === 404 && err.response?.data?.error === 'model_not_found_in_current_stage_db') {
        setAlert({ type: 'failure', message: `${stage} DB에 존재하지 않는 모델입니다.` });
      } else if (err.response.status == 500 && err.response?.data?.error === 'owner_name_mismatch_error') {
        setAlert({
          type: 'failure',
          message: (
            <div>
              모델 소유자의 이름이 다릅니다.
              <br />
              [DB에 저장된 유저명: {err.response?.data?.ownerName}]
            </div>
          ),
        });
      } else if (err.response.status == 500 && err.response?.data?.error === 'duplicated_seq_error') {
        setAlert({ type: 'failure', message: '이미 존재하는 모델의 seq 입니다.' });
      } else {
        setAlert({ type: 'failure', message: '쇼케이스 모델 추가 실패' });
      }
    }
  };

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      ref={modalRef}
      onClick={(e) => e.target === modalRef.current && onClose()}
    >
      <div
        style={{
          backgroundColor: 'white',
          borderRadius: '4px',
          width: '400px',
          padding: '25px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <h3 style={{ marginBottom: '30px' }}>쇼케이스에 모델 파일 추가하기</h3>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: '10px' }}>
          <label>Seq</label>
          <input
            style={{
              height: '35px',
              width: '200px',
              border: '1px solid #ddd',
              borderRadius: '4px',
              marginBottom: '10px',
            }}
            type="text"
            onChange={handleSeqInputChange}
          />
          <label>Model Owner Name</label>
          <input
            style={{
              height: '35px',
              width: '200px',
              border: '1px solid #ddd',
              borderRadius: '4px',
              marginBottom: '10px',
            }}
            type="text"
            onChange={handleNameInputChange}
          />
          <label>
            <input type="checkbox" checked={checkDB} onChange={handleCheckDB} />{' '}
            {`${stage} DB에 존재하는 모델인지 확인`}
          </label>
          <label>
            <input type="checkbox" disabled={!checkDB} checked={checkName} onChange={handleCheckName} />{' '}
            {`입력한 유저 이름이 모델 소유자인지 확인`}
          </label>
          <br />
          <label>PNG</label>
          <input
            type="file"
            accept=".png"
            onChange={handlePngInputChange}
            style={{ marginBottom: '10px', width: '200px' }}
          />
          <label>GIF</label>
          <input
            type="file"
            accept=".gif"
            onChange={handleGifInputChange}
            style={{ marginBottom: '10px', width: '200px' }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            marginTop: '20px',
          }}
        >
          <button
            style={{
              backgroundColor: 'black',
              color: 'white',
              borderRadius: '4px',
              padding: '10px 20px',
              marginRight: '10px',
            }}
            onClick={handleConfirm}
          >
            확인
          </button>
          <button
            style={{
              backgroundColor: 'white',
              color: 'black',
              border: '1px solid black',
              borderRadius: '4px',
              padding: '10px 20px',
            }}
            onClick={() => onClose()}
          >
            취소
          </button>
        </div>
      </div>
    </div>
  );
};

export default Showcase;
