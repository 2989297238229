import { Stage } from '../../types';
import { PopupItem } from '../../pages/admin/dashboard/3dpresso/popup/PopupService';
import { AxiosInstance, getAuthorization } from '../AxiosInstance';

export async function putPopupList(item: any, stage?: Stage): Promise<number> {
  const res = await AxiosInstance.put('/3dpresso/popup', { stage, ...item });
  return res.status;
}

export async function deletePopup(popupId: number, stage?: Stage): Promise<number> {
  const res = await AxiosInstance.delete('/3dpresso/popup', {
    params: {
      stage,
      popupId: popupId,
    },
  });
  return res.status;
}

export async function getPopupList(
  searchWord: string | undefined,
  stage?: Stage,
): Promise<{ historyData: PopupItem[]; postingData: PopupItem[] }> {
  await getAuthorization();
  const res = await AxiosInstance.get('/3dpresso/popup/list', {
    params: {
      stage,
      searchStr: searchWord,
    },
  });
  const data = res.data.data;
  return convertPopupListData(data);
}

const convertPopupListData = (items: any) => {
  const historyData: PopupItem[] = [];
  const postingData: PopupItem[] = [];
  items.forEach((item: any) => {
    const currentDate = new Date();
    const createDate = new Date(item.create_date.replaceAll('.', '-').replace(/ /g, 'T'));
    const endDate = new Date(item.end_date.replaceAll('.', '-').replace(/ /g, 'T'));
    const popupItem: PopupItem = {
      ...item,
      popupId: item.id.toString(),
      createDate: item.create_date.substring(0, item.create_date.length - 3),
      endDate: item.end_date.substring(0, item.create_date.length - 3),
    };
    historyData.push(popupItem);
    // 만료 되지 않은 팝업에 대해서 따로 저장
    if (currentDate < endDate) {
      postingData.push(popupItem);
    }
  });
  return { historyData: historyData, postingData: postingData };
};
