import axios from 'axios';

const config = { baseURL: process.env.REACT_APP_PAYMENT_API_PATH };
const AxiosInstance = axios.create(config);

//새로운 결제 생성
export const createPayment = async (amount: number, description: string, administrator: string): Promise<any> => {
  const res = await AxiosInstance.post(`/ready`, { amount, description, administrator });
  return res.data;
};

//결제 내역 조회
type getPaymentHistoryRes = {
  payments: {
    idx: number;
    administrator: string;
    registered_date: string;
    amount: number;
    status: string;
    receipt: string; //url
  }[];
  count: number;
  page: number;
  totalPage: number;
};
export const getPaymentList = async (range: number[]): Promise<getPaymentHistoryRes> => {
  const res = await AxiosInstance.get(`/list`, {
    params: { range: JSON.stringify(range) },
  });
  return res.data;
};

export const refreshPaymentList = async () => {
  await AxiosInstance.post('/list/refresh', {});
  return;
};
