import { Checkbox } from '@mui/material';
import { RDSButton, RDSDataTable, RDSSearchBar } from '@reconlabs/reconlabs-fe-components';
import React, { useEffect, useState } from 'react';
import { PaginationOption, UserInfo } from '../../types';

const SelectUserModalPlicarzero = (props: any) => {
  const httpClient = props.httpClient;
  const [tags, setTags] = useState<{ value: string; count: number }[]>([]);
  const [tagFilter, setTagFilter] = useState<string>('');
  const [userList, setUserList] = useState<UserInfo[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [paginationOption, setPaginationOption] = useState<PaginationOption>({
    offset: 0,
    page: 1,
    range: [0, 9],
    rowsPerPage: 10,
    searchText: '',
    currentSort: { props: 'name', type: 'asc' },
    // 이메일 인증된 인원만 가져오기 위해
    where: { email_verified: true },
  });
  const [checkedBoxes, setCheckedBoxes] = useState<string[]>(props.userEmailListToSendEmail);

  useEffect(() => {
    getServerDataAndSetState();
  }, [paginationOption]);

  const getServerDataAndSetState = async () => {
    const res = await httpClient.getUserList('plicarzero', tagFilter, paginationOption, props.stage);
    const count = res.count;
    // const tags = data.tags;
    setTotalCount(count);
    setTags(tags);
    setUserList(res.users);
  };

  const handlePaginationOption = (e: any) => {
    // RDSDatatable에서 handlePaginationOption 의 event로 던져주는 거에 where옵션은 없음
    setPaginationOption({ ...e, where: { email_verified: true } });
  };

  const onCheckBoxClicked = (row: any) => {
    if (checkedBoxes.includes(row.user_email)) {
      // 해당 로직은 현재 무엇이 체크되어있는지 이메일string[] 로 되어있는데, 이는 유저수가 높아질수록 매우 힘들어진다.
      // 다른 방법을 생각해야=> user_idx로 해도 되고
      const updatedCheckedBoxes = checkedBoxes.filter((checkedBox) => checkedBox !== row.user_email);
      setCheckedBoxes(updatedCheckedBoxes);
    } else {
      setCheckedBoxes([...checkedBoxes, row.user_email]);
    }
  };

  return (
    <div style={{ padding: '10px', width: '800px', backgroundColor: 'white' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>메일 받을 고객</div>
      </div>
      <RDSDataTable
        data={userList}
        searchInput={'default'}
        dataTableOptions={{
          paginationPerPage: 10,
          paginationRowsPerPageOptions: [10, 30, 50, 100, 300],
        }}
        searchFilterKeyNames={['user_name', 'user_email']}
        sortServer={true}
        pagination={true}
        paginationServer={true}
        paginationTotalRows={totalCount}
        actionButton={<></>}
        onChangePaginationOption={handlePaginationOption}
        columns={[
          {
            name: '체크',
            cell: (row: any) => (
              <div style={{ cursor: 'pointer' }} onClick={() => onCheckBoxClicked(row)}>
                <Checkbox checked={checkedBoxes.includes(row.user_email)} />
              </div>
            ),
          },
          ...props.column,
        ]}
      />
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
        <RDSButton
          type="outline"
          customStyle={{ width: '100px' }}
          onClick={() => {
            props.setIsVisible(false);
          }}
        >
          취소
        </RDSButton>
        <RDSButton
          customStyle={{ width: '100px' }}
          onClick={() => {
            props.setUserEmailListToSendEmail(checkedBoxes);
            props.setIsVisible(false);
          }}
        >
          저장
        </RDSButton>
      </div>
    </div>
  );
};

export default SelectUserModalPlicarzero;
