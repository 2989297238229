/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { parseTime } from '../../lib/utils';
import { useAuthenticator } from '@aws-amplify/ui-react';

const Layout = (props: any) => {
  /*----------------------------------------
                     Data
   ----------------------------------------*/
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const navigate = useNavigate();
  const [username, setUsername] = useState<string>('');
  const [timer, setTimer] = useState<string>('');
  const httpClient = props.httpClient;
  /*----------------------------------------
                   Life Cycle
   ----------------------------------------*/
  useEffect(() => {
    getTime(new Date());
    const timeInterval = setInterval(() => {
      getTime(new Date());
    }, 1000);
    return () => {
      clearInterval(timeInterval);
    };
  }, []);

  useEffect(() => {
    (async function userAuthenticate() {
      try {
        await httpClient.getAuthorization();
        await getLoginResult();
      } catch (error: any) {
        navigate('/');
      }
    })();
  }, [user]);
  /*----------------------------------------
                 Business Logic
   ----------------------------------------*/
  const getTime = (currentDate: Date) => {
    const currentDateToString = parseTime(currentDate, '.');
    setTimer(currentDateToString);
  };

  const getLoginResult = async () => {
    // 현재 localStorage 에 저장된 토큰을 기반으로 로그인 유저정보를 가져옴
    const loginResult = await httpClient.getLoginInfo();
    setUsername(loginResult.name);
  };
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <div css={{ width: '1280px', margin: 'auto' }}>
      <div
        css={{
          paddingLeft: '20px',
          gap: '24px',
          alignItems: 'center',
          height: '64px',
          display: 'flex',
          color: 'white',
          backgroundColor: 'black',
          fontFamily: '',
        }}
      >
        <img css={{ width: '48px', cursor: 'pointer' }} onClick={() => {}} src={''} alt="logo" />
        <span
          css={{ cursor: 'pointer', fontSize: '16px' }}
          onClick={() => {
            navigate('/admin/dashboard');
          }}
        >
          Dashboard
        </span>
        <span css={{ cursor: 'pointer', fontSize: '16px' }}>Static</span>
        {/* flex 엘리먼트 내부에서 정렬 Ref */}
        {/* https://stackoverflow.com/questions/36182635/making-a-flex-item-float-right */}
        <div css={{ marginLeft: 'auto', marginRight: '20px' }}>
          <span>안녕하세요 {username} 님 </span>
          <span css={{ marginRight: '20px' }}>{timer}</span>
          <AccountCircleIcon css={{ width: '25px', cursor: 'pointer' }} onClick={signOut} />
        </div>
      </div>
      <div css={{ height: 'calc(100% - 64px)' }}>
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
