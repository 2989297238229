import React, { useEffect } from 'react';
import { Stage } from '../../../../../../types';
import { Payment, User, getPaymentlistByUser, refundPayment } from '../../../../../../api/3dpresso/user';
import { useQuery } from 'react-query';
import {
  ColumnDef,
  PaginationState,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Box, Tab, Tabs } from '@mui/material';
import { RDSButton, RDSModal, RDSToast } from '@reconlabs/reconlabs-fe-components';
import axios, { AxiosError } from 'axios';
import TanstackTable from '../../../../../../components/TanstackTable';
import { getBeanList } from '../../../../../../api/3dpresso/bean';

const BeanListModal = ({ stage, user }: { stage: Stage; user: User | boolean }) => {
  const rerender = React.useReducer(() => ({}), {})[1];
  const [toast, setToast] = React.useState<string>('');

  const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 5,
  });
  const [tabValue, setTabValue] = React.useState<string>('전체');
  const [clickedTransaction, setClickedTransaction] = React.useState<any>(undefined);

  const fetchDataOptions = {
    range: [pageIndex * pageSize, (pageIndex + 1) * pageSize],
    transaction_type: (() => {
      switch (tabValue) {
        case '전체':
          return [];
        case '지급':
          return ['refund', 'purchase', 'provide'];
        case '사용':
          return ['use', 'restore'];
      }
    })(),
  };

  const columns = React.useMemo<ColumnDef<Payment>[]>(
    () => [
      {
        header: 'payments',
        footer: (props) => props.column.id,
        columns: [
          {
            accessorKey: 'bean_amount',
            cell: (info) => info.getValue(),
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'action_type',
            cell: (info) => info.getValue(),
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'engine_type',
            cell: (info) => info.getValue(),
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'type',
            cell: (info) => info.getValue(),
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'seq',
            cell: (info) => info.getValue(),
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'ttl',
            header: <div style={{ whiteSpace: 'nowrap' }}>만료 시간</div>,
            cell: (info) => {
              if (info.getValue()) {
                return (
                  <div style={{ whiteSpace: 'nowrap', margin: '0px 5px 0px 5px' }}>
                    {new Date(Number(info.getValue())).toUTCString().slice(0, -3)}
                  </div>
                );
              }
            },

            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'transaction_date',
            header: '처리된 시간',
            cell: (info) => <div style={{ whiteSpace: 'nowrap', margin: '0px 5px 0px 5px' }}>{info.getValue()}</div>,
            footer: (props) => props.column.id,
          },
          {
            header: 'action',
            accessorFn: (row) => row,
            cell: (info) =>
              info.getValue().transaction_type === 'purchase' && (
                <div style={{ width: '50px' }}>
                  <RDSButton
                    type="solid"
                    size="xsmall"
                    onClick={() => {
                      setClickedTransaction(info.getValue());
                    }}
                  >
                    환불
                  </RDSButton>
                </div>
              ),
            footer: (props) => props.column.id,
          },
        ],
      },
    ],
    [],
  );

  const dataQuery = useQuery(
    ['user', fetchDataOptions, stage],
    async () => {
      if (user && typeof user == 'object' && user.idx) {
        const data = await getBeanList(stage, user.idx, fetchDataOptions);
        for (let index = 0; index < data.payments.length; index++) {
          const payment = data.payments[index];
          data.payments[index] = { ...payment, ...payment.action_detail };
        }
        return {
          rows: data.payments,
          pageCount: Math.ceil(data.count / pageSize),
        };
      }
    },
    {
      keepPreviousData: true,
    },
  );

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const table = useReactTable({
    data: dataQuery.data?.rows || [],
    columns,
    pageCount: dataQuery.data?.pageCount ?? -1,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    getPaginationRowModel: getPaginationRowModel(), // If only doing manual pagination, you don't need this
    debugTable: true,
  });

  useEffect(() => {
    setPagination({
      pageIndex: 0,
      pageSize: pageSize,
    });
  }, [pageSize, tabValue]);

  return (
    <div className="p-2">
      <RDSToast.Overlay
        customStyle={{ zIndex: 9999 }}
        type="loadingSpinner"
        toastOverlayId="refund_loading"
        openedToastOverlay={toast}
        setOpenedToastOverlay={setToast}
      />
      {/* <RDSModal
        open={clickedTransaction ? true : false}
        onClose={setClickedTransaction}
        title={'환불'}
        supportingText={
          <div style={{ overflow: 'scroll' }}>
            ``
            <div>user : {typeof user !== 'boolean' && user.user_email}</div>
            <div>transaction : {JSON.stringify(clickedTransaction)}</div>
          </div>
        }
        button1Fn={async () => {
          try {
            setToast('refund_loading');
            clickedTransaction && (await refundPayment(clickedTransaction.idx, 'null', stage));
            setToast('');
          } catch (err: any) {
            setToast('');
            if (axios.isAxiosError(err) && err.response?.status == 422) {
              alert('이미 환불처리된 구매이거나 존재하지 않는 구매입니다.');
            }
          }
        }}
        button1Label={'confirm'}
      /> */}
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tabValue}
            onChange={(event: React.SyntheticEvent, newTabValue) => setTabValue(newTabValue)}
            aria-label="basic tabs example"
          >
            <Tab label="전체" value={'전체'} />
            <Tab label="지급" value={'지급'} />
            <Tab label="사용" value={'사용'} />
          </Tabs>
        </Box>
      </Box>
      <TanstackTable table={table} dataQuery={dataQuery} />
    </div>
  );
};

export default BeanListModal;
