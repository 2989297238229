import React, { useEffect, useRef, useState } from 'react';
import { PopupType, Stage } from '../../../../../types';
import { parseTime, stringToDate } from '../../../../../lib/utils';
import { ModalMaker, RDSButton, RDSDropdown, RDSOption } from '@reconlabs/reconlabs-fe-components';
import DeletePopupModal from '../../../../../components/popup/DeletePopupModal';
import { TextField } from '@mui/material';
import { Editor } from '@toast-ui/react-editor';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import '@toast-ui/editor-plugin-color-syntax/dist/toastui-editor-plugin-color-syntax.css';
import { deletePopup, getPopupList, putPopupList } from '../../../../../api/3dpresso/popup';
import PopupPreviewModal from '../../../../../components/popup/PopupPreviewModal';
import PopupCurrentStatus from './PopupCurrentStatus';

const PopupService = ({ stage }: { stage: Stage }) => {
  const [historyItems, setHistoryItems] = useState<PopupItem[]>([]);
  const [postingItems, setPostingItems] = useState<PopupItem[]>([]);
  const [currentPopupItem, setCurrentPopupItem] = useState<PopupItem>({
    title: '',
    content: '',
    tag: '3dpresso',
    type: 'emergency',
    endDate: '',
    popupId: '',
    createDate: '',
    state: '',
  });
  const [selectedItem, setSelectedItem] = useState<PopupItem | undefined>(undefined);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false);
  const [tag, setTag] = useState('3dpresso');
  const [refreshState, refresh] = useState({});
  const editorRef = useRef() as any;

  useEffect(() => {
    if (selectedItem) {
      setCurrentPopupItem({ ...selectedItem, endDate: stringToDate(selectedItem.endDate) });
    }
  }, [selectedItem]);

  useEffect(() => {
    editorRef.current.getInstance().setMarkdown(currentPopupItem.content);
  }, [currentPopupItem.content]);

  const popupRegistration = async () => {
    if (!validation()) {
      return;
    }
    const markdown = editorRef.current.getInstance().getMarkdown();
    await putPopupList(
      {
        popupId: selectedItem ? Number(selectedItem.popupId) : null,
        endDate: currentPopupItem.endDate,
        type: currentPopupItem.type,
        state: selectedItem ? selectedItem.state : 'off',
        title: currentPopupItem.title,
        content: markdown,
        tag: tag,
      },
      stage,
    );
    setSelectedItem(undefined);
    refresh({});
  };

  const validation = () => {
    let result = true;
    if (postingItems.length === 5) {
      alert('팝업 현황은 5개를 초과할수 없습니다.');
      result = false;
    } else if (!currentPopupItem.title) {
      alert('팝업 제목을 입력하세요.');
      result = false;
    } else if (stringToDate(parseTime(new Date(), '-', false, false)) > stringToDate(currentPopupItem.endDate)) {
      alert('팝업 만료 일자가 현재보다 이를 수 없습니다.');
      result = false;
    } else if (!currentPopupItem.endDate) {
      alert('팝업 만료 일자를 선택해 주세요.');
      result = false;
    }
    return result;
  };

  const templateApply = (item: PopupItem) => {
    setSelectedItem(undefined);
    setCurrentPopupItem({ ...item, endDate: stringToDate(item.endDate) });
  };

  const postConfirmed = async (body: object) => {
    await putPopupList(body, stage);
    refresh({});
  };

  const onDeletePopup = async () => {
    setOpenDeleteModal(false);
    await deletePopup(Number(selectedItem!.popupId), stage);
    refresh({});
  };

  const deleteCancel = () => {
    setOpenDeleteModal(false);
    editCancel();
  };

  const editCancel = () => {
    setSelectedItem(undefined);
  };

  const closePreviewModal = () => {
    setOpenPreviewModal(false);
    editCancel();
  };

  const historySearch = (searchWord: string | undefined) => {};

  useEffect(() => {
    (async function () {
      const data = await getPopupList('', stage);
      setPostingItems(data.postingData);
      setHistoryItems(data.historyData);
    })();
  }, [stage, refreshState]);

  return (
    <>
      <ModalMaker isVisible={openDeleteModal && true} setIsVisible={setOpenDeleteModal}>
        <DeletePopupModal deletePopup={onDeletePopup} deleteCancel={deleteCancel} />
      </ModalMaker>
      {openPreviewModal && (
        <PopupPreviewModal
          type={selectedItem ? selectedItem.type : 'emergency'}
          title={selectedItem ? selectedItem.title : ''}
          content={selectedItem ? selectedItem.content : ''}
          headerImageSrc={`https://stag.assets.plicar.rlabsdev.com/icons/bang.png`}
          width={600}
          height={400}
          closePreviewModal={closePreviewModal}
        />
      )}
      <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row' }}>
        <div>
          <div
            style={{
              height: '100%',
            }}
          >
            <PopupCurrentStatus
              postingItems={postingItems}
              postConfirmed={postConfirmed}
              itemSelected={setSelectedItem}
              selectedItem={selectedItem}
              setOpenDeleteModal={setOpenDeleteModal}
              setOpenPreviewModal={setOpenPreviewModal}
            />
            <div
              style={{
                fontWeight: '500',
                fontSize: '20px',
              }}
            >
              팝업 등록
            </div>
            <div style={{ marginTop: '10px' }}>
              <TextField
                sx={{
                  width: '505px',
                  height: '48px',
                  fontSize: '16px',
                  fontWeight: '400',
                  textAlign: 'left',
                }}
                placeholder="제목을 입력해 주세요."
                size="medium"
                variant="standard"
                value={currentPopupItem.title}
                onChange={(e) => setCurrentPopupItem({ ...currentPopupItem, title: e.target.value })}
              />
            </div>
            <div>
              {'팝업 만료 일자    '}
              <input
                style={{
                  border: '1px solid rgba(0, 0, 0, 0.23)',
                  borderRadius: '4px',
                  fontSize: '16px',
                }}
                value={currentPopupItem.endDate}
                type="datetime-local"
                min="2022-01-01T00:00"
                max="2099-12-31T23:59"
                onChange={(e) => setCurrentPopupItem({ ...currentPopupItem, endDate: e.target.value })}
              />
            </div>
            <div style={{ marginTop: '10px', marginBottom: '10px' }}>
              <RDSDropdown
                size="small"
                value={currentPopupItem.type}
                onChange={(value) => {
                  setCurrentPopupItem({ ...currentPopupItem, type: value });
                }}
              >
                <RDSOption value="emergency">긴급 점검</RDSOption>
                <RDSOption value="temp">임시 점검</RDSOption>
                <RDSOption value="notice">공지 사항</RDSOption>
                <RDSOption value="periodic">정기 점검</RDSOption>
              </RDSDropdown>
              <p />
              <RDSDropdown
                size="small"
                value={tag}
                onChange={(value: Stage) => {
                  setTag(value);
                }}
              >
                <RDSOption value="3dpresso">3dpresso</RDSOption>
                <RDSOption value="zepeto">zepeto</RDSOption>
              </RDSDropdown>
            </div>
            {/* toastUI react editor */}
            <div style={{ zIndex: -1 }}>
              <Editor
                initialValue={currentPopupItem.content}
                height="400px"
                initialEditType="markdown"
                useCommandShortcut={false}
                ref={editorRef}
                plugins={[colorSyntax]}
              />
            </div>
            <div style={{ marginTop: '5px', overflow: 'hidden' }}>
              <RDSButton type={'solid'} onClick={popupRegistration}>
                {selectedItem ? '팝업 수정' : '팝업 게시'}
              </RDSButton>
              {selectedItem ? (
                <RDSButton type={'outline'} onClick={editCancel}>
                  취소
                </RDSButton>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        {/* <div style={{ width: '374px', marginLeft: '10px', border: '1px solid #000000' }}>
          <ServiceHistory
            type={'popup'}
            popupItems={historyItems}
            templateApply={templateApply}
            search={historySearch}
          />
        </div> */}
      </div>
    </>
  );
};

export default PopupService;

export type PopupItem = {
  [index: string]: string;
  popupId: string;
  createDate: string;
  endDate: string;
  type: PopupType;
  state: string;
  content: string;
  title: string;
  tag: string;
};
