import axios, { AxiosInstance } from 'axios';
import { PopupItem, ScheduledInspectionItem, PostEmailItem, PaginationOption, Stage } from '../types';
import * as dataConverter from './data-converter';
import { getEmailDetail, getEmailList, getCompanyList } from './data-converter';
import { Auth } from 'aws-amplify';

export type ReturnValue = {
  data: any;
};

const PROD_SERVER_DOMAIN = 'https://dzg6u2wfrl.execute-api.ap-northeast-2.amazonaws.com/prod';

export default class HttpClient {
  private httpRequest: AxiosInstance;
  requestRetriedCount: number;

  constructor() {
    this.requestRetriedCount = 0;
    this.httpRequest = axios.create({
      baseURL: `${process.env.REACT_APP_API_PATH}`,
      withCredentials: false,
    });
    this.httpRequest.interceptors.response.use(
      (res) => {
        console.log('request fullfiled (axios interceptor)');
        this.requestRetriedCount = 0;
        return res;
      },
      async (error) => {
        console.log(`retrying request ${this.requestRetriedCount} times`);
        if (this.requestRetriedCount === 4) {
          return;
        }
        this.requestRetriedCount++;
        const { url, params, method } = error.config;
        console.log(error.config);
        if (error.response.status === 401) {
          console.log('401 error, assume as id token staled and get another idtoken with refresh token');
          try {
            // Auth.currentSession 하면 refresh 자동 (호출 하지않아도 자동으로 되지만 끊겼다가 실행됨)
            const currentSession = await Auth.currentSession();
            const idToken = currentSession.getIdToken().getJwtToken();
            this.setAuthorization(idToken);
            console.log(url, method, params);
            alert('session이 만료되어 재요청중입니다 잠시만 기다려주세요');
            const res = await this.httpRequest({ method, url, params });
            console.log(res, this.httpRequest);
            return res;
          } catch (error: any) {
            console.log(error);
            console.log('failed to get token by refresh token');
            window.location.pathname = '/';
            throw Error('failed to get token');
          }
        }
      },
    );
    this.getAuthorization();
  }

  async getAuthorization() {
    const authorization = this.httpRequest.defaults.headers.common.Authorization;
    if (!authorization) {
      const token = (await Auth.currentSession()).getIdToken().getJwtToken();
      if (token) {
        this.setAuthorization(token);
      }
    }
  }

  setAuthorization(token: string) {
    console.log('setting header authro');
    this.httpRequest.defaults.headers.common = this.httpRequest.defaults.headers.common || {};
    this.httpRequest.defaults.headers.common.Authorization = `Bearer ${token}`;
  }

  async getLoginInfo(): Promise<ReturnValue> {
    const url = `${process.env.REACT_APP_API_PATH}/login`;
    const res = await this.httpRequest.get(url);
    return res.data.data;
  }

  async getProject(): Promise<ReturnValue> {
    const url = `${process.env.REACT_APP_API_PATH}/project`;
    const res = await this.httpRequest.get(url);
    return res.data;
  }

  async getInspectionList(
    projectId: string,
    searchWord: string | undefined,
    stage: Stage,
  ): Promise<{ historyData: ScheduledInspectionItem[]; expectedData: ScheduledInspectionItem[] }> {
    const url = `${stage === 'prod' ? PROD_SERVER_DOMAIN : process.env.REACT_APP_API_PATH}/${projectId}/schedule/list`;
    const res = await this.httpRequest.get(url, {
      params: {
        searchStr: searchWord,
        stage,
      },
    });
    const data = res.data.data;
    return dataConverter.getInspectionList(data);
  }

  async putInspection(projectId: string, item: any, stage: Stage): Promise<number> {
    const url = `${stage === 'prod' ? PROD_SERVER_DOMAIN : process.env.REACT_APP_API_PATH}/${projectId}/schedule`;
    const res = await this.httpRequest.put(url, { ...item, stage });
    return res.status;
  }

  async postInspection(projectId: string, item: any, stage: Stage): Promise<number> {
    const url = `${stage === 'prod' ? PROD_SERVER_DOMAIN : process.env.REACT_APP_API_PATH}/${projectId}/schedule`;
    const res = await this.httpRequest.post(url, { ...item, stage });
    return res.status;
  }

  async deleteInspection(projectId: string, scheduleId: any, stage: Stage): Promise<number> {
    const url = `${stage === 'prod' ? PROD_SERVER_DOMAIN : process.env.REACT_APP_API_PATH}/${projectId}/schedule`;
    const res = await this.httpRequest.delete(url, { params: { scheduleId, stage } });
    return res.status;
  }

  async getPopupList(
    projectId: string,
    searchWord: string | undefined,
    stage?: Stage,
  ): Promise<{ historyData: PopupItem[]; postingData: PopupItem[] }> {
    const url = `${stage === 'prod' ? PROD_SERVER_DOMAIN : process.env.REACT_APP_API_PATH}/${projectId}/popup/list`;

    const res = await this.httpRequest.get(url, {
      params: {
        stage,
        searchStr: searchWord,
      },
    });
    const data = res.data.data;
    return dataConverter.getPopupList(data);
  }

  async putPopupList(projectId: string, item: any, stage?: Stage): Promise<number> {
    const url = `${stage === 'prod' ? PROD_SERVER_DOMAIN : process.env.REACT_APP_API_PATH}/${projectId}/popup`;
    const res = await this.httpRequest.put(url, { stage, ...item });
    return res.status;
  }

  async postUserAccessToken(projectId: string, stage: Stage, user_uid: string): Promise<number> {
    const url = `${stage === 'prod' ? PROD_SERVER_DOMAIN : process.env.REACT_APP_API_PATH}/${projectId}/admin/token`;
    const res = await this.httpRequest.post(url, { stage, user_uid });
    return res.data.token;
  }

  async deletePopup(projectId: string, popupId: number, stage?: Stage): Promise<number> {
    const url = `${stage === 'prod' ? PROD_SERVER_DOMAIN : process.env.REACT_APP_API_PATH}/${projectId}/popup`;
    const res = await this.httpRequest.delete(url, {
      params: {
        stage,
        popupId: popupId,
      },
    });
    return res.status;
  }

  async getEmailList(projectId: string, searchWord: string | undefined, stage?: Stage): Promise<PostEmailItem[]> {
    const url = `${stage === 'prod' ? PROD_SERVER_DOMAIN : process.env.REACT_APP_API_PATH}/${projectId}/email/list`;
    console.log(stage);
    const res = await this.httpRequest.get(url, {
      params: {
        stage,
        searchStr: searchWord,
      },
    });
    const data = res.data.data;
    return getEmailList(data);
  }

  async getEmailDetail(
    projectId: string,
    emailId: number,
    stage?: Stage,
  ): Promise<{ title: string; typeValue: string; typeLabel: string; content: string }> {
    const url = `${stage === 'prod' ? PROD_SERVER_DOMAIN : process.env.REACT_APP_API_PATH}/${projectId}/email`;
    const res = await this.httpRequest.get(url, {
      params: {
        emailId: emailId,
        stage: stage,
      },
    });
    return getEmailDetail(res.data.data);
  }

  async postEmail(
    projectId: string,
    body: { type: string; title: string; content: string; userIdArr: any[]; stage?: Stage },
  ): Promise<number> {
    const url = `${body.stage === 'prod' ? PROD_SERVER_DOMAIN : process.env.REACT_APP_API_PATH}/${projectId}/email`;
    const res = await this.httpRequest.post(url, body);
    return res.status;
  }

  async getUserList(
    projectId: string,
    tag: string,
    option: PaginationOption,
    stage?: Stage,
    excludeInternalppl?: boolean,
  ): Promise<any[]> {
    const url = `${stage === 'prod' ? PROD_SERVER_DOMAIN : process.env.REACT_APP_API_PATH}/${projectId}/user/list`;
    const res = await this.httpRequest.get(url, {
      params: {
        searchStrs: JSON.stringify([option.searchText]),
        range: `[${option.range}]`,
        sortColumn: option.currentSort ? option.currentSort.props : '',
        sortOrder: option.currentSort ? option.currentSort.type : '',
        stage: stage ? stage : 'dev',
        where: option.where,
        excludeInternalppl: excludeInternalppl ? excludeInternalppl : false,
      },
    });
    return res.data;
  }

  async getUserInfo(
    projectId: string,
  ): Promise<{ count: number; tags: { tag: string; count: number }; stage?: Stage }> {
    const url = `${process.env.REACT_APP_API_PATH}/${projectId}/user/all`;
    const res = await this.httpRequest.get(url, {
      params: {
        tag: true,
      },
    });
    const data = res.data.data;

    return { count: data.count, tags: data.tags };
  }

  async putUserInfo(
    projectId: string,
    userInfo: { id: number; name: string; clientCompany: string; clientJobPosition: string; email: string },
    stage: Stage = 'dev',
  ): Promise<number> {
    const url = `${stage === 'prod' ? PROD_SERVER_DOMAIN : process.env.REACT_APP_API_PATH}/${projectId}/user`;
    const res = await this.httpRequest.put(url, {
      id: userInfo.id,
      name: userInfo.name,
      clientCompany: userInfo.clientCompany,
      clientJobPosition: userInfo.clientJobPosition,
      email: userInfo.email,
    });

    return res.status;
  }

  async putUserInfoPlicarzero(projectId: string, userInfo: Object, stage: Stage = 'dev'): Promise<number> {
    const url = `${stage === 'prod' ? PROD_SERVER_DOMAIN : process.env.REACT_APP_API_PATH}/${projectId}/user`;
    const res = await this.httpRequest.put(url, { ...userInfo, stage });

    return res.status;
  }

  async deleteUserInfo(projectId: string, user: object, stage: Stage): Promise<number> {
    const url = `${stage === 'prod' ? PROD_SERVER_DOMAIN : process.env.REACT_APP_API_PATH}/${projectId}/user`;
    const res = await this.httpRequest.delete(url, {
      params: {
        stage,
        user,
      },
    });

    return res.status;
  }

  async postUserTag(projectId: string, userId: string, tagArr: string[]): Promise<number> {
    const url = `${process.env.REACT_APP_API_PATH}/${projectId}/user/tag`;
    const res = await this.httpRequest.post(url, {
      userId: userId,
      tagArr: tagArr,
    });

    return res.status;
  }

  async deleteUserTag(projectId: string, userId: string, tag: string): Promise<number> {
    const url = `${process.env.REACT_APP_API_PATH}/${projectId}/user/tag`;
    const res = await this.httpRequest.delete(url, {
      params: {
        userId: userId,
        tag: tag,
      },
    });

    return res.status;
  }

  async postTag(projectId: string, tag: string): Promise<number> {
    const url = `${process.env.REACT_APP_API_PATH}/${projectId}/user/tag`;
    const res = await this.httpRequest.post(url, {
      tagArr: [tag],
    });

    return res.status;
  }

  async deleteTag(projectId: string, tag: string): Promise<number> {
    const url = `${process.env.REACT_APP_API_PATH}/${projectId}/user/tag`;
    const res = await this.httpRequest.delete(url, {
      params: {
        tag: tag,
      },
    });

    return res.status;
  }

  async getCompanyList(projectId: string, searchStr: string): Promise<string[]> {
    const url = `${process.env.REACT_APP_API_PATH}/${projectId}/company/list`;
    const res = await this.httpRequest.get(url, {
      params: {
        searchStr: searchStr,
      },
    });

    return getCompanyList(res.data);
  }

  async getUserModelList(projectId: string, userInfo: any, stage: Stage = 'dev'): Promise<any> {
    const url = `${stage === 'prod' ? PROD_SERVER_DOMAIN : process.env.REACT_APP_API_PATH}/${projectId}/model/list`;
    const res = await this.httpRequest.get(url, {
      params: {
        idx: userInfo.idx,
        user_uid: userInfo.uid,
        email: userInfo.user_email,
        stage: stage,
      },
    });

    return res.data;
  }
  async _getMultipartUploadSignedUrls(user: any, fileName: string, fileSize: number, stage: Stage, projectId: string) {
    let res;
    const url = `${
      stage === 'prod' ? PROD_SERVER_DOMAIN : process.env.REACT_APP_API_PATH
    }/${projectId}/model/upload/signedurl`;
    try {
      res = await this.httpRequest.get(url, {
        params: {
          user,
          file_name: fileName,
          file_size: `${fileSize}`,
          stage,
        },
      });
    } catch (e) {
      // console.log(e);
      throw new Error('GET signedUrl 획득에 실패했습니다');
    }
    console.log(res.data);
    return { id: res.data.model_uid, urls: res.data.urls };
  }

  async _postMultipartUploadComplete(modelId: string, stage: Stage, projectId: string) {
    const url = `${
      stage === 'prod' ? PROD_SERVER_DOMAIN : process.env.REACT_APP_API_PATH
    }/${projectId}/model/upload/complete`;
    try {
      await this.httpRequest.post(url, {
        model_uid: modelId,
        stage,
      });
    } catch (e) {
      console.log(e);
      throw new Error('POST 멀티파트업로드 완료처리 실패');
    }
  }

  // PUT signedUrl로 데이터 업로드
  async _putUpload(uploadUrl: string, body: any) {
    let res;
    try {
      res = await axios.put(uploadUrl, body, { withCredentials: false });
    } catch (e) {
      throw new Error('PUT via signedUrl 실패');
    }
    return res;
  }

  // POST 멀티파트 업로드 중단처리
  async _postAbortMultipartUpload(modelId: string, stage: Stage, projectId: string): Promise<any> {
    const url = `${
      stage === 'prod' ? PROD_SERVER_DOMAIN : process.env.REACT_APP_API_PATH
    }/${projectId}/model/upload/abort`;
    try {
      await this.httpRequest.post(url, { model_uid: modelId, stage });
    } catch (e) {
      // console.log(e);
      throw new Error('POST 멀티파트 업로드 중단처리 실패');
    }
  }

  async deleteBillingKeyZero(stage: Stage, user_email: string) {
    const url = `${stage === 'prod' ? PROD_SERVER_DOMAIN : process.env.REACT_APP_API_PATH}/plicarzero/payment/billing`;
    const res = await this.httpRequest.delete(url, { params: { user_email, stage } });
    return res;
  }
}
