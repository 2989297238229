/**
 * javascript Date를 받아서 사람이 보기좋은 string으로 변환합니다.
 * ex) 2021-11-16T03:42:21.000Z -> 2021.11.16
 * @param {Date} date  date: 2021-11-16T03:42:21.000Z
 * @param {String} splitString 연월일을 구분자입니다 default  "/"
 * @param {Boolean} includeTime String에 시간 정보를 포함할지 결정합니다
 * @param {Boolean} includeSeconds String에 초 정보를 포함할지 결정합니다
 * @param {Boolean} includeSpace String에 빈 공백을 줄지 T를 줄지 결정합니다
 * @returns
 */
export const parseTime = (
  date: Date | string | any,
  splitString = '/',
  includeTime: Boolean = true,
  includeSeconds: Boolean = true,
  includeSpace: Boolean = true,
) => {
  if (date === undefined || date === null) {
    return '';
  }
  const dateInstance = new Date(date);
  const YEAR = dateInstance.getFullYear(); // yyyy
  const MONTH = String(dateInstance.getMonth() + 1).padStart(2, '0'); // mm
  const DATE = String(dateInstance.getDate()).padStart(2, '0'); // dd
  const HOUR = String(dateInstance.getHours()).padStart(2, '0'); // hh
  const MINUTE = String(dateInstance.getMinutes()).padStart(2, '0'); // mm
  const SECONDS = String(dateInstance.getSeconds()).padStart(2, '0'); // ss

  if (includeTime) {
    if (includeSeconds) {
      return `${YEAR}${splitString}${MONTH}${splitString}${DATE}${
        includeSpace ? ' ' : 'T'
      }${HOUR}:${MINUTE}:${SECONDS}`;
    } else {
      return `${YEAR}${splitString}${MONTH}${splitString}${DATE}${includeSpace ? ' ' : 'T'}${HOUR}:${MINUTE}`; // yyyy.dd.mm hh:mm
    }
  } else {
    return `${YEAR}${splitString}${MONTH}${splitString}${DATE}`; // yyyy.dd.mm
  }
};

export const stringToDate = (time: string) => {
  return time.replace(/ /g, 'T');
};

export const getParamsByKey = (key: string) => {
  return new URLSearchParams(window.location.search).get(key);
};

/**
 * object array 인 경우 1depth 까지 deep copy 한 결과를 return
 * object 내부에 array 가 props 로 있는경우에는 해당 array 는 얕은 복사로 return 됨으로
 * 해당 array 까지 deep copy 가 필요한 경우에는 추가로 구현 필요함.
 */
export const arrayDeepCopy = (items: any[]) => {
  const result: any[] = [];

  items.forEach((item: any) => {
    if (!Array.isArray(item) && typeof item === 'object' && item !== null && item !== undefined) {
      const tempObj: { [string: string]: any } = {};
      for (const i in item) {
        tempObj[i] = item[i];
      }
      result.push(tempObj);
    }
  });
  return result;
};

export const convertKSTtoUTCdatestring = (KSTDateString: string) => {
  const UTCdatestring = new Date(KSTDateString).toISOString(); // add 'Z' to indicate UTC
  return UTCdatestring.substring(0, 16) + 'Z';
};

export const urlToBlob = (url: string) => {
  return new Promise((resolve, reject) => {
    try {
      const xhr = new XMLHttpRequest();
      xhr.open('get', url);
      xhr.responseType = 'blob';
      xhr.onerror = () => {
        reject('error');
      };
      xhr.onload = () => {
        if (xhr.status === 200) {
          resolve(xhr.response);
        } else {
          reject('error : ' + xhr.statusText);
        }
      };
      xhr.send();
    } catch (err: any) {
      reject(err.message);
    }
  });
};
