/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import ScheduledInspection from './ScheduledInspection';
import { Button } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { ScheduledInspectionItem } from '../../types';
import { convertKSTtoUTCdatestring, parseTime, stringToDate } from '../../lib/utils';
import InspectionTypeSelectBox from '../InspectionTypeSelectBox';
import ServiceHistory from '../ServiceHistory';

const ServiceMaintenance = (props: any) => {
  /*----------------------------------------
										 Data
	 ----------------------------------------*/
  const location = useLocation();
  const projectName = location.search.split('=')[1];
  const httpClient = props.httpClient;
  const [inspectionItems, setInspectionItems] = useState<Array<ScheduledInspectionItem>>([]);
  const [selectedItem, setSelectedItem] = useState<ScheduledInspectionItem>();
  const [inspectionStartTime, setInspectionStartTime] = useState<string>('');
  const [inspectionEndTime, setInspectionEndTime] = useState<string>('');
  const [inspectionReason, setInspectionReason] = useState<string>('');
  const [historyItems, setHistoryItems] = useState<Array<ScheduledInspectionItem>>([]);
  const [inspectionType, setInspectionType] = useState<{ value: string; label: string }>();
  const [statusLabel, setStatusLabel] = useState<string>('운영중');
  /*----------------------------------------
									 Life Cycle
	 ----------------------------------------*/
  useEffect(() => {
    // document.querySelector('div.admin-tool_body')?.setAttribute('style', 'height: 965px');
    initVars(undefined);
  }, [props.stage]);

  useEffect(() => {
    if (selectedItem) {
      setInspectionStartTime(selectedItem.inspectionStartTime);
      setInspectionEndTime(selectedItem.inspectionEndTime);
      setInspectionType({ value: selectedItem.typeValue, label: selectedItem.typeLabel });
      setInspectionReason(selectedItem.inspectionReason);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (inspectionItems && inspectionItems.length > 0) {
      inspectionItems.forEach((item) => {
        if (item.status === '점검중') {
          setStatusLabel('점검중');
        }
      });
    }
  }, [inspectionItems]);
  /*----------------------------------------
								 Business Logic
	 ----------------------------------------*/
  const initVars = async (searchWord: string | undefined) => {
    const currentTime = parseTime(new Date(), '-', true, false, false);
    const data = await httpClient.getInspectionList(projectName, searchWord, props.stage);
    setInspectionStartTime(currentTime);
    setInspectionEndTime(currentTime);
    setInspectionType({ value: 'emergency', label: '긴급 점검' });
    setInspectionReason('');
    console.log(data);
    setInspectionItems(data.expectedData);

    if (data.historyData.length > 16) {
      setHistoryItems(data.historyData.slice(0, 16));
    } else {
      setHistoryItems(data.historyData);
    }
  };

  const validation = () => {
    let result: boolean = true;

    if (inspectionItems.length === 5) {
      alert('예정된 점검은 5개를 초과할수 없습니다.');
      result = false;
    } else if (!inspectionReason) {
      alert('점검 사유를 입력하세요.');
      result = false;
    } else if (stringToDate(inspectionStartTime) > stringToDate(inspectionEndTime)) {
      alert('점검 시작 시간이 종료 시간보다 늦을수 없습니다.');
      result = false;
    } else if (!inspectionStartTime) {
      alert('점검 시작 시간을 선택해주세요.');
      result = false;
    } else if (!inspectionEndTime) {
      alert('점검 종료 시간을 선택해주세요.');
      result = false;
    } else if (inspectionStartTime === inspectionEndTime) {
      alert('점검 시작 시간과 점검 종료 시간은 같을수 없습니다.');
      result = false;
    }

    return result;
  };
  /*----------------------------------------
									Event Handler
	 ----------------------------------------*/
  const onCancelButtonClicked = () => {
    const currentTime = parseTime(new Date(), '-', true, false, false);
    setInspectionStartTime(currentTime);
    setInspectionEndTime(currentTime);
    setInspectionType({ value: 'emergency', label: '긴급 점검' });
    setInspectionReason('');
    setSelectedItem(undefined);
  };

  const templateApply = (item: ScheduledInspectionItem) => {
    setSelectedItem(undefined);
    setInspectionStartTime(item.inspectionStartTime);
    setInspectionEndTime(item.inspectionEndTime);
    setInspectionType({ value: item.typeValue, label: item.typeLabel });
    setInspectionReason(item.inspectionReason);
  };

  const onConfirmButtonClicked = async () => {
    if (!validation()) {
      return;
    }
    if (selectedItem) {
      const result = await httpClient.putInspection(
        projectName,
        {
          scheduleId: selectedItem.inspectionId,
          startDate: convertKSTtoUTCdatestring(inspectionStartTime),
          endDate: convertKSTtoUTCdatestring(inspectionEndTime),
          description: inspectionReason,
          type: inspectionType?.value,
        },
        props.stage,
      );
      result === 200 && initVars('');
    } else {
      const result = await httpClient.postInspection(
        projectName,
        {
          startDate: convertKSTtoUTCdatestring(inspectionStartTime),
          endDate: convertKSTtoUTCdatestring(inspectionEndTime),
          description: inspectionReason,
          type: inspectionType?.value,
        },
        props.stage,
      );
      result === 200 && initVars('');
    }
  };

  /* 점검 종료 */
  const inspectionDelete = (item: ScheduledInspectionItem) => {
    httpClient
      .deleteInspection(
        projectName,
        item.inspectionId,
        props.stage,
      )
      .then((result: number) => {
        if (result === 200) {
          initVars(undefined);
        }
      });
  };

  const historySearch = (searchWord: string) => {
    initVars(searchWord);
  };
  /*----------------------------------------
								Default Template
	 ----------------------------------------*/
  return (
    <div css={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row' }}>
      <div css={{ width: '846px', height: '100%', border: '1px solid #000000', padding: '15px' }}>
        <div
          css={{
            height: '100%',
          }}
        >
          <div
            css={{
              fontFamily: 'Noto Sans, sans-serif',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '20px',
              lineHeight: '32px',
              color: '#000000',
            }}
          >
            <span css={{ letterSpacing: '0.15px' }}>현재 상황</span>
            <span
              css={{
                marginLeft: '24px',
                fontSize: '24px',
                fontWeight: '400',
                color: statusLabel === '운영중' ? '#00AA92' : '#FF0071',
              }}
            >
              {statusLabel}
            </span>
          </div>
          {inspectionItems.length !== 0 ? (
            <ScheduledInspection
              items={inspectionItems}
              inspectionDelete={inspectionDelete}
              selectedItem={selectedItem}
              itemSelected={setSelectedItem}
            />
          ) : (
            ''
          )}
          <div
            css={{
              border: '1px solid #000000',
              borderRadius: '10px',
              width: '816px',
              height: '252px',
              marginTop: '24px',
              padding: '15px',
            }}
          >
            <div
              css={{
                fontFamily: 'Noto Sans, sans-serif',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '24px',
                letterSpacing: '0.15px',
                color: '#000000',
              }}
            >
              점검 유형
            </div>
            <div css={{ marginTop: '15px' }}>
              <InspectionTypeSelectBox inspectionType={inspectionType} setInspectionType={setInspectionType} />
            </div>
            <div
              css={{
                marginTop: '15px',
                display: 'flex',
                flexDirection: 'row',
                fontFamily: 'Noto Sans, sans-serif',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '24px',
                letterSpacing: '0.15px',
                color: '#000000',
              }}
            >
              <div
                css={{
                  width: '220px',
                }}
              >
                <div
                  css={{
                    textAlign: 'center',
                  }}
                >
                  점검 시작 시간
                </div>
                <div css={{ marginTop: '5px' }}>
                  <input
                    css={{
                      width: '220px',
                      height: '40px',
                      textAlign: 'center',
                      color: 'rgba(0, 0, 0, 0.6)',
                      outline: 'none',
                      border: '1px solid rgba(0, 0, 0, 0.23)',
                      borderRadius: '4px',
                      fontFamily: 'Noto Sans, sans-serif',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '24px',
                      letterSpacing: '0.15px',
                    }}
                    type="datetime-local"
                    value={inspectionStartTime}
                    min="2022-01-01T00:00"
                    max="2099-12-31T23:59"
                    onChange={(e) => setInspectionStartTime(e.target.value)}
                  />
                </div>
              </div>
              <div
                css={{
                  width: '40px',
                  textAlign: 'center',
                  lineHeight: '69px',
                }}
              >
                ~
              </div>
              <div
                css={{
                  width: '220px',
                }}
              >
                <div
                  css={{
                    textAlign: 'center',
                  }}
                >
                  점검 종료 시간
                </div>
                <div css={{ marginTop: '5px' }}>
                  <input
                    css={{
                      width: '220px',
                      height: '40px',
                      textAlign: 'center',
                      color: 'rgba(0, 0, 0, 0.6)',
                      outline: 'none',
                      border: '1px solid rgba(0, 0, 0, 0.23)',
                      borderRadius: '4px',
                      fontFamily: 'Noto Sans, sans-serif',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '24px',
                      letterSpacing: '0.15px',
                    }}
                    type="datetime-local"
                    value={inspectionEndTime}
                    min="2022-01-01T00:00"
                    max="2099-12-31T23:59"
                    onChange={(e) => setInspectionEndTime(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div
              css={{
                marginTop: '13px',
              }}
            >
              <input
                css={{
                  width: '556px',
                  height: '48px',
                  outline: 'none',
                  border: 'none',
                  borderBottom: '1px solid #0000006B',
                  fontFamily: 'Noto Sans, sans-serif',
                  fontSize: '16px',
                  fontWeight: '400',
                  lineHeight: '24px',
                  letterSpacing: '0.15px',
                  textAlign: 'left',
                  color: 'rgba(0, 0, 0, 0.6)',
                }}
                value={inspectionReason}
                onChange={(e) => setInspectionReason(e.target.value)}
                placeholder={'점검 사유를 입력해주세요.'}
                type="text"
              />
              <Button
                variant={'contained'}
                css={{
                  float: 'right',
                  width: '105px',
                  height: '42px',
                  borderRadius: '4px',
                  backgroundColor: '#0071FF',
                  fontFamily: 'Noto Sans, sans-serif',
                  fontSize: '15px',
                  fontWeight: '500',
                  lineHeight: '26px',
                  letterSpacing: '0.46px',
                  fontStyle: 'normal',
                  boxShadow: '0px 1px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 3px rgba(0, 0, 0, 0.14)',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: '#FFFFFF',
                }}
                onClick={onConfirmButtonClicked}
              >
                {selectedItem ? '점검 수정' : '점검 추가'}
              </Button>
              {selectedItem ? (
                <Button
                  variant={'outlined'}
                  css={{
                    float: 'right',
                    marginRight: '15px',
                    width: '73px',
                    height: '42px',
                    borderColor: '#FF0071',
                    borderRadius: '4px',
                    fontFamily: 'Noto Sans, sans-serif',
                    fontSize: '15px',
                    fontWeight: '500',
                    lineHeight: '26px',
                    letterSpacing: '0.46px',
                    fontStyle: 'normal',
                    color: '#FF0071',
                  }}
                  onClick={onCancelButtonClicked}
                >
                  취소
                </Button>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
      <div css={{ width: '374px', marginLeft: '10px', border: '1px solid #000000' }}>
        <ServiceHistory
          type={'inspection'}
          inspectionItems={historyItems}
          templateApply={templateApply}
          search={historySearch}
        />
      </div>
    </div>
  );
};

export default ServiceMaintenance;
