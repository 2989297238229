import React, { useEffect } from 'react';
import { Stage } from '../../../../../../types';
import { Payment, User, getPaymentlistByUser, refundPayment } from '../../../../../../api/3dpresso/user';
import { useQuery } from 'react-query';
import {
  ColumnDef,
  PaginationState,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Box, Tab, Tabs } from '@mui/material';
import { RDSButton, RDSModal, RDSToast } from '@reconlabs/reconlabs-fe-components';
import axios, { AxiosError } from 'axios';
import TanstackTable from '../../../../../../components/TanstackTable';
import { getBeanList } from '../../../../../../api/3dpresso/bean';

const PaymentListModal = ({ stage, user }: { stage: Stage; user: User | boolean }) => {
  const rerender = React.useReducer(() => ({}), {})[1];
  const [toast, setToast] = React.useState<string>('');

  const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 5,
  });
  const [tabValue, setTabValue] = React.useState<string>('charges');
  const [clickedCharge, setclickedCharge] = React.useState<any>(undefined);
  const [data, setData] = React.useState<any>({ charges: [], invoices: [] });

  const invoicesColumns = React.useMemo<ColumnDef<Payment>[]>(
    () => [
      {
        header: 'invoices',
        footer: (props) => props.column.id,
        columns: [
          {
            accessorKey: 'total',
            cell: (info) => info.getValue(),
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'subtotal',
            cell: (info) => info.getValue(),
            footer: (props) => props.column.id,
          },
          {
            header: 'price',
            accessorFn: (row) => row,
            cell: (info) => {
              if (info.getValue().lines && info.getValue().lines.data) {
                if (info.getValue().billing_reason === 'subscription_update') {
                  return info.getValue().lines.data[1].price.lookup_key;
                }
                return info.getValue().lines.data[0].price.lookup_key;
              }
            },
            footer: (props) => props.column.id,
          },
          {
            header: 'discount',
            accessorFn: (row) => row,
            cell: (info) => {
              if (info.getValue().discount && info.getValue().discount.coupon) {
                return `${info.getValue().discount.coupon.percent_off}%`;
              }
            },
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'billing_reason',
            cell: (info) => info.getValue(),
            footer: (props) => props.column.id,
          },
          {
            header: 'paid_at(한국시간 기준)',
            accessorFn: (row) => row,
            cell: (info) => {
              if (info.getValue().status == 'paid') {
                return (
                  <div style={{ whiteSpace: 'nowrap', margin: '0px 5px 0px 5px' }}>
                    {new Date(info.getValue().status_transitions.paid_at * 1000).toLocaleString()}
                  </div>
                );
              }
            },
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'status',
            cell: (info) => info.getValue(),
            footer: (props) => props.column.id,
          },
        ],
      },
    ],
    [],
  );

  const chargesColumns = React.useMemo<ColumnDef<Payment>[]>(
    () => [
      {
        header: 'charges',
        footer: (props) => props.column.id,
        columns: [
          {
            header: '총 금액',

            accessorKey: 'amount',
            cell: (info) => info.getValue(),
            footer: (props) => props.column.id,
          },
          {
            header: '결제된 금액',
            accessorKey: 'amount_captured',
            cell: (info) => info.getValue(),
            footer: (props) => props.column.id,
          },
          {
            header: '환불된 금액',
            accessorKey: 'amount_refunded',
            cell: (info) => info.getValue(),
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'description',
            cell: (info) => {
              return <div style={{ whiteSpace: 'nowrap', margin: '0px 5px 0px 5px' }}>{info.getValue()}</div>;
            },
            footer: (props) => props.column.id,
          },

          {
            accessorKey: 'status',
            cell: (info) => info.getValue(),
            footer: (props) => props.column.id,
          },
          {
            accessorKey: '환불',
            accessorFn: (row) => row,
            cell: (info) =>
              info.getValue().status === 'succeeded' &&
              info.getValue().amount_refunded < info.getValue().amount && (
                <div style={{ width: '50px' }}>
                  <RDSButton
                    type="solid"
                    size="xsmall"
                    onClick={() => {
                      setclickedCharge(info.getValue());
                    }}
                  >
                    환불
                  </RDSButton>
                </div>
              ),
            footer: (props) => props.column.id,
          },
        ],
      },
    ],
    [],
  );

  useEffect(() => {
    (async function () {
      if (user && typeof user == 'object' && user.idx) {
        const data = await getPaymentlistByUser(user.user_email);
        console.log(data);
        setData(data);
      }
    })();
  }, []);

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const table = useReactTable({
    data: data[tabValue] || [],
    columns: tabValue == 'invoices' ? invoicesColumns : chargesColumns,
    pageCount: Math.ceil(data[tabValue].length / pageSize) ?? -1,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    getPaginationRowModel: getPaginationRowModel(), // If only doing manual pagination, you don't need this
    debugTable: true,
  });

  useEffect(() => {
    setPagination({
      pageIndex: 0,
      pageSize: pageSize,
    });
  }, [pageSize, tabValue]);

  return (
    <div className="p-2">
      <RDSToast.Overlay
        customStyle={{ zIndex: 9999 }}
        type="loadingSpinner"
        toastOverlayId="refund_loading"
        openedToastOverlay={toast}
        setOpenedToastOverlay={setToast}
      />
      <RDSModal
        open={clickedCharge ? true : false}
        onClose={setclickedCharge}
        title={'환불'}
        supportingText={
          <div style={{ overflow: 'scroll' }}>
            <div>user : {typeof user !== 'boolean' && user.user_email}</div>
            <div>invoice</div>
            <div>
              paid at :{' '}
              {clickedCharge &&
                clickedCharge.status_transitions &&
                clickedCharge.status_transitions.paid_at &&
                new Date(clickedCharge.status_transitions.paid_at * 1000).toLocaleString()}
            </div>
          </div>
        }
        button1Fn={async () => {
          try {
            setToast('refund_loading');
            clickedCharge && (await refundPayment(clickedCharge.id, 'null'));
            setToast('');
          } catch (err: any) {
            setToast('');
            if (axios.isAxiosError(err) && err.response?.status == 422) {
              alert('이미 환불처리된 구매이거나 존재하지 않는 구매입니다.');
            }
          }
        }}
        button1Label={'confirm'}
      />
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tabValue}
            onChange={(event: React.SyntheticEvent, newTabValue) => setTabValue(newTabValue)}
            aria-label="basic tabs example"
          >
            <Tab label="charge" value={'charges'} />
            <Tab label="invoice" value={'invoices'} />
          </Tabs>
        </Box>
      </Box>

      <TanstackTable table={table} />
    </div>
  );
};

export default PaymentListModal;
