import {
  ColumnDef,
  PaginationState,
  SortingState,
  TableOptions,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { useEffect, useState } from 'react';
import { TableFetchOptions, User, getUserList } from '../../../../../../api/3dpresso/user';
import { Stage } from '../../../../../../types';
import { useQuery } from 'react-query';
import { RDSSearchBar } from '@reconlabs/reconlabs-fe-components';
import TanstackTable from '../../../../../../components/TanstackTable';

const UserListTable = ({
  stage,
  columns,
  filter,
}: {
  stage: Stage;
  columns: ColumnDef<User>[];
  filter?: Record<string, any>;
}) => {
  const [totalCount, setTotalCount] = React.useState<number>(0);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  );

  const [searchStr, setSeearchStr] = React.useState<string>('');

  const fetchDataOptions: TableFetchOptions = {
    searchStr: searchStr.split(' '),
    range: [pageIndex * pageSize, (pageIndex + 1) * pageSize - 1],
    externalInternalppl: false,
    sortColumn: sorting[0] ? sorting[0].id : '',
    sortOrder: sorting[0] ? (sorting[0].desc ? 'desc' : 'asc') : '',
    filter: filter,
  };

  const dataQuery = useQuery(
    ['user', fetchDataOptions, stage],
    async () => {
      const data = await getUserList(stage, fetchDataOptions);
      setTotalCount(data.count);
      return {
        rows: data.users,
        pageCount: Math.ceil(data.count / pageSize),
      };
    },
    {
      keepPreviousData: true,
    },
  );

  const tableConfig: TableOptions<any> = {
    data: dataQuery.data?.rows ?? [],
    columns,
    pageCount: dataQuery.data?.pageCount ?? -1,
    state: {
      pagination,
      sorting,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    getPaginationRowModel: getPaginationRowModel(), // If only doing manual pagination, you don't need this
    debugTable: true,
  };
  const table = useReactTable(tableConfig);

  useEffect(() => {
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
  }, [stage, filter]);

  return (
    <div className="p-2">
      <div className="h-2" />{' '}
      <div>
        <div>totalCount: {totalCount}</div>
      </div>
      <div className="searchbar">
        <RDSSearchBar
          size="small"
          searchBarId="search"
          handleSubmit={(value) => {
            setSeearchStr(value);
          }}
        />
      </div>
      <TanstackTable table={table} dataQuery={dataQuery} />
    </div>
  );
};

export default UserListTable;
