import React, { useEffect, useState } from 'react';
import { Stage } from '../../../../../../types';
import { User, getUserSubscription } from '../../../../../../api/3dpresso/user';

const SubscriptionModal = ({ stage, user }: { stage: Stage; user: User | boolean }) => {
  const [data, setData] = useState<any>({});
  useEffect(() => {
    (async function () {
      if (user && typeof user == 'object' && user.idx) {
        const data = await getUserSubscription(user.user_email);
        console.log(data);
        if (data.subscription) {
          setData(data.subscription);
        }
      }
    })();
  }, []);
  return (
    <div>
      {Object.keys(data).length > 0 ? (
        <div>
          <div>구독 플랜: {data.items.data[0].price.lookup_key}</div>
          <div>billing cycle anchor : 한국시간 {new Date(data.billing_cycle_anchor * 1000).toLocaleString()}</div>
          <div>current_period_start : 한국시간 {new Date(data.current_period_start * 1000).toLocaleString()}</div>
          <div>current_period_end : 한국시간 {new Date(data.current_period_end * 1000).toLocaleString()}</div>
          {data.cancel_at && <div>cancel at : 한국시간 {new Date(data.cancel_at * 1000).toLocaleString()}</div>}
          {data.trial_start && (
            <div>
              <div>trial start: 한국시간 {new Date(data.trial_start * 1000).toLocaleString()}</div>
              <div>trial end: 한국시간 {new Date(data.trial_end * 1000).toLocaleString()}</div>
              <div>trial setting: {JSON.stringify(data.trial_settings.end_behavior)}</div>
            </div>
          )}
        </div>
      ) : (
        <div>NO PLAN YET</div>
      )}
    </div>
  );
};

export default SubscriptionModal;
