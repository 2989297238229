import axios from 'axios';
import { Stage } from '../types';

const config = { baseURL: process.env.REACT_APP_API_PATH };
const AxiosInstance = axios.create(config);

export type model = {
  seq: string;
  userName: string;
  pngUrl: string;
  order: number; //db에서 관리하는 순서
};

export const getShowcaseList = async (stage: Stage): Promise<any> => {
  const res = await AxiosInstance.get(`/3dpresso/showcase`, { params: { stage } });
  return res.data.modelList;
};

export const addShowcaseModel = async (formData: FormData) => {
  const res = await AxiosInstance.post(`/3dpresso/showcase/model`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return res;
};

export const changeShowcaseOrder = async (stage: Stage, newModelList: Array<Object>) => {
  const res = await AxiosInstance.put(`3dpresso/showcase/order`, { stage, newModelList });
  return res.data;
};

export const deleteShowcaseModel = async (stage: Stage, seq: string) => {
  const res = await AxiosInstance.delete(`/3dpresso/showcase/model`, { params: { stage, seq } });
  return res.data;
};
