
const PLICAR_USER_DATA_COLUMN = [
  {
    name: '이름',
    props: 'name',
    selector: (row: any) => row.name,
    width: '180px',
    center: true,
    sortable: true,
  },
  {
    name: '회사',
    props: 'clientCompany',
    selector: (row: any) => row.clientCompany,
    width: '200px',
    center: true,
    sortable: true,
  },
  {
    name: '직책',
    props: 'clientJobPosition',
    selector: (row: any) => row.clientJobPosition,
    width: '200px',
    center: true,
    sortable: true,
  },
  {
    name: 'e-mail',
    props: 'email',
    selector: (row: any) => row.email,
    sortable: true,
    center: true,
    width: '200px',
  },
];

const PLICARZERO_USER_DATA_COLUMN = [
  {
    name: '이메일',
    props: 'user_email',
    selector: (row: any) => row.user_email,
    width: '180px',
    center: true,
    sortable: true,
  },
  {
    name: '이름',
    props: 'user_name',
    selector: (row: any) => row.user_name,
    width: '150px',
    center: true,
    sortable: true,
  },
  {
    name: '가입날짜',
    props: 'registered_date',
    selector: (row: any) => row.registered_date,
    width: '160px',
    center: true,
    sortable: true,
  },
  {
    name: '가입타입',
    props: 'register_type',
    selector: (row: any) => row.register_type,
    width: '100px',
    center: true,
    sortable: true,
  },
  {
    name: '유저상태',
    props: 'user_status',
    selector: (row: any) => row.user_status,
    width: '100px',
    center: true,
    sortable: true,
  },
];

export const userDataColumn: { [key: string]: any } = {
  plicar: PLICAR_USER_DATA_COLUMN,
  plicarzero: PLICARZERO_USER_DATA_COLUMN,
};

//결제 상태 관련
export const PAYMENT_BEFORE = 'payment_before';
export const PAYMENT_BILLING = 'billing';
export const PAYMENT_BILLING_FAIL = 'billing_fail';
export const PAYMENT_BILLING_CANCEL = 'billing_cancel';
export const PAYMENT_BILLING_END = 'billing_end';