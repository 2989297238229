import { Stage } from '../../../../../types';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { CustomerManagement } from './User/CustomerManagement';
import BeanDashbaord from './Dashboard/BeanDashbaord';

const Bean = ({ stage }: { stage: Stage }) => {
  const [tabValue, setTabValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newTabValue: number) => setTabValue(newTabValue);

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="대시보드" />
            <Tab label="고객 관리" />
            <Tab label="컴플레인 관리" />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <BeanDashbaord stage={stage}/>
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <CustomerManagement stage={stage} />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          Item Three
        </TabPanel>
      </Box>
    </>
  );
};

export function TabPanel(props: { children?: React.ReactNode; index: number; value: any }) {
  const { children, value, index } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'div'}>{children}</Typography>
        </Box>
      )}
    </div>
  );  
}

export default Bean;
