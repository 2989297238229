import React, { useEffect, useRef, useState } from 'react';
import { Stage } from '../../../../../../types';
import { RDSDropdown, RDSOption, RDSToast } from '@reconlabs/reconlabs-fe-components';
import { setUserBean } from '../../../../../../api/3dpresso/bean';

const BeanSetModal = ({ stage, user, closeModal }: { stage: Stage; user: any; closeModal: Function }) => {
  const [beanAmount, setBeanAmount] = useState(0);
  const [beanType, setBeanType] = useState<'free' | 'paid'>('free');
  const [toast, setToast] = useState<string>('');

  const handleChange = (event: any) => {
    setBeanAmount(event.target.value);
  };

  const inputRef = useRef<any>(null);

  const handleClick = async () => {
    if (beanAmount < 0) {
      alert('put bean amount');
      return;
    }
    if (!Number.isInteger(Number(beanAmount))) {
      alert('bean is not integer type');
      return;
    }
    try {
      setToast('bean_put_loading');
      await setUserBean(stage, user.idx, beanType, beanAmount);
      setToast('');
      alert('bean 변경메시지 전송 성공! 잠시만 기다리신후 결제내역을 확인하시면 변경이 완료될 예정입니다');
      closeModal();
    } catch (error) {
      setToast('');
      console.error(error);
      alert('실패');
    }
  };

  useEffect(() => {
    console.log(inputRef.current);
    inputRef.current.focus();
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minWidth: '250px' }}>
      <RDSToast.Overlay
        type="loadingSpinner"
        toastOverlayId="bean_put_loading"
        openedToastOverlay={toast}
        setOpenedToastOverlay={setToast}
      />
      <div style={{ padding: '10px' }}>bean 조정</div>
      <div style={{ color: 'red' }}>{user && user.user_email}</div>

      <p />
      <div style={{ width: '100px' }}>
        <RDSDropdown
          size="small"
          value={beanType}
          onChange={(value) => {
            setBeanType(value);
          }}
        >
          <RDSOption value="free">free</RDSOption>
          <RDSOption value="paid">paid</RDSOption>
        </RDSDropdown>
        <p />
        <input ref={inputRef} type="number" value={beanAmount} onChange={handleChange} />
      </div>

      <p />
      <button style={{ padding: '10px' }} onClick={handleClick}>
        Confirm
      </button>
    </div>
  );
};

export default BeanSetModal;
