import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';

const Signedin = (props: any) => {
  /*----------------------------------------
                     Data
   ----------------------------------------*/
  const navigate = useNavigate();
  /*----------------------------------------
                   Life Cycle
  //  ----------------------------------------*/
  // when first rendered Main.tsx by signed in, user is not defiend yet.
  // but becuase of augment in useAuthenticator, it render again when user changed
  const { user } = useAuthenticator((context) => [context.user]);
  useEffect(() => {
    // if there is token, check user has verified revokeTokens
    if (user) {
      (async function checkUserToken() {
        try {
          const userInfo = await props.httpClient.getLoginInfo();
          console.log(userInfo);
          navigate('/admin/dashboard');
        } catch (err) {
          navigate('/');
        }
      })();
    }
  }, [user]);
  /*----------------------------------------
                 Business Logic
   ----------------------------------------*/

  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return <>signing in</>;
};

export default Signedin;
