import React, { ReactNode } from 'react';
import './LoadingInterface.css';

const LoadingInterface: any = ({ children }: { children: JSX.Element }) => <>{children}</>;

// const LoadingInterface: React.FunctionComponent & {
//   HourGlass: React.FunctionComponent;
//   Ripple: React.FunctionComponent;
//   EyeRolling: React.FunctionComponent;
// } = ({ children }: { children: JSX.Element }) => <>{children}</>;

const HourGlass = () => {
  return <div className="lds-hourglass"></div>;
};
const Ripple = () => {
  return <div className="lds-ripple"></div>;
};
const EyeRolling = () => {
  return <div className="eye-rolling"></div>;
};
LoadingInterface.HourGlass = HourGlass;
LoadingInterface.EyeRolling = EyeRolling;
LoadingInterface.Ripple = Ripple;

export default LoadingInterface;
