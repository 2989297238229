/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { awsconfig } from '../constants/awsConfig';
import { useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import RDSAuthenticator from '../lib/RDSAuthenticator';

const Login = (props: any) => {
  const navigate = useNavigate();
  const { user } = useAuthenticator((context) => [context.user]);
  /*----------------------------------------
                  Event Handler
   ----------------------------------------*/
  useEffect(() => {
    // if there is token, check user has verified token
    if (user) {
      (async function checkUserToken() {
        try {
          const userInfo = await props.httpClient.getLoginInfo();
          console.log(userInfo);
          navigate('/admin/dashboard');
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, [user]);
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <>
      <div className="auth-wrapper">
        <RDSAuthenticator awsconfigure={awsconfig}>사용자를 인식할 수 없습니다 새로고침을 눌러주세요</RDSAuthenticator>
      </div>
    </>
  );
};

export default Login;
