import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, CardContent, Chip, TextField } from '@mui/material';
import {
  AddIcon,
  CheckCircleOutlinedIcon,
  ModalMaker,
  RDSButton,
  RDSDropdown,
  RDSOption,
  RDSToast,
  RemoveOutlinedIcon,
} from '@reconlabs/reconlabs-fe-components';
import SelectUserModal from './SelectUserModal';
import {
  EmailGroup,
  EmailSelectParam,
  getEmailDetail,
  getEmailList,
  postEmail,
} from '../../../../../api/3dpresso/email';
import { PostEmailItem, Stage } from '../../../../../types';
import EmailGroupManagement, { defaultSelectedUserEmail } from './EmailGroupManagement';
import { EditorRef, EmailEditor, EmailEditorProps } from 'react-email-editor';
import EmailHistory from './EmailHistory';

const EmailService = ({ stage }: { stage: Stage }) => {
  const [inspectionType, setInspectionType] = useState<{ value: string; label: string }>({
    value: 'emergency',
    label: '',
  });
  const templates = ['admintoolEmail', 'admintoolEmailEmpty'];
  const [emailTemplate, setEmailTemplate] = useState<string>(templates[0]);
  const [emailTitle, setEmailTitle] = useState<string>('');
  const [emailContent, setEmailContent] = useState<string>('');
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [toast, setToast] = useState<string>('');
  const [openSelectUserModal, setOpenSelectUserModal] = useState(false);
  const [openEmailHistoryModal, setOpenEmailHistoryModal] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState<EmailSelectParam | undefined>(undefined);
  const [selectedGroup, setSelectedGroup] = useState<EmailGroup>();
  const [zero, setZero] = useState<Boolean>(false);
  const emailEditorRef = useRef<EditorRef>(null);

  const onReady: EmailEditorProps['onReady'] = (unlayer) => {
    // editor is ready
    // you can load your template here;
    // the design json can be obtained by calling
    // unlayer.loadDesign(callback) or unlayer.exportHtml(callback)
    // const templateJson = { DESIGN JSON GOES HERE };
    // unlayer.loadDesign(templateJson);
  };

  const applyEmail = async (design: any) => {
    if (!design) {
      alert('해당 이메일의 디자인이 저장되지 않아 적용할 수 없습니다.');
      return;
    }
    // setEmailTitle(data.title);
    const unlayer = emailEditorRef.current?.editor;
    unlayer?.loadDesign(design);
    alert('해당 이메일 템플릿이 적용되었습니다.');
  };

  const sendEmail = async () => {
    setIsButtonLoading(true);

    try {
      const unlayer = emailEditorRef.current?.editor;
      unlayer?.exportHtml(async (data) => {
        const { design, html } = data;
        console.log('exportHtml', html);
        try {
          if (selectedGroup?.group_id) {
            await postEmail({
              type: inspectionType!.value,
              title: emailTitle,
              template_name: emailTemplate,
              content: html || '',
              design: design,
              group_id: selectedGroup.group_id,
              stage: stage,
              zero
            });
          } else {
            await postEmail({
              type: inspectionType!.value,
              title: emailTitle,
              template_name: emailTemplate,
              content: html || '',
              design: design,
              stage: stage,
              ...selectedEmail,
              zero
            });
          }
          alert('이메일을 성공적으로 보냈습니다.');
        } catch (e) {
          console.log(e);
          alert('!!이메일 발송 실패!!ㅠㅠ');
        }
        setIsButtonLoading(false);
      });
    } catch (e) {
      alert('failed');
    }
  };

  useEffect(() => {
    selectedEmail && setSelectedGroup(undefined);
    setOpenSelectUserModal(false);
  }, [selectedEmail]);

  useEffect(() => {
    selectedGroup && setSelectedEmail(undefined);
    setOpenSelectUserModal(false);
  }, [selectedGroup]);

  useEffect(() => {
    setSelectedEmail(undefined);
    setSelectedGroup(undefined);
  }, [stage]);

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '30px', minWidth: '1220px', width: '100%' }}>
        <RDSToast.Overlay
          type="loadingSpinner"
          toastOverlayId="loading"
          openedToastOverlay={toast}
          setOpenedToastOverlay={setToast}
        />
        <ModalMaker isVisible={openSelectUserModal} setIsVisible={setOpenSelectUserModal}>
          <SelectUserOrGroupModal
            stage={stage}
            setSelectedEmail={setSelectedEmail}
            setSelectedGroup={setSelectedGroup}
          />
        </ModalMaker>
        <ModalMaker isVisible={openEmailHistoryModal} setIsVisible={setOpenEmailHistoryModal}>
          <EmailHistory
            stage={stage}
            applyEmail={applyEmail}
            onClickCancel={() => {
              setOpenEmailHistoryModal(false);
            }}
          />
        </ModalMaker>
        <div>전체 메일 발송</div>
        <TextField
          style={{
            width: '750px',
            height: '48px',
            fontSize: '16px',
          }}
          placeholder="제목을 입력해 주세요."
          size="medium"
          variant="standard"
          value={emailTitle}
          onChange={(e) => setEmailTitle(e.target.value)}
        />
        <Button
          variant={'outlined'}
          sx={{
            width: '500px',
          }}
          onClick={() => {
            setOpenSelectUserModal(true);
          }}
        >
          받는 고객
          <AddIcon sx={{ width: '16px', height: '16px', marginLeft: '8px', marginBottom: '3px' }} />
        </Button>
        <Card
          variant="outlined"
          sx={{
            width: '500px',
          }}
        >
          <CardContent>
            <span style={{ color: 'grey' }}>선택된 고객</span>
            {selectedEmail && (
              <div>
                <div style={{ color: 'blue', marginBottom: '10px' }}>User</div>
                <div style={{ display: 'flex', gap: '30px' }}>
                  <div>
                    &#183; {selectedEmail.select_all ? '전체선택' : '일부선택'} <br />
                    &#183; {selectedEmail.select_all ? '추가' : '선택'}된 유저 : {selectedEmail.selected_users.length}{' '}
                    명
                    <br />
                    &#183; 필터링 : {JSON.stringify(selectedEmail.filter)}
                    <br />
                    &#183; 제외된 유저 : {selectedEmail.excluded_users.length}명
                  </div>
                  <Button
                    sx={{ height: '30px' }}
                    variant="outlined"
                    onClick={() => {
                      setSelectedEmail(undefined);
                    }}
                  >
                    취소
                  </Button>
                </div>
              </div>
            )}
            {selectedGroup && (
              <div>
                <div style={{ color: 'blue', marginBottom: '10px' }}>Group</div>
                <div style={{ display: 'flex', gap: '10px' }}>
                  {selectedGroup.group_name}
                  <Button
                    sx={{ height: '30px' }}
                    variant="outlined"
                    onClick={() => {
                      setSelectedGroup(undefined);
                    }}
                  >
                    취소
                  </Button>
                </div>
              </div>
            )}
          </CardContent>
        </Card>
        <div style={{ display: 'flex', gap: '20px' }}>
          <div style={{ width: '400px' }}>
            <div>템플릿 선택</div>
            <RDSDropdown
              size="small"
              width={300}
              value={emailTemplate}
              onChange={(value) => {
                setEmailTemplate(value);
              }}
            >
              {templates.map((template) => (
                <RDSOption key={template} value={template}>
                  {template}
                </RDSOption>
              ))}
            </RDSDropdown>
          </div>

          <div style={{ width: '400px' }}>
            <RDSDropdown
              size="small"
              width={300}
              value={zero ? 'zero' : 'none'}
              onChange={(value) => {
                value == 'zero' ? setZero(true) : setZero(false);
              }}
            >
              <RDSOption key={'zero'} value={'zero'}>
                ZERO
              </RDSOption>
              <RDSOption key={'none'} value={'none'}>
                NONE
              </RDSOption>
            </RDSDropdown>
          </div>
          <div style={{ width: '500px' }}>
            <div>이메일 전송 히스토리</div>
            <RDSButton
              size="xsmall"
              customStyle={{
                width: '50px',
              }}
              onClick={async () => {
                setOpenEmailHistoryModal(true);
              }}
            >
              GO
            </RDSButton>
            {/* <RDSDropdown
            size="small"
            width={300}
            value={''}
            onChange={async(value) => {
              await applyEmail(value)
            }}
          >
            {emailSentList.map((emailsent) => (
              <RDSOption key={emailsent.id} value={emailsent.id}>
               {emailsent.send_date}  {emailsent.title}
              </RDSOption>
            ))}
          </RDSDropdown> */}
          </div>
        </div>
        <EmailEditor minHeight={'600px'} ref={emailEditorRef} onReady={onReady} />
        <RDSButton
          customStyle={{
            width: '750px',
          }}
          isLoading={isButtonLoading}
          onClick={async () => {
            let message;
            if (selectedGroup?.group_id) {
              message = {
                group: selectedGroup.group_name,
                stage: stage,
              };
            } else {
              message = {
                stage: stage,
                ...selectedEmail,
              };
            }
            if (confirm(`${JSON.stringify(message)}로 메일을 보내는게 맞나요?`)) await sendEmail();
            else alert('취소되었습니다.');
          }}
        >
          메일발송
        </RDSButton>
      </div>
    </div>
  );
};

export default EmailService;

const SelectUserOrGroupModal = ({
  stage,
  setSelectedEmail,
  setSelectedGroup,
}: {
  stage: Stage;
  setSelectedEmail: React.Dispatch<React.SetStateAction<EmailSelectParam | undefined>>;
  setSelectedGroup: React.Dispatch<React.SetStateAction<EmailGroup | undefined>>;
}) => {
  const [selection, setSelection] = useState('');
  return (
    <div>
      {selection == '' && (
        <div>
          <Button
            variant={'outlined'}
            onClick={() => {
              setSelection('group');
            }}
          >
            그룹에서 선택(그룹 추가)
          </Button>
          <Button
            variant={'outlined'}
            onClick={() => {
              setSelection('table');
            }}
          >
            유저 테이블에서 선택
          </Button>
        </div>
      )}
      {selection == 'table' && <SelectUserModal setSelectedEmail={setSelectedEmail} stage={stage} />}

      {selection == 'group' && <EmailGroupManagement stage={stage} setSelectedGroup={setSelectedGroup} />}
    </div>
  );
};
