import { Stage } from '../../types';
import { AxiosInstance, getAuthorization } from '../AxiosInstance';
import { Payment } from './user';

export const getBeanList = async (
  stage: Stage,
  user_idx: string,
  options: any,
): Promise<{ payments: Payment[]; count: number }> => {
  await getAuthorization();

  const res = await AxiosInstance.get(`/3dpresso/bean/list`, {
    params: {
      stage,
      user_idx,
      range: `[${options.range}]`,
      transaction_type: JSON.stringify(options.transaction_type),
      transaction_detail: options.transaction_detail,
    },
  });
  return res.data;
};

export const getBeanAnalyzeList = async (stage: Stage, month: number): Promise<any> => {
  await getAuthorization();

  const res = await AxiosInstance.get(`/3dpresso/analyze/bean/list`, {
    params: {
      stage,
      month,
    },
  });
  return res.data;
};
export const putBean = async (
  stage: Stage,
  user_idx: string,
  bean_amount: number,
  ttl: number,
  detail: string,
): Promise<{}> => {
  const res = await AxiosInstance.put(`/3dpresso/bean`, {
    stage,
    user_idx:`${user_idx}`,
    bean_amount: Number(bean_amount),
    ttl,
    detail,
  });
  return res.data;
};

export const setUserBean = async (
  stage: Stage,
  user_idx: string,
  bean_type: 'free' | 'paid',
  bean_amount: number,
): Promise<{}> => {
  const res = await AxiosInstance.put(`/3dpresso/bean/set`, {
    stage,
    user_idx,
    bean_type,
    bean_amount: bean_amount,
  });
  return res.data;
};

export const putBeanAllUser = async (stage: Stage, bean_amount: number): Promise<{}> => {
  const res = await AxiosInstance.put(`/3dpresso/bean/all`, {
    stage,
    bean_amount: Number(bean_amount),
  });
  return res.data;
};
