/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect, useRef, useState } from 'react';
import Chip from '@mui/material/Chip';
import AddTagModal from './AddTagModal';
import UserEditModal from './UserEditModal';
import { UserInfo, PaginationOption, Stage } from '../../types';
import { useLocation } from 'react-router-dom';
import {
  EditIcon,
  LinkIcon,
  RDSCheckbox,
  RDSDataTable,
  RDSModal,
  UploadIcon,
  XMarkIcon,
} from '@reconlabs/reconlabs-fe-components';
import UserEditModalPlicarzero from '../../pages/admin/dashboard/plicarzero/user/UserEditModalPlicarzero';
import DataTable from 'react-data-table-component';
import { UploadProgressContext } from '../../context/UploadProgressContextProvider';
import { getModelViewerScriptSource } from '../../lib/createARScripts';
import { urlToBlob } from '../../lib/utils';
import ModalMaker from '../../modals/ModalMaker';

const CustomerManagement = (props: any) => {
  /*----------------------------------------
                     Data
   ----------------------------------------*/
  // const
  const httpClient = props.httpClient;
  const location = useLocation();
  const projectId = location.search.split('=')[1];
  const inputRef = useRef<HTMLInputElement>(null);
  const { uploadFiles, setUploadFiles } = useContext(UploadProgressContext);

  // boolean
  const [openAddTagModal, setOpenAddTagModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [openPlicarzeroEditModal, setOpenPlicarzeroEditModal] = useState<boolean>(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [openUserModelsModal, setOpenUserModelsModal] = useState<boolean>(false);
  const [checkExcludeReconlabsMember, setCheckExcludeReconlabsMember] = useState(false);

  // internal variables
  const [tags, setTags] = useState<{ value: string; count: number }[]>([]);
  const [tagFilter, setTagFilter] = useState<string>('');
  const [selectedTags, setSelectedTags] = useState<string>('');
  const [selectedUserId, setSelectedUserId] = useState<number>();
  const [selectedUser, setSelectedUser] = useState<any>();

  const [userModelList, setUserModelList] = useState<any[]>([]);
  const [modelGridColumns, setModelGridColumns] = useState<any[]>([]);

  const [columns, setColumns] = useState<any[]>([]);
  const [userList, setUserList] = useState<UserInfo[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [paginationOption, setPaginationOption] = useState<PaginationOption>({
    offset: 0,
    page: 1,
    range: [1, 10],
    rowsPerPage: 10,
    searchText: '',
    currentSort: { props: 'registered_date', type: 'asc' },
  });
  /*----------------------------------------
                  Life Cycle
   ----------------------------------------*/
  useEffect(() => {
    document.querySelector('div.admin-tool_body')?.setAttribute('style', 'height: 1030px');
  }, []);

  useEffect(() => {
    if (document.querySelector('.plicarzero-viewer') === null) {
      const RDSPlicarViewerScript = document.createElement('script');
      RDSPlicarViewerScript.classList.add('plicarzero-viewer');
      RDSPlicarViewerScript.type = 'module';
      RDSPlicarViewerScript.src = getModelViewerScriptSource(); // TODO API Endpoint 변경 및 안정화시 plicar-viewer.min.js 로 변경
      document.head.appendChild(RDSPlicarViewerScript);
    }
  }, []);

  useEffect(() => {
    setColumns([
      ...props.userDataColumn.slice(0, 2),
      {
        name: '모델 개수',
        props: 'model_count',
        selector: (row: any) => row.model_count,
        width: '100px',
        center: true,
        sortable: true,
        cell: (row: any) => {
          return <button onClick={() => onModelCountClicked(row)}>{row.model_count}</button>;
        },
      },
      {
        name: '현재 조회수',
        props: 'model_count',
        selector: (row: any) => row.current_viewcount,
        width: '100px',
        center: true,
        sortable: false,
      },
      {
        name: '수정',
        width: '60px',
        center: true,
        cell: (row: any) => (
          <div style={{ cursor: 'pointer' }} onClick={() => onEditButtonClicked(row)}>
            <EditIcon />
          </div>
        ),
      },
      {
        name: '모델 업로드',
        width: '90px',
        center: true,
        cell: (row: any) => (
          <div style={{ cursor: 'pointer' }} onClick={() => onClickModelUpload(row)}>
            <UploadIcon />
          </div>
        ),
      },
      // {
      //   name: '태그',
      //   selector: (row: any) => row.tags,
      //   sortable: true,
      //   center: true,
      //   width: '200px',
      //   cell: (row: any) =>
      //     row && row.tags && row.tags.length > 0 ? (
      //       row.tags.split(',').map((tag: string, index: number) => {
      //         return (
      //           <>
      //             <Chip
      //               key={tag}
      //               label={`${tag}`}
      //               sx={{
      //                 marginLeft: index === 0 ? '' : '5px',
      //               }}
      //               variant="outlined"
      //             />
      //             {index === row.tags.split(',').length - 1 ? (
      //               <AddCircleOutlineIcon
      //                 key={index}
      //                 sx={{ color: '#00000071', cursor: 'pointer', marginLeft: '5px' }}
      //                 onClick={() => onAddTagButtonClicked(row.tags, row.id)}
      //               />
      //             ) : (
      //               ''
      //             )}
      //           </>
      //         );
      //       })
      //     ) : (
      //       <AddCircleOutlineIcon
      //         sx={{ color: '#00000071', cursor: 'pointer' }}
      //         onClick={() => onAddTagButtonClicked(row.tags, row.id)}
      //       />
      //     ),
      // },
      ...props.userDataColumn.slice(2),
      {
        name: '삭제',
        width: '60px',
        cell: (row: any) => (
          <div style={{ cursor: 'pointer' }} onClick={() => onDeleteButtonClicked(row)}>
            <XMarkIcon />
          </div>
        ),
      },
      {
        name: '홈페이지 접속',
        width: '100px',
        cell: (row: any) => (
          <div style={{ cursor: 'pointer' }} onClick={() => onClickedAccessWithUser(row)}>
            <LinkIcon />
          </div>
        ),
      },
    ]);

    initVars();
  }, [props.stage]);

  useEffect(() => {
    initVars();
  }, [tagFilter, tagFilter.length, paginationOption, props.stage, checkExcludeReconlabsMember]);

  useEffect(() => {
    // DataTable Search filter area style
    if (userList.length > 0) {
      const el: HTMLElement = document.querySelector('.RDSDataTable > header')!;
      el.style.display = 'block';
      el.style.position = 'static';
      el.style.width = '400px';

      // table body style
      const tableBody = document.querySelector('.rdt_Table')?.parentElement?.parentElement;
      if (tableBody) {
        tableBody.style.height = '487px';
        tableBody.style.overflow = 'auto';
      }
    }
  }, [userList, userList.length]);

  useEffect(() => {
    httpClient.excludeInternalppl = checkExcludeReconlabsMember;
  }, [checkExcludeReconlabsMember]);
  /*----------------------------------------
                 Business Logic
   ----------------------------------------*/
  const initVars = async () => {
    // const data = await httpClient.getUserInfo(projectId);
    const res = await httpClient.getUserList(
      projectId,
      tagFilter,
      paginationOption,
      props.stage,
      checkExcludeReconlabsMember,
    );
    const count = res.count;
    // const tags = data.tags;
    setTotalCount(count);
    setTags(tags);
    setUserList(res.users);
  };
  /*----------------------------------------
                  Event Handler
   ----------------------------------------*/
  const onClickedExcludeReconlabsMember = () => {
    checkExcludeReconlabsMember ? setCheckExcludeReconlabsMember(false) : setCheckExcludeReconlabsMember(true);
  };
  const onClickedAccessWithUser = async (row: any) => {
    const token = await httpClient.postUserAccessToken(projectId, props.stage, row.uid);
    window.open(`https://prod-admin.webapp.plicarzero.rlabsdev.com/login?access_token=${token.access_token}`, '_blank');
  };

  const onModelCountClicked = async (userGridRow: any) => {
    setOpenUserModelsModal(true);

    const data = await props.httpClient.getUserModelList(projectId, userGridRow, props.stage);

    setSelectedUser(userGridRow);
    setUserModelList(data.models);
    setModelGridColumns([
      {
        name: '모델명',
        props: 'modelName',
        selector: (row: any) => row.modelName,
        width: '200px',
        center: true,
        sortable: true,
      },
      {
        name: '다운로드',
        props: 'modelGlbURL',
        selector: (row: any) => row.modelGlbURL,
        width: '200px',
        center: true,
        sortable: true,
        cell: (row: any) => {
          return (
            <button
              onClick={async () => {
                const blob: any = await urlToBlob(row.modelGlbURL);
                const link = document.createElement('a');
                link.download = `${row.modelName}`;
                link.href = window.URL.createObjectURL(blob);
                link.click();
              }}
            >
              GLB Download
            </button>
          );
        },
      },
      {
        name: '3D 모델',
        props: 'modelName',
        selector: (row: any) => row.modelName,
        width: '200px',
        center: true,
        sortable: true,
        cell: (row: any) => {
          return (
            <>
              {/* @ts-ignore */}
              <plicarzero-viewer
                style={{ width: '100%', height: '100%' }}
                viewer-uid={''}
                viewer-data={JSON.stringify(row)}
                exposure="1"
                environment-image={'neutral'}
                camera-controls
                camera-orbit="25deg 75deg 105%"
                max-camera-orbit="Infinity 170deg 120%"
                min-camera-orbit="-Infinity 10deg auto"
              />
            </>
          );
        },
      },
    ]);
  };

  const onAddTagButtonClicked = (tags: string, id: number) => {
    if (tags && tags !== 'undefined' && tags !== 'null') {
      setSelectedTags(tags);
    } else {
      setSelectedTags('');
    }
    setSelectedUserId(id);
    setOpenAddTagModal(true);
  };

  const onClickModelUpload = (row: any) => {
    setSelectedUser(row);
    inputRef.current?.click();
  };
  const onEditButtonClicked = (row: any) => {
    if (projectId === 'plicarzero') {
      setSelectedUser(row);
      setOpenPlicarzeroEditModal(true);
    } else {
      const clientCompany = row.clientCompany;
      const clientJobPosition = row.clientJobPosition;
      const email = row.email;
      setSelectedUser({
        id: row.id,
        name: row.name,
        clientCompany: clientCompany && clientCompany !== 'undefined' && clientCompany !== 'null' ? clientCompany : '',
        clientJobPosition:
          clientJobPosition && clientJobPosition !== 'undefined' && clientJobPosition !== 'null'
            ? clientJobPosition
            : '',
        email: email,
      });
      setOpenEditModal(true);
    }
  };

  const onDeleteButtonClicked = (row: any) => {
    setSelectedUser(row);
    setDeleteModalOpen(true);
  };

  const onClickUserDeleteConfirm = async (user: any) => {
    await httpClient.deleteUserInfo(projectId, user, props.stage).then((result: number) => {
      console.log(result);
      if (result === 200) {
        refresh();
      }
    });
  };

  const handleClick = (tag: string) => {
    if (tagFilter === tag) {
      setTagFilter('');
    } else {
      setTagFilter(tag);
    }
  };

  const handlePaginationOption = (e: any) => {
    setPaginationOption(e);
  };

  const uploadFile = async (user: any, file: File, stage: Stage, projectId: string) => {
    const fileUid = Math.random().toString(36);
    const { id: modelId, urls } = await httpClient._getMultipartUploadSignedUrls(
      user,
      file.name,
      file.size,
      stage,
      projectId,
    );
    setUploadFiles([...uploadFiles, { user, file, progress: 0 }]);

    let partNumber = 1; // 파트를 나타내는 1~10,000 사이 숫자
    let iteration = 0; // 업로드 인덱스를 타나내는 0 부터 시작하는 숫자

    console.log(urls);
    const maxPartNumber = urls.length; //
    const segmentSize = Math.ceil(file.size / maxPartNumber);

    for (partNumber; partNumber <= maxPartNumber; partNumber++) {
      const uploadChunk = await file
        .slice(
          iteration * segmentSize, // 자르기 시작데이터
          partNumber === maxPartNumber // 마지막 청크일 경우
            ? file.size // 파일끝까지 업로드
            : (iteration + 1) * segmentSize,
        )
        .arrayBuffer(); // file 읽은 부분 Buffer로 만듦

      try {
        await httpClient._putUpload(urls[iteration], uploadChunk);
        setUploadFiles([...uploadFiles, { user, file, progress: Math.floor((partNumber / maxPartNumber) * 100) }]);
      } catch {
        alert(`${file.name}업로드에 실패하였습니다`);
        break;
      }
      iteration += 1;
    }
    // 종료 ('complete'상태로 변경)
    try {
      await httpClient._postMultipartUploadComplete(modelId, stage, projectId);
    } catch {
      alert(`${file.name}업로드에 실패하였습니다`);
    }
  };

  const refresh = async () => {
    const userData = await httpClient.getUserList(
      projectId,
      tagFilter,
      paginationOption,
      props.stage,
      checkExcludeReconlabsMember,
    );
    setUserList(userData.users);
    setTotalCount(userData.count);
  };
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <>
      <input
        id="RDSUpload-input"
        ref={inputRef}
        type={'file'}
        onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
          const stage = props.stage;
          if (event.target.files) {
            Array.from(event.target.files).map(async (file) => {
              await uploadFile(selectedUser, file, stage, projectId);
            });
          }
          inputRef.current!.value = '';
        }}
        style={{ display: 'none' }}
      ></input>
      <ModalMaker state={openAddTagModal}>
        <AddTagModal
          tags={tags}
          userTableData={userList}
          selectedTags={selectedTags}
          selectedUserId={selectedUserId}
          setOpenAddTagModal={setOpenAddTagModal}
          saved={refresh}
          initVars={initVars}
          httpClient={httpClient}
        />
      </ModalMaker>
      <ModalMaker state={openEditModal}>
        <UserEditModal
          selectedUser={selectedUser}
          setOpenEditModal={setOpenEditModal}
          saved={refresh}
          httpClient={httpClient}
          stage={props.stage}
        />
      </ModalMaker>
      <ModalMaker state={openPlicarzeroEditModal}>
        <UserEditModalPlicarzero
          selectedUser={selectedUser}
          setOpenEditModal={setOpenPlicarzeroEditModal}
          saved={refresh}
          httpClient={httpClient}
          stage={props.stage}
        />
      </ModalMaker>

      <RDSModal
        title={selectedUser && `${selectedUser.user_name} 님의 모델 목록`}
        enableCloseButton={false}
        supportingText={
          <>
            <DataTable className="UserModelsTable" data={userModelList} columns={modelGridColumns} pagination />
          </>
        }
        open={openUserModelsModal}
        onClose={() => setOpenUserModelsModal(false)}
        buttonType={'noBox'}
        button1Label={'닫기'}
        button1Fn={() => setOpenUserModelsModal(false)}
        customStyle={{
          '.RDSModal': {
            width: '700px',
          },
          '.UserModelsTable .rdt_TableBody': {
            height: '375px',
            overflowY: 'scroll',
          },
          '.UserModelsTable .rdt_TableRow': {
            minHeight: '168px',
          },
        }}
      />

      <RDSModal
        title={`유저를 삭제하시겠습니까?`}
        button2Label={'취소'}
        button2Fn={() => {
          setDeleteModalOpen(false);
        }}
        button1Label={'확인'}
        button1Fn={() => {
          onClickUserDeleteConfirm(selectedUser);
          setDeleteModalOpen(false);
        }}
        open={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
        }}
      />
      <div
        css={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          border: '1px solid #000000',
          padding: '15px',
        }}
      >
        <div style={{ padding: 12 }}></div>
        <div>
          <div
            css={{
              fontFamily: 'Noto Sans, sans-serif',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '20px',
              lineHeight: '32px',
              letterSpacing: '0.15px',
              color: 'rgba(0, 0, 0, 0.87)',
            }}
          >
            전체 고객 수 : {totalCount}
          </div>
          <div>
            <div
              css={{
                marginTop: '24px',
                fontFamily: 'Noto Sans, sans-serif',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '13px',
                lineHeight: '18px',
                letterSpacing: '0.16px',
                color: 'rgba(0, 0, 0, 0.87)',
              }}
            >
              태그별 고객 보기
            </div>
            <div
              css={{
                marginTop: '24px',
              }}
            >
              {tags && tags.length > 0
                ? tags.map((tag: { value: string; count: number }, index: number) => {
                    return (
                      <Chip
                        key={tag.value}
                        label={`${tag.value}(${tag.count})`}
                        sx={{
                          marginLeft: index !== 0 ? '10px' : '',
                          backgroundColor: tag.value === tagFilter ? '#0071FF' : '',
                          color: tag.value === tagFilter ? '#FFFFFF' : '',
                        }}
                        variant="outlined"
                        onClick={() => handleClick(tag.value)}
                      />
                    );
                  })
                : ''}
            </div>
          </div>
        </div>
        <div
          css={{
            width: '100%',
            height: '100%',
            marginTop: '24px',
          }}
        >
          <RDSCheckbox.IconType
            checked={checkExcludeReconlabsMember}
            handleCheck={onClickedExcludeReconlabsMember}
            label={'리콘랩스 멤버 제외'}
          />
          <RDSDataTable
            data={userList}
            columns={columns}
            searchInput={'default'}
            searchFilterKeyNames={[
              'clientCompany',
              'clientJobPosition',
              'name',
              'email',
              'user_name',
              'user_email',
              'tags',
            ]}
            customStyle={{
              width: '100%',
            }}
            dataTableOptions={{
              paginationPerPage: 10,
              paginationRowsPerPageOptions: [10, 30, 50, 100, 300],
            }}
            sortServer={true}
            pagination={true}
            paginationServer={true}
            paginationTotalRows={totalCount}
            actionButton={<></>}
            onChangePaginationOption={handlePaginationOption}
          />
        </div>
      </div>
    </>
  );
};

export default CustomerManagement;
