import { Button, Checkbox } from '@mui/material';
import { RDSButton, RDSDataTable, RDSSearchBar } from '@reconlabs/reconlabs-fe-components';
import { ColumnDef } from '@tanstack/react-table';
import React, { useEffect, useState } from 'react';
import { User } from '../../../../../api/3dpresso/user';
import UserListTable from '../Bean/User/UserListTable';
import { Stage } from '../../../../../types';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { EmailSelectParam } from '../../../../../api/3dpresso/email';
import { defaultSelectedUserEmail } from './EmailGroupManagement';
import CSVSelector from '../../../../../components/CSVselector';

const SelectUserModal = ({
  stage,
  setSelectedEmail,
}: {
  stage: Stage;
  userEmailListToSendEmail?: EmailSelectParam;
  setSelectedEmail: (user: EmailSelectParam) => any;
}) => {
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [selectedCheckBox, setSelectedCheckBox] = useState<string[]>(defaultSelectedUserEmail.selected_users);
  const [filter, setFilter] = useState<Record<string, any>>({});

  const onBoxClicked = (row: any) => {
    let updatedCheckedBoxes;
    if (selectedCheckBox.includes(row.user_email)) {
      updatedCheckedBoxes = selectedCheckBox.filter((checkedBox: any) => checkedBox !== row.user_email);
    } else {
      updatedCheckedBoxes = [...selectedCheckBox, row.user_email];
    }
    setSelectedCheckBox(updatedCheckedBoxes);
  };
  useEffect(() => {
    setSelectedCheckBox([]);
  }, [selectAll]);

  const columns = React.useMemo<ColumnDef<User>[]>(
    () => [
      {
        header: 'Info',
        footer: (props) => props.column.id,
        columns: [
          {
            header: 'check',
            enableSorting: false,
            accessorFn: (row) => row,
            cell: (row) => (
              <Checkbox
                checked={
                  selectAll
                    ? !selectedCheckBox.includes(row.getValue().user_email)
                    : selectedCheckBox.includes(row.getValue().user_email)
                }
                onClick={() => {
                  onBoxClicked(row.getValue());
                }}
              />
            ),
            footer: (props) => props.column.id,
          },
          {
            // accessorFn: (row) => row.user_email,
            accessorKey: 'user_email',
            cell: (info) => info.getValue(),
            footer: (props) => props.column.id,
          },
          {
            // accessorFn: (row) => row.user_name,
            // id: 'user_name',
            accessorKey: 'user_name',
            cell: (info) => info.getValue(),
            // header: () => <span>user_name/span>,
            footer: (props) => props.column.id,
          },
        ],
      },
      {
        header: 'beans',
        footer: (props) => props.column.id,
        columns: [
          {
            header: 'all beans',
            enableSorting: false,
            accessorFn: (row) => row.free_beans + row.paid_beans,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'free_beans',
            // header: () => <span>free_beans</span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'paid_beans',
            // header: () => <span>paid_beans</span>,
            footer: (props) => props.column.id,
          },
        ],
      },
    ],
    [selectAll, selectedCheckBox],
  );

  return (
    <div style={{ padding: '10px', width: '1000px', maxHeight: '700px', overflow: 'scroll', backgroundColor: 'white' }}>
      <h2>메일 받을 고객</h2>
      <div style={{ display: 'flex', gap: '20px', margin: '10px' }}>
        <Button
          variant="contained"
          onClick={() => {
            setSelectAll(!selectAll);
          }}
        >
          전체 선택
        </Button>
        <Button variant={'outlined'}>
          CSV import
          <CSVSelector
            onChange={(data) => {
              const user_email_arr: string[] = [];
              let user_email_idx: number | undefined;
              for (const row of data) {
                // find user_email column from the first row
                if (user_email_idx == undefined) {
                  row.forEach((value, idx) => {
                    if (value == 'user_email' || value == 'email') user_email_idx = idx;
                  });
                  continue;
                }
                // user_email_arr.push({ user_email: row[user_email_idx] });
                row[user_email_idx] && user_email_arr.push(row[user_email_idx]);
                // if cannot find user_email continue to next row
              }
              console.log(user_email_arr);

              setSelectAll(false);
              setSelectedEmail({ select_all: false, selected_users: user_email_arr, excluded_users: [], filter: {} });
            }}
          />
        </Button>
      </div>

      <h6>Filter</h6>
      <FormControl size="small" sx={{ width: '150px' }}>
        <InputLabel id="select-label">Marketing Agreed</InputLabel>
        <Select
          labelId="select-label"
          id="demo-simple-select"
          value={filter.marketing_agreed == undefined ? '' : filter.marketing_agreed}
          // value={`${filter.marketing_agreed}` || ''}
          label="Marketing Agreed"
          onChange={(e) => {
            const value = e.target.value;
            if (value == '') {
              filter.marketing_agreed !== undefined && delete filter.marketing_agreed;
              setFilter({ ...filter });
            } else {
              setFilter({ ...filter, marketing_agreed: value == 'true' ? true : false });
            }
          }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={'true'}>true</MenuItem>
          <MenuItem value={'false'}>false</MenuItem>
        </Select>
      </FormControl>
      <p />
      <FormControl size="small" sx={{ width: '150px' }}>
        <InputLabel id="select-label">Country</InputLabel>
        <Select
          labelId="select-label"
          id="demo-simple-select"
          value={filter.country || ''}
          // value={`${filter.marketing_agreed}` || ''}
          label="Marketing Agreed"
          onChange={(e) => {
            const value = e.target.value;
            if (value == '') {
              console.log(filter.country);
              filter.country !== undefined && delete filter.country;
              setFilter({ ...filter });
            } else {
              setFilter({ ...filter, country: value });
            }
          }}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={'ko'}>ko</MenuItem>
          <MenuItem value={'en'}>en</MenuItem>
        </Select>
      </FormControl>

      <UserListTable stage={stage} columns={columns} filter={filter} />

      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
        <RDSButton
          customStyle={{ width: '100px' }}
          onClick={() => {
            const userList: EmailSelectParam = {
              select_all: selectAll,
              excluded_users: [],
              selected_users: [],
              filter,
            };
            selectAll ? (userList.excluded_users = selectedCheckBox) : (userList.selected_users = selectedCheckBox);
            setSelectedEmail(userList);
          }}
        >
          선택
        </RDSButton>
      </div>
    </div>
  );
};

export default SelectUserModal;

// // 돌아가는걸 봤을때 각 row의 열마다 pagination해도 id와 state는 동일함
// const CheckBox = ({ row, onBoxClicked, initialChecked }: any) => {
//   // useEffect(() => {
//   //   console.log('update', checked);
//   // }, [checked]);

//   // const onCheckBoxClicked = (row: any) => {
//   //   onBoxClicked(row);
//   //   if (checked.includes(row.user_email)) {
//   //     setChecked(checked.filter((checkedBox: any) => checkedBox !== row.user_email));
//   //   } else {
//   //     setChecked([...checked, row.user_email]);
//   //   }
//   // };

//   return (
//     <div style={{ cursor: 'pointer' }} onClick={() => onBoxClicked(row)}>
//       <Checkbox checked={initialChecked ? !checked.includes(row.user_email) : checked.includes(row.user_email)} />
//     </div>
//   );
// };
