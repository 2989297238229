import { AxisOptions, Chart } from 'react-charts';
import ResizableBox from './ResizableBox';
import React from 'react';

const TankstackChart = ({
  width = 300,
  height = 300,
  x_axis,
  y_axis,
  data,
}: {
  width?: number;
  height?: number;
  x_axis: string;
  y_axis: string;
  data: any;
}) => {
  console.log(x_axis, y_axis, data);

  const primaryAxis = React.useMemo<AxisOptions<any>>(
    () => ({
      getValue: (datum) => datum[x_axis],
    }),
    [],
  );

  const secondaryAxes = React.useMemo<AxisOptions<any>[]>(
    () => [
      {
        getValue: (datum) => datum[y_axis],
        hardMin: 0,
      },
    ],
    [],
  );

  return (
    <div>
      <ResizableBox width={width} height={height}>
        <Chart
          options={{
            data: data,
            primaryAxis,
            secondaryAxes,
          }}
        />
      </ResizableBox>
    </div>
  );
};

export default TankstackChart;
