/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { RDSButton, RDSDropdown, RDSInputField, RDSModal, RDSOption } from '@reconlabs/reconlabs-fe-components';
import { AxiosResponse } from 'axios';

const UserEditModalPlicarzero = (props: any) => {
  /*----------------------------------------
                     Data
   ----------------------------------------*/
  const httpClient = props.httpClient;
  const location = useLocation();
  const projectId = location.search.split('=')[1];
  const user = props.selectedUser;
  const [name, setName] = useState<string>('불러오는 중');
  const [maxModelCount, setMaxModelCount] = useState<number | string>('불러오는 중');
  const [maxViewCount, setMaxViewCount] = useState<number | string>('불러오는 중');
  const [addWaterMark, setAddWaterMark] = useState<boolean | string>('불러오는 중');
  const [uploadFileSize, setUploadFileSize] = useState<number | string>('불러오는 중');
  const [userTier, setUserTier] = useState<string | null>('불러오는 중');
  const [openDeleteBillingKeyModal, setOpenDeleteBillingKeyModal] = useState(false);
  /*----------------------------------------
                  Life Cycle
   ----------------------------------------*/
  useEffect(() => {
    initVars();
  }, []);
  /*----------------------------------------
                 Business Logic
   ----------------------------------------*/
  const initVars = async () => {
    const user_permission = JSON.parse(user.user_permission);
    setName(user.user_name);
    setMaxModelCount(user_permission.max_model_count);
    setMaxViewCount(user_permission.max_view_count);
    setAddWaterMark(user_permission.add_water_mark);
    setUploadFileSize(user_permission.upload_file_size);
    setUserTier(user.user_tier);
  };

  const save = () => {
    const user_permission = JSON.stringify({
      add_water_mark: addWaterMark,
      upload_file_size: uploadFileSize,
      max_view_count: maxViewCount,
      max_model_count: maxModelCount,
    });
    const body = {
      user_email: user.user_email,
      user_tier: userTier,
      user_permission,
    };
    httpClient.putUserInfoPlicarzero(projectId, body, props.stage).then((result: number) => {
      if (result === 200) {
        props.setOpenEditModal(false);
        props.saved();
      }
    });
  };

  const deleteBillingKey = () => {
    httpClient.deleteBillingKeyZero(props.stage, user.user_email).then((response: AxiosResponse) => {
      if (response?.status === 200) alert('삭제 완료');
      else alert('빌링키가 없거나 삭제에 실패했습니다');
    });
    setOpenDeleteBillingKeyModal(false);
  };
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <div
      css={{
        backgroundColor: '#FFFFFF',
        width: '600px',
        padding: '20px',
        borderRadius: '16px',
      }}
    >
      <div>고객 정보 수정</div>
      <div
        css={{
          marginTop: '10px',
          marginBottom: '10px',
        }}
      >
        <div css={{ marginLeft: '15px', fontSize: '15px', fontWeight: '700' }}>user email</div>
        <div css={{ fontSize: '20px', fontWeight: '500', color: 'blue' }}> {user.user_email}</div>
      </div>

      {/* 수정사항 컨테이너 */}
      <div css={{ display: 'flex' }}>
        {/* 드롭다운 */}
        <div css={{ width: '280px' }}>
          <div css={{ marginLeft: '15px', fontSize: '15px', fontWeight: '700' }}>user tier</div>
          <RDSDropdown
            width="230px"
            value={userTier!}
            onChange={(e) => {
              alert(
                '유저 티어 변경 시, 나머지 사항들과 동시에 변경이 불가합니다. 티어변경 저장 후 별도로 변경 부탁드립니다.',
              );
              setUserTier(e);
            }}
          >
            <RDSOption value={'Free'}>Free</RDSOption>
            <RDSOption value={'Basic'}>Basic</RDSOption>
            <RDSOption value={'Plus'}>Plus</RDSOption>
            <RDSOption value={'Enterprise'}>Enterprise</RDSOption>
          </RDSDropdown>
          <div css={{ marginLeft: '15px', fontSize: '15px', fontWeight: '700', marginTop: '20px' }}>워터마크 onoff</div>
          <RDSDropdown
            width="230px"
            value={`${addWaterMark}`}
            onChange={(e) => {
              setAddWaterMark(e);
            }}
          >
            <RDSOption value={'true'}>on</RDSOption>
            <RDSOption value={'false'}>off</RDSOption>
          </RDSDropdown>
          <RDSButton
            type="solid"
            color="grey_dark"
            onClick={() => setOpenDeleteBillingKeyModal(true)}
            customStyle={{ marginTop: '30px' }}
          >
            유저 정기 결제 키 삭제
          </RDSButton>
        </div>

        {/* 텍스트필드 */}
        <div css={{ width: '250px' }}>
          <RDSInputField.Text
            width="250px"
            title="user name"
            variant="standard"
            value={name}
            handleInputChange={(e) => {
              setName(e.target.value);
            }}
          />
          <RDSInputField.Text
            width="250px"
            title="upload file size"
            variant="standard"
            value={uploadFileSize!}
            handleInputChange={(e: any) => {
              setUploadFileSize(e.target.value);
            }}
          />
          <RDSInputField.Text
            width="250px"
            title="max model count"
            variant="standard"
            value={maxModelCount!}
            handleInputChange={(e: any) => {
              setMaxModelCount(e.target.value);
            }}
          />
          <RDSInputField.Text
            width="250px"
            title="max view count"
            variant="standard"
            value={maxViewCount!}
            handleInputChange={(e: any) => {
              setMaxViewCount(e.target.value);
            }}
          />
        </div>
      </div>

      {/* button area */}
      <div
        css={{
          marginTop: '20px',
          display: 'flex',
          justifyContent: 'right',
          gap: '10px',
        }}
      >
        <RDSButton type={'outline'} onClick={() => props.setOpenEditModal(false)}>
          취소
        </RDSButton>
        <RDSButton type={'solid'} onClick={save}>
          저장
        </RDSButton>
      </div>
      <RDSModal
        open={openDeleteBillingKeyModal}
        onClose={() => setOpenDeleteBillingKeyModal(false)}
        enableCloseButton={false}
        title="정기 결제 해지"
        supportingText={`유저 ${user.user_email}의 정기 결제 키를 삭제합니다. 티어는 변동되지 않습니다.`}
        buttonType="noBox"
        button1Label="닫기"
        button1Color="grey"
        button1Fn={() => setOpenDeleteBillingKeyModal(false)}
        button2Label="확인"
        button2Color="warning"
        button2Fn={deleteBillingKey}
      ></RDSModal>
    </div>
  );
};

export default UserEditModalPlicarzero;
