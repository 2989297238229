import React, { useEffect } from 'react';

import './CustomerManagement.css';
import { ColumnDef } from '@tanstack/react-table';
import { TableFetchOptions, User, getUserList } from '../../../../../../api/3dpresso/user';
import { Stage } from '../../../../../../types';
import { ModalMaker, RDSButton, RDSIconButton, RemoveOutlinedIcon } from '@reconlabs/reconlabs-fe-components';
import BeanListModal from './BeanListModal';
import BeanPutModal from './BeanPutModal';
import UserListTable from './UserListTable';
import BeanSetModal from './BeanSetModal';
import PaymentListModal from './PaymentListModal';
import SubscriptionModal from './SubscriptionModal';

export const CustomerManagement = ({ stage }: { stage: Stage }) => {
  const [openBeanListModal, setOpenBeanListModal] = React.useState<boolean | User>(false);
  const [openPaymentListModal, setOpenPaymentListModal] = React.useState<boolean | User>(false);
  const [openBeanPutModal, setOpenBeanPutModal] = React.useState<boolean | User>(false);
  const [openBeanSetModal, setOpenBeanSetModal] = React.useState<boolean | User>(false);
  const [openSubscriptionModal, setOpenSubscriptionModal] = React.useState<boolean | User>(false);
  const columns = React.useMemo<ColumnDef<User>[]>(
    () => [
      {
        header: 'Info',
        footer: (props) => props.column.id,
        columns: [
          {
            // accessorFn: (row) => row.user_email,
            accessorKey: 'user_email',
            cell: (info) => info.getValue(),
            footer: (props) => props.column.id,
          },
          {
            // accessorFn: (row) => row.user_name,
            // id: 'user_name',
            accessorKey: 'user_name',
            cell: (info) => info.getValue(),
            // header: () => <span>user_name/span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'registered_date',
            cell: (info) => info.getValue(),
            // header: () => <span>user_name/span>,
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'user_tier',
            cell: (info) => <div style={{ textAlign: 'center' }}>{info.getValue()}</div>,
            // header: () => <span>user_name/span>,
            footer: (props) => props.column.id,
          },
        ],
      },
      {
        header: 'beans',
        footer: (props) => props.column.id,
        columns: [
          {
            accessorKey: 'bean',
            cell: (info) => <div style={{ textAlign: 'center' }}>{info.getValue()}</div>,
            footer: (props) => props.column.id,
          },
          {
            header: '빈 지급 내역',
            enableSorting: false,
            accessorFn: (row) => row,
            cell: (info) => (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <RDSButton
                  type="solid"
                  size="xsmall"
                  // icon={<RemoveOutlinedIcon />}
                  onClick={() => {
                    setOpenBeanListModal(info.getValue());
                  }}
                >
                  빈 지급 내역
                </RDSButton>
              </div>
            ),
            footer: (props) => props.column.id,
          },
          {
            header: '결제 내역',
            enableSorting: false,
            accessorFn: (row) => row,
            cell: (info) => (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <RDSButton
                  type="solid"
                  size="xsmall"
                  // icon={<RemoveOutlinedIcon />}
                  onClick={() => {
                    setOpenPaymentListModal(info.getValue());
                  }}
                >
                  결제 내역
                </RDSButton>
              </div>
            ),
            footer: (props) => props.column.id,
          },
          {
            header: '구독 상세',
            enableSorting: false,
            accessorFn: (row) => row,
            cell: (info) => (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <RDSButton
                  type="solid"
                  size="xsmall"
                  // icon={<RemoveOutlinedIcon />}
                  onClick={() => {
                    setOpenSubscriptionModal(info.getValue());
                  }}
                >
                  구독 상세
                </RDSButton>
              </div>
            ),
            footer: (props) => props.column.id,
          },
          {
            header: 'bean 지급',
            enableSorting: false,
            accessorFn: (row) => row,
            cell: (info) => (
              <RDSButton
                type="solid"
                size="xsmall"
                onClick={() => {
                  setOpenBeanPutModal(info.getValue());
                }}
              >
                bean 지급
              </RDSButton>
            ),
            footer: (props) => props.column.id,
          },
          // {
          //   header: 'bean 조정',
          //   enableSorting: false,
          //   accessorFn: (row) => row,
          //   cell: (info) => (
          //     <RDSButton
          //       type="solid"
          //       size="xsmall"
          //       onClick={() => {
          //         setOpenBeanSetModal(info.getValue());
          //       }}
          //     >
          //       bean 조정
          //     </RDSButton>
          //   ),
          //   footer: (props) => props.column.id,
          // },
        ],
      },
    ],
    [],
  );

  return (
    <div className="p-2">
      <ModalMaker isVisible={openPaymentListModal && true} setIsVisible={setOpenPaymentListModal}>
        <PaymentListModal stage={stage} user={openPaymentListModal} />
      </ModalMaker>
      <ModalMaker isVisible={openBeanListModal && true} setIsVisible={setOpenBeanListModal}>
        <BeanListModal stage={stage} user={openBeanListModal} />
      </ModalMaker>
      <ModalMaker isVisible={openBeanPutModal && true} setIsVisible={setOpenBeanPutModal}>
        <BeanPutModal stage={stage} user={openBeanPutModal} closeModal={setOpenBeanPutModal} />
      </ModalMaker>
      <ModalMaker isVisible={openBeanSetModal && true} setIsVisible={setOpenBeanSetModal}>
        <BeanSetModal stage={stage} user={openBeanSetModal} closeModal={setOpenBeanSetModal} />
      </ModalMaker>
      <ModalMaker isVisible={openSubscriptionModal && true} setIsVisible={setOpenSubscriptionModal}>
        <SubscriptionModal stage={stage} user={openSubscriptionModal} />
      </ModalMaker>
      {/* <RDSButton
        type="solid"
        size="xsmall"
        onClick={() => {
          setOpenBeanPutModal(true);
        }}
      >
        bean 전체지급
      </RDSButton> */}
      <UserListTable stage={stage} columns={columns} />
    </div>
  );
};
