/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { PostEmailItem } from '../../types';
import TextField from '@mui/material/TextField';
import ReceiverModal from './ReceiverModal';
import { ModalMaker, RDSButton, RDSToast } from '@reconlabs/reconlabs-fe-components';
import { Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useLocation } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import SelectUserModalPlicarzero from './SelectUserModalPlicarzero';
import InspectionTypeSelectBox from '../InspectionTypeSelectBox';
import ServiceHistory from '../ServiceHistory';

const ServiceSendEmail = (props: any) => {
  /*----------------------------------------
										 Data
	 ----------------------------------------*/
  const httpClient = props.httpClient;
  const location = useLocation();
  const projectId = location.search.split('=')[1];
  const [tags, setTags] = useState<{ value: string; count: number; selected: boolean }[]>([]);
  const [historyItems, setHistoryItems] = useState<Array<PostEmailItem>>([]);
  const [inspectionType, setInspectionType] = useState<{ value: string; label: string }>();
  const [emailTitle, setEmailTitle] = useState<string>('');
  const [emailContent, setEmailContent] = useState<string>('');
  const [openReceiverModal, setOpenReceiverModal] = useState<boolean>(false);
  const [receiveIdList, setReceiveIdList] = useState<number[]>([]);
  const [userEmailListToSendEmail, setUserEmailListToSendEmail] = useState<any>([]);
  const [clickedToAllUser, setClickedToAllUser] = useState<boolean>(false);
  const [openSelectUserModalPlicarzero, setOpenSelectUserModalPlicarzero] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [toast, setToast] = useState<string>('');
  const [count, setCount] = useState<number>(0);
  /*----------------------------------------
									 Life Cycle
	 ----------------------------------------*/
  useEffect(() => {
    document.querySelector('div.admin-tool_body')?.setAttribute('style', 'height: 1100px');
    // initVars(undefined);
    initVars(undefined);
  }, [props.stage]);

  useEffect(() => {
    //전체고객 선택한 상태에서 일부유저를 제외시킨경우
    if (clickedToAllUser && userEmailListToSendEmail.length !== count) {
      setClickedToAllUser(false);
    }
  }, [userEmailListToSendEmail]);

  //전체 고객 체크한경우 모든 유저 받아와서 이메일 보낼 arr에 넣는다
  useEffect(() => {
    if (clickedToAllUser) {
      setToast('loading');
      httpClient.getUserList(projectId, '', { searchText: '', range: [0, 9999] }, props.stage).then((res: any) => {
        setCount(res.count);
        setUserEmailListToSendEmail(res.users.map((users: any) => users.user_email));
        setToast('');
      });
    }
  }, [clickedToAllUser]);

  /*----------------------------------------
								 Business Logic
	 ----------------------------------------*/
  const initVars = async (searchWord: string | undefined) => {
    const data = await httpClient.getEmailList(projectId, searchWord, props.stage);
    // const tagData = await httpClient.getUserInfo(projectId, props.stage);
    // const tempTags = tagData.tags;
    // tempTags.forEach((tag: { value: string; count: number; selected: boolean }) => {
    //   tag.selected = false;
    // });
    // setTags(tempTags);
    setEmailTitle('');
    setEmailContent('');
    setUserEmailListToSendEmail([]);
    setClickedToAllUser(false);
    setCount(0);
    setInspectionType({ value: 'emergency', label: '긴급 점검' });
    if (data.length > 16) {
      setHistoryItems(data.slice(0, 16));
    } else {
      setHistoryItems(data);
    }
  };
  /*----------------------------------------
									Event Handler
	 ----------------------------------------*/
  const templateApply = async (item: PostEmailItem) => {
    const data = await httpClient.getEmailDetail(projectId, Number(item.emailId), props.stage);
    setEmailTitle(data.title);
    setEmailContent(data.content);
    setInspectionType({ value: data.typeValue, label: data.typeLabel });
  };

  const historySearch = (searchWord: string) => {
    initVars(searchWord);
  };

  const sendEmail = () => {
    setIsButtonLoading(true);
    httpClient
      .postEmail(projectId, {
        type: inspectionType!.value,
        title: emailTitle,
        content: emailContent,
        userIdArr: userEmailListToSendEmail,
        stage: props.stage,
      })
      .then((result: number) => {
        if (result === 200) {
          alert('메시지를 성공적으로 보냈습니다');
          initVars(undefined);
        }
      });
    setIsButtonLoading(false);
  };
  /*----------------------------------------
								Default Template
	 ----------------------------------------*/
  return (
    <div css={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'row' }}>
      <RDSToast.Overlay
        type="loadingSpinner"
        // loadingSpinner일 시 content, icon은 무시됩니다.
        toastOverlayId="loading"
        openedToastOverlay={toast}
        setOpenedToastOverlay={setToast}
      />
      <ModalMaker isVisible={openReceiverModal} setIsVisible={setOpenReceiverModal}>
        <ReceiverModal
          httpClient={httpClient}
          tags={tags}
          setTags={setTags}
          userIdList={receiveIdList}
          setUserIdList={setReceiveIdList}
          receiveIdList={receiveIdList}
          setReceiverInput={setUserEmailListToSendEmail}
          closeModal={setOpenReceiverModal}
          stage={props.stage}
        />
      </ModalMaker>
      <ModalMaker isVisible={openSelectUserModalPlicarzero} setIsVisible={setOpenSelectUserModalPlicarzero}>
        <SelectUserModalPlicarzero
          setIsVisible={setOpenSelectUserModalPlicarzero}
          httpClient={httpClient}
          userEmailListToSendEmail={userEmailListToSendEmail}
          setUserEmailListToSendEmail={setUserEmailListToSendEmail}
          column={props.userDataColumn}
          stage={props.stage}
        />
      </ModalMaker>
      <div css={{ width: '846px', border: '1px solid #000000', padding: '15px' }}>
        <div
          css={{
            fontFamily: 'Noto Sans, sans-serif',
            fontSize: '20px',
            fontWeight: '500',
            lineHeight: '32px',
            letterSpacing: '0.15px',
            textAlign: 'left',
          }}
        >
          전체 메일 발송
        </div>
        <div
          css={{
            marginTop: '24px',
          }}
        >
          <InspectionTypeSelectBox inspectionType={inspectionType} setInspectionType={setInspectionType} />
        </div>
        <div
          css={{
            marginTop: '24px',
          }}
        >
          <TextField
            sx={{
              width: '505px',
              height: '48px',
              fontFamily: 'Noto Sans, sans-serif',
              fontSize: '16px',
              fontWeight: '400',
              lineHeight: '48px',
              letterSpacing: '0.15px',
              textAlign: 'left',
            }}
            placeholder="제목을 입력해 주세요."
            size="medium"
            variant="standard"
            value={emailTitle}
            onChange={(e) => setEmailTitle(e.target.value)}
          />
        </div>
        <div
          css={{
            marginTop: '24px',
            lineHeight: '48px',
          }}
        >
          <div style={{ display: 'flex', gap: '10px' }}>
            <Chip
              color="primary"
              label={`전체 고객 ${count ? count : ''}`}
              variant={clickedToAllUser ? 'filled' : 'outlined'}
              onClick={() => {
                if (clickedToAllUser) {
                  setClickedToAllUser(false);
                  setUserEmailListToSendEmail([]);
                } else {
                  setClickedToAllUser(true);
                }
              }}
            />
            <Chip
              color="primary"
              label={`선택 고객 ${userEmailListToSendEmail.length}`}
              variant={userEmailListToSendEmail.length === 0 ? 'outlined' : 'filled'}
              onClick={() => {
                setUserEmailListToSendEmail([]);
              }}
            />
            <Button
              variant={'outlined'}
              css={{
                width: '120px',
                height: '36px',
                marginLeft: '24px',
                border: '1px solid rgba(0, 0, 0, 0.6)',
                color: 'rgba(0, 0, 0, 0.6)',
                borderRadius: '4px',
                fontFamily: 'Noto Sans, sans-serif',
                fontSize: '14px',
                fontWeight: '500',
                letterSpacing: '0.4px',
                fontStyle: 'normal',
                display: 'inline-block',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={() => {
                if (projectId === 'plicarzero') {
                  setOpenSelectUserModalPlicarzero(true);
                } else {
                  setOpenReceiverModal(true);
                }
              }}
            >
              받는 고객
              <AddCircleOutlineIcon sx={{ width: '16px', height: '16px', marginLeft: '8px', marginBottom: '3px' }} />
            </Button>
          </div>
        </div>
        <div
          css={{
            marginTop: '24px',
          }}
        >
          {tags && tags.length > 0
            ? tags.map((tag: { value: string; count: number; selected: boolean }, index: number) => {
                return tag.count > 0 ? (
                  <Chip
                    key={`${tag.value}`}
                    label={`${tag.value}(${tag.count})`}
                    sx={{
                      borderColor: tag.selected ? '' : '#0071FF',
                      color: tag.selected ? '#FFFFFF' : '#0071FF',
                      backgroundColor: tag.selected ? '#0071FF' : '',
                      marginLeft: index !== 0 ? '10px' : '',
                    }}
                    variant="outlined"
                  />
                ) : (
                  ''
                );
              })
            : ''}
        </div>
        <textarea
          css={{
            border: '1px solid rgba(0, 0, 0, 0.6)',
            borderRadius: '10px',
            marginTop: '24px',
            padding: '10px',
            height: '401px',
            width: '100%',
            outline: 'none',
            fontFamily: 'Noto Sans, sans-serif',
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '24px',
            letterSpacing: '0.15px',
            color: '#000000',
            resize: 'none',
          }}
          placeholder={'내용을 입력해 주세요.'}
          value={emailContent}
          onChange={(e) => setEmailContent(e.target.value)}
        />
        <div
          css={{
            marginTop: '5px',
            overflow: 'hidden',
          }}
        >
          <div css={{ display: 'flex', justifyContent: 'right' }}>
            <RDSButton isLoading={isButtonLoading} onClick={sendEmail}>
              메일발송
            </RDSButton>
          </div>
        </div>
      </div>
      <div css={{ width: '374px', marginLeft: '10px', border: '1px solid #000000' }}>
        <ServiceHistory type={'email'} emailItems={historyItems} templateApply={templateApply} search={historySearch} />
      </div>
    </div>
  );
};

export default ServiceSendEmail;
