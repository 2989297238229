/* eslint-disable react/display-name */
/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import { ModalMaker, XMarkIcon } from '@reconlabs/reconlabs-fe-components';
import { Viewer } from '@toast-ui/react-editor';
import { PopupType } from '../../types';

type PopupPreviewModalProps = {
  title: string;
  content: string;
  type: PopupType
  headerImageSrc: string;
  width: number;
  height: number;
  closePreviewModal: Function;
};

const PopupPreviewModal = ({
  title,
  content,
  type,
  headerImageSrc,
  width,
  height,
  closePreviewModal,
}: PopupPreviewModalProps) => {
  /*----------------------------------------
                     Data
   ----------------------------------------*/
  const [shouldModalOpen, setShouldModalOpen] = useState<boolean>(true);
  /*----------------------------------------
                 Business Logic
   ----------------------------------------*/
  useEffect(() => {
    if (shouldModalOpen === false) {
      closePreviewModal();
    }
  }, [shouldModalOpen]);

  const onClickNotToSeeAgain = () => {
    closeModal();
  };

  const closeModal = () => {
    setShouldModalOpen(false);
    closePreviewModal();
  };
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <ModalMaker isVisible={shouldModalOpen} setIsVisible={setShouldModalOpen}>
      <div
        css={{
          backgroundColor: 'white',
          width: width,
          height: height,
          overflow: 'scroll',
        }}
      >
        {type === 'notice' ? (
          <PopupPreviewModal.Notice
            onClickNotToSeeAgain={onClickNotToSeeAgain}
            width={width}
            height={height}
            title={title}
            content={content}
          />
        ) : (
          <PopupPreviewModal.Emergency
            onClickNotToSeeAgain={onClickNotToSeeAgain}
            headerImageSrc={headerImageSrc}
            width={width}
            height={height}
            title={title}
            content={content}
          />
        )}
      </div>
    </ModalMaker>
  );
};

export default PopupPreviewModal;


PopupPreviewModal.Notice = ({ onClickNotToSeeAgain, width, height, title, content }: PopupNoticeContentProps) => {
  return (
    <div css={{ padding: width / 20 }}>
      <div
        css={{
          justifyContent: 'right',
          display: 'flex',
          alignItems: 'center',
          gap: '5px',
          marginBottom: '15px',
          fontWeight: '500',
        }}
      >
        다시보지 않을래요
        <div
          onClick={() => {
            onClickNotToSeeAgain('never');
          }}
          css={{ cursor: 'pointer' }}
        >
          <XMarkIcon css={{ color: 'lightgrey' }} />
        </div>
      </div>
      <div
        css={{
          border: '#0071FF 1px solid',
          borderStyle: 'dashed',
          padding: `${height / 20}px ${width / 20}px ${height / 20}px ${width / 20}px`,
        }}
      >
        <div>
          <div
            css={{
              fontFamily: 'Noto Sans KR',
              fontStyle: 'normal',
              fontWeight: '700',
              fontSize: '36px',
              lineHeight: '52px',
              textAlign: 'center',
              whiteSpace: 'pre-line',
              color: '#000000',
              '@media (max-width: 780px)': {
                fontSize: '23px',
                lineHeight: '30px',
              },
            }}
            dangerouslySetInnerHTML={{ __html: title }}
          ></div>
          <Viewer initialValue={content} />
          <p />
          <div css={{ fontWeight: '800' }}>문의가 필요하시면 채널톡을 이용해주세요</div>
        </div>
      </div>
    </div>
  );
};

PopupPreviewModal.Emergency = ({
  onClickNotToSeeAgain,
  width,
  height,
  headerImageSrc,
  title,
  content,
}: PopupNoticeContentProps) => {
  return (
    <div css={{ padding: width / 20 }}>
      <div
        css={{
          justifyContent: 'right',
          display: 'flex',
          alignItems: 'center',
          gap: '5px',
          marginBottom: '15px',
          fontWeight: '500',
        }}
      >
        오늘 다시보지 않을래요
        <div
          onClick={() => {
            onClickNotToSeeAgain(24);
          }}
          css={{ cursor: 'pointer' }}
        >
          <XMarkIcon css={{ color: 'lightgrey' }} />
        </div>
      </div>
      <div
        css={{
          border: '#0071FF 1px solid',
          borderStyle: 'dashed',
          padding: `${height / 20}px ${width / 20}px ${height / 20}px ${width / 20}px`,
        }}
      >
        <div
          css={{
            height: '75px',
            paddingBottom: '30px',
            '@media (max-width: 780px)': {
              height: '40px',
              paddingBottom: '10px',
            },
          }}
        >
          <div
            css={{
              margin: 'auto',
              padding: '10px 0 10px 0',
              background: ' #0071FF',
              width: '70px',
              height: '70px',
              '@media (max-width: 780px)': {
                width: '45px',
                height: '45px',
              },
            }}
          >
            <img
              css={{
                height: '50px',
                margin: 'auto',
                objectFit: 'contain',
                '@media (max-width: 780px)': {
                  marginTop: '5px',
                  width: '10px',
                  height: '35px',
                },
              }}
              src={headerImageSrc}
            />
          </div>
        </div>
        <div>
          <div
            css={{
              fontFamily: 'Noto Sans KR',
              fontStyle: 'normal',
              fontWeight: '700',
              fontSize: '36px',
              lineHeight: '52px',
              textAlign: 'center',
              whiteSpace: 'pre-line',
              color: '#000000',
              '@media (max-width: 780px)': {
                fontSize: '23px',
                lineHeight: '30px',
              },
            }}
            dangerouslySetInnerHTML={{ __html: title }}
          ></div>
          <Viewer initialValue={content} />
          <p />
          <div css={{ fontWeight: '800' }}>문의가 필요하시면 채널톡을 이용해주세요</div>
        </div>
      </div>
    </div>
  );
};

type PopupNoticeContentProps = {
  onClickNotToSeeAgain: Function;
  title: string;
  content: string;
  headerImageSrc?: string;
  width: number;
  height: number;
};
