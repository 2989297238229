/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import { RDSDataTable } from '@reconlabs/reconlabs-fe-components';
import { Button } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import { arrayDeepCopy } from '../../lib/utils';
import { Stage } from '../../types';

type ReceiverModalProps = {
  httpClient: any;
  tags: { value: string, count: number, selected: boolean }[];
  setTags: Function;
  userIdList: number[];
  setUserIdList: Function;
  closeModal: Function;
  setReceiverInput: Function;
  receiveIdList: number[];
  stage: Stage;
}

const columns = [
  {
    name: '고객사',
    selector: (row: any) => row.clientCompany,
    width: '200px',
    center: true,
    sortable: true,
  },
  {
    name: '담당자 이름',
    selector: (row: any) => row.name,
    width: '180px',
    center: true,
    sortable: true,
  },
  {
    name: '메일 주소',
    selector: (row: any) => row.email,
    sortable: true,
    center: true,
    width: '200px',
  },
  {
    name: '태그',
    selector: (row: any) => row.tags,
    sortable: true,
    center: true,
    width: '200px',
    cell: (row: any) =>
      row && row.tags && row.tags.length > 0 && row.tags.split(',').map((tag: string, index: number) => {
        return (
          <Chip
            key={tag}
            label={`${tag}`}
            sx={{
              marginLeft: index === 0 ? '' : '5px'
            }}
            variant="outlined"
          />
        );
      })
  }
];

const ReceiverModal = (props: ReceiverModalProps) => {
  /*----------------------------------------
                     Data
   ----------------------------------------*/
  const location = useLocation();
  const projectId = location.search.split('=')[1];
  const [tags, setTags] = useState<{ value: string, count: number, selected: boolean, userList?: object[] }[]>([]);
  const [userIdList, setUserIdList] = useState<Array<number>>(props.receiveIdList);
  const [userList, setUserList] = useState<Array<any>>([]);
  /*----------------------------------------
                   Life Cycle
   ----------------------------------------*/
  useEffect(() => {
    if (props.tags && props.tags.length > 0) {
      initVars();
    }
  }, [props.tags, props.tags.length]);

  useEffect(() => {
    // Select items 표시 div 제거
    document.querySelector('div.RDSDataTable > div.rdt_TableHeader')?.setAttribute('style', 'display: none');
    // DataTable Search filter area style
    if (userList.length > 0) {
      const el: HTMLElement = document.querySelector('.RDSDataTable > header')!;
      el.style.display = 'block';
      el.style.position = 'static';
      el.style.width = '400px';
    }
  }, [userList, userList.length]);

  useEffect(() => {
    if (tags && tags.length > 0 && userList && userList.length > 0) {
      const taggedUserList = userList.filter((user: any) => user.tags && user.tags.length > 0);
      if (!tags[0].userList) {
        const newTags = arrayDeepCopy(tags);
        newTags.forEach((tag: any) => {
          tag.userList = taggedUserList.filter((user: any) => user.tags.indexOf(tag.value) >= 0);
        });
        setTags(newTags);
      }
    }
  }, [tags, tags.length, userList, userList.length]);

  useEffect(() => {
    checkTagState(userIdList);
  }, [userIdList, userIdList.length]);
  /*----------------------------------------
                 Business Logic
   ----------------------------------------*/
  const initVars = async () => {
    const tagData = await props.httpClient.getUserInfo(projectId, props.stage);
    const userData = await props.httpClient.getEmailList(projectId, undefined, props.stage);
    const tempTags = tagData.tags;

    tempTags.forEach((tag: { value: string, count: number, selected?: boolean }) => {
      if (tag.count > 0) {
        tag.selected = props.tags.find((label: { value: string, count: number, selected: boolean }) => label.value === tag.value)!.selected;
      }
    });

    setTags(tempTags.filter((tag: { value: string, count: number, selected: boolean }) => tag.count > 0));
    setUserList(userData);
  };

  const getSelectedUserNames = () => {
    let result = '';
    userList.forEach((user, index) => {
      if (userIdList.indexOf(user.id) !== -1) {
        if (index === 0) {
          result += user.name;
        } else {
          result += `, ${user.name}`;
        }
      }
    });

    return result;
  };

  const checkTagState = (idList: number[]) => {
    // 태그에 포함된 user 가 모두 idList 에 있으면 해당 태그의 selected = true
    const tempArr = arrayDeepCopy(tags);

    tempArr.forEach((tag: any) => {
      let result = true;
      tag.userList.forEach((user: any) => {
        if (idList.indexOf(user.id) < 0) {
          result = false;
        }
      });
      tag.selected = result;
    });

    setTags(tempArr);
  };

  const handlePreSelection = (row: any) => {
    return userIdList.indexOf(row.id) >= 0;
  }

  const onSelectedRowsChange = (e: any) => {
    const result: number[] = [];
    const selectedRows = e.selectedRows;
    let validation = false;

    selectedRows.forEach((row: any) => {
      result.push(row.id);
    });

    if (selectedRows.length > userIdList.length) {

      selectedRows.forEach((id: number) => {
        if (userIdList.indexOf(id) === -1) {
          validation = true;
        } else {
          result.push(id);
        }
      });

    } else if (userIdList.length > selectedRows.length) {

      userIdList.forEach((id: number) => {
        if (selectedRows.indexOf(id) === -1) {
          validation = true;
        } else {
          result.push(id);
        }
      })

    }

    if (validation) {
      setUserIdList(result);
    }
  }
  /*----------------------------------------
                  Event Handler
   ----------------------------------------*/
  const closeModal = () => {
    props.closeModal(false);
  };

  const save = () => {
    const result = getSelectedUserNames();
    props.setUserIdList(userIdList);
    props.setReceiverInput(result);
    props.setTags(tags);
    closeModal();
  };

  const handleClick = (tag: { value: string, count: number, selected: boolean }) => {
    const newTags = arrayDeepCopy(tags);
    const newUserIdList: number[] = [];
    userIdList.forEach((id: number) => {
      newUserIdList.push(id);
    });
    const newTag = newTags.find((newTag: { value: string, count: number, selected: boolean }) => newTag.value === tag.value)!;
    newTag.selected = !newTag?.selected;
    const selectStatus = newTag.selected;
    const usersInTag: number[] = [];

    // 태그에 포함된 유저목록
    userList.forEach((user: any) => {
      if (user.tags && user.tags.length > 0) {
        user.tags.split(',').forEach((label: string) => {
          if (label === tag.value) {
            usersInTag.push(user.id);
          }
        });
      }
    });

    // 추가되는 상태
    if (selectStatus) {
      usersInTag.forEach((id: number) => {
        if (newUserIdList.indexOf(id) < 0) {
          newUserIdList.push(id);
        }
      });
      setUserIdList(newUserIdList);
    }
    // 제거되는 상태
    else {
      usersInTag.forEach((id: number) => {
        if (newUserIdList.indexOf(id) >= 0) {
          delete newUserIdList[newUserIdList.indexOf(id)];
        }
      });
      setUserIdList(newUserIdList.filter(() => true));
    }

    setTags(newTags);
  }
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <div
      css={{
        width: '902px',
        height: '630',
        background: '#FFFFFF',
        borderRadius: '8px',
        padding: '20px',
      }}
    >
      {/* header */}
      <div>
        <div
          css={{
            height: '40px',
            display: 'inline-block',
            fontFamily: 'Noto Sans, sans-serif',
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '40px',
            letterSpacing: '0.15px',
            color: '#000000',
          }}
        >
          메일 받을 고객
        </div>
      </div>
      {/* Select Tag */}
      <div>
        <div
          css={{
            height: '20px',
            display: 'inline-block',
            fontFamily: 'Noto Sans, sans-serif',
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '15px',
            lineHeight: '40px',
            letterSpacing: '0.15px',
            color: '#000000',
          }}
        >
          태그 선택
        </div>
        <div
          css={{
            marginTop: '5px',
            marginBottom: '15px',
          }}>
          {
            tags && tags.length > 0 ? (
              tags.map((tag: { value: string, count: number, selected: boolean }, index: number) => {
                return <Chip
                  key={tag.value}
                  label={`${tag.value}(${tag.count})`}
                  sx={{
                    borderColor: tag.selected ? '' : '#0071FF',
                    color: tag.selected ? '#FFFFFF' : '#0071FF',
                    backgroundColor: tag.selected ? '#0071FF' : '',
                    marginLeft: index !== 0 ? '10px' : '',
                  }}
                  variant="outlined"
                  onClick={() => handleClick(tag)} />;
              })
            ) : ''
          }
        </div>
      </div>
      {/* Datatable contents */}
      <div
        css={{
          height: '500px',
          overflow: 'auto'
        }}
      >
        {
          userList && userList.length > 0 ? (
            <RDSDataTable
              data={userList}
              columns={columns}
              searchInput={'default'}
              searchFilterKeyNames={['clientCompany', 'name', 'email', 'tags']}
              customStyle={{
                width: '100%',
              }}
              selectableRows
              handlePreSelection={handlePreSelection}
              onSelectedRowsChange={onSelectedRowsChange}
              actionButton={<></>}
              onChangePaginationOption={(e) => console.log(e)}
            />
          ) : (<></>)
        }
      </div>
      {/* button area */}
      <div
        css={{
          marginTop: '20px'
        }}
      >
        <Button
          variant={'contained'}
          css={{
            float: 'right',
            marginLeft: '10px',
            width: '89px',
            height: '36px',
            borderRadius: '4px',
            backgroundColor: '#0071FF',
            fontFamily: 'Noto Sans, sans-serif',
            fontSize: '14px',
            fontWeight: '500',
            lineHeight: '26px',
            letterSpacing: '0.46px',
            fontStyle: 'normal',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#FFFFFF',
          }}
          onClick={save}
        >
          저장
        </Button>
        <Button
          variant={'outlined'}
          css={{
            float: 'right',
            marginLeft: '10px',
            width: '89px',
            height: '36px',
            borderRadius: '4px',
            fontFamily: 'Noto Sans, sans-serif',
            fontSize: '14px',
            fontWeight: '500',
            lineHeight: '26px',
            letterSpacing: '0.46px',
            fontStyle: 'normal',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#0071FF',
          }}
          onClick={() => props.closeModal(false)}
        >
          취소
        </Button>
      </div>
    </div>
  );
};

export default ReceiverModal;