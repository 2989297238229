/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { Button } from '@mui/material';
import { useLocation } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

const AddTagModal = (props: any) => {
  /*----------------------------------------
                     Data
   ----------------------------------------*/
  const location = useLocation();
  const projectId = location.search.split('=')[1];
  const [labels, setLabels] = useState<string[]>([]);
  const [initSelectedTags, setInitSelectedTags] = useState<string[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [addTag, setAddTag] = useState<string>('');
  /*----------------------------------------
                  Life Cycle
   ----------------------------------------*/
  useEffect(() => {
    initVars();
    if (props.selectedTags.length > 0) {
      setSelectedTags(props.selectedTags.split(','));
      setInitSelectedTags(props.selectedTags.split(','));
    }
  }, []);
  /*----------------------------------------
                 Business Logic
   ----------------------------------------*/
  const initVars = () => {
    const tags = props.tags;
    const tempArr: string[] = [];
    if (tags && tags.length > 0) {
      tags.forEach((tag: { value: string; count: number }) => {
        tempArr.push(tag.value);
      });
    }
    setLabels(tempArr);
  };

  const validateTag = (deleteLabel: string, data: any) => {
    let result = true;
    data.forEach((userInfo: any) => {
      if (userInfo.tags && userInfo.tags.length > 0) {
        userInfo.tags.forEach((tag: string) => {
          if (tag === deleteLabel) result = false;
        });
      }
    });
    return result;
  };
  /*----------------------------------------
                  Event Handler
   ----------------------------------------*/
  const handleClick = (label: string) => {
    // selectedTags 에 label 이 있으면 제거, 없으면 추가
    if (selectedTags.indexOf(label) >= 0) {
      const tempArr: string[] = selectedTags.filter((tag: string) => {
        return label !== tag;
      });
      setSelectedTags(tempArr);
    } else {
      const tempArr: string[] = selectedTags.concat([]);
      tempArr.push(label);
      setSelectedTags(tempArr);
    }
  };

  const handleDelete = (deleteLabel: string) => {
    const data = props.userTableData;
    if (validateTag(deleteLabel, data)) {
      props.httpClient.deleteTag(projectId, deleteLabel).then((result: number) => {
        if (result === 200) {
          const tempArr: string[] = [];
          labels.forEach((label) => {
            if (label !== deleteLabel) {
              tempArr.push(label);
            }
          });
          setLabels(tempArr);
          props.initVars();
        }
      });
    } else {
      console.warn('해당 태그를 사용중인 유저가 있습니다.');
    }
  };

  const save = async () => {
    const addTags: string[] = [];
    const deletedTags: string[] = [];

    selectedTags.forEach((tag: string) => {
      if (initSelectedTags.indexOf(tag) < 0) {
        addTags.push(tag);
      }
    });

    initSelectedTags.forEach((tag: string) => {
      if (selectedTags.indexOf(tag) < 0) {
        deletedTags.push(tag);
      }
    });

    try {
      if (deletedTags.length > 0) {
        deletedTags.forEach((deletedTag: string, index: number) => {
          props.httpClient.deleteUserTag(projectId, props.selectedUserId, deletedTag).then((result: number) => {
            if (result === 200 && index === deletedTags.length - 1 && addTags.length === 0) {
              props.saved();
              props.setOpenAddTagModal(false);
            }
          });
        });
      }

      if (addTags.length > 0) {
        props.httpClient.postUserTag(projectId, props.selectedUserId, addTags).then((result: number) => {
          if (result === 200) {
            props.saved();
            props.setOpenAddTagModal(false);
          }
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onAddTagButtonClicked = () => {
    if (labels.indexOf(addTag) >= 0) {
      console.warn('중복되는 태그가 있음');
    } else if (addTag.length === 0) {
      console.warn('태그를 입력하세요.');
    } else {
      const tempArr: string[] = labels.concat([]);
      if (addTag && addTag.length > 0) {
        props.httpClient.postTag(projectId, addTag).then((result: number) => {
          if (result === 200) {
            tempArr.push(addTag);
            setLabels(tempArr);
            setAddTag('');
            props.initVars();
          } else {
            console.warn('태그 추가 API 요청 실패');
          }
        });
      }
    }
  };
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <div
      css={{
        backgroundColor: '#FFFFFF',
        width: '500px',
        height: '400px',
        padding: '20px',
        borderRadius: '16px',
      }}
    >
      <div>태그 수정</div>
      <div
        css={{
          marginTop: '20px',
          height: 'calc(100% - 105px)',
        }}
      >
        <div>기존 태그 선택</div>
        <div
          css={{
            marginTop: '10px',
          }}
        >
          <div
            css={{
              marginBottom: '10px',
            }}
          >
            <TextField
              sx={{
                width: '120px',
                height: '40px',
              }}
              size={'small'}
              label="태그 추가"
              value={addTag}
              onChange={(e) => setAddTag(e.target.value)}
            />
            <AddCircleOutlineOutlinedIcon
              sx={{ color: '#00000061', margin: '8px 0 0 10px', cursor: 'pointer' }}
              onClick={onAddTagButtonClicked}
            />
          </div>
          <Stack direction="row" sx={{ display: 'block' }}>
            {labels.map((label) => {
              return (
                <Chip
                  key={label}
                  label={label}
                  sx={{
                    marginTop: '5px',
                    marginRight: '5px',
                    backgroundColor: selectedTags.indexOf(label) >= 0 ? '#0071FF' : '',
                  }}
                  variant="outlined"
                  onClick={() => handleClick(label)}
                  onDelete={() => handleDelete(label)}
                />
              );
            })}
          </Stack>
        </div>
        <div
          css={{
            marginTop: '20px',
          }}
        >
          결과 미리보기
        </div>
        <div
          css={{
            marginTop: '10px',
          }}
        >
          {selectedTags.length > 0 &&
            selectedTags.map((label, index) => {
              return (
                <Chip
                  key={label}
                  label={label}
                  variant="outlined"
                  sx={{
                    marginLeft: index !== 0 ? '10px' : '',
                  }}
                />
              );
            })}
        </div>
      </div>
      {/* button area */}
      <div
        css={{
          marginTop: '20px',
          overflow: 'auto',
        }}
      >
        <Button
          variant={'contained'}
          css={{
            float: 'right',
            marginLeft: '10px',
            width: '89px',
            height: '36px',
            borderRadius: '4px',
            backgroundColor: '#0071FF',
            fontFamily: 'Noto Sans, sans-serif',
            fontSize: '14px',
            fontWeight: '500',
            lineHeight: '26px',
            letterSpacing: '0.46px',
            fontStyle: 'normal',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#FFFFFF',
          }}
          onClick={save}
        >
          저장
        </Button>
        <Button
          variant={'outlined'}
          css={{
            float: 'right',
            marginLeft: '10px',
            width: '89px',
            height: '36px',
            borderRadius: '4px',
            fontFamily: 'Noto Sans, sans-serif',
            fontSize: '14px',
            fontWeight: '500',
            lineHeight: '26px',
            letterSpacing: '0.46px',
            fontStyle: 'normal',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#0071FF',
          }}
          onClick={() => props.setOpenAddTagModal(false)}
        >
          취소
        </Button>
      </div>
    </div>
  );
};

export default AddTagModal;
