import axios, { AxiosResponse } from 'axios';
import { Auth } from 'aws-amplify';

const config = { baseURL: process.env.REACT_APP_API_PATH };
export const PROD_SERVER_DOMAIN = 'https://dzg6u2wfrl.execute-api.ap-northeast-2.amazonaws.com/prod';

const prodconfig = { baseURL: PROD_SERVER_DOMAIN };
export const AxiosInstance = axios.create(config);
export const prodAxiosInstance = axios.create(prodconfig);

AxiosInstance.prototype.requestRetriedCount = 0;
prodAxiosInstance.prototype.requestRetriedCount = 0;

const setAuthorization = (token: string) => {
  console.log('setting header authorization');
  AxiosInstance.defaults.headers.common = AxiosInstance.defaults.headers.common || {};
  AxiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  prodAxiosInstance.defaults.headers.common = prodAxiosInstance.defaults.headers.common || {};
  prodAxiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export async function getAuthorization() {
  const authorization = AxiosInstance.defaults.headers.common.Authorization;
  if (!authorization) {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();
    if (token) {
      setAuthorization(token);
    }
  }
}

// AxiosInstance.interceptors.request.use(async (config) => {
//   await getAuthorization();
//   return config;
// });

const Responseinterceptor = (res: AxiosResponse, axiosInstance: any) => {
  console.log('request fullfiled (axios interceptor)');
  axiosInstance.prototype.requestRetriedCount = 0;
  return res;
};
const ErrorReponseInterceptor = async (error: any, axiosInstance: any) => {
  console.log(`retrying request ${axiosInstance.prototype.requestRetriedCount} times`);
  if (axiosInstance.prototype.requestRetriedCount === 3) {
    return;
  }
  axiosInstance.prototype.requestRetriedCount++;
  const { url, params, method } = error.config;
  console.log(error.config);
  if (error.response.status === 401) {
    console.log('401 error, assume as id token staled and get another idtoken with refresh token');
    try {
      // Auth.currentSession 하면 refresh 자동 (호출 하지않아도 자동으로 되지만 끊겼다가 실행됨)
      const currentSession = await Auth.currentSession();
      const idToken = currentSession.getIdToken().getJwtToken();
      setAuthorization(idToken);
      console.log(url, method, params);
      alert('session이 만료되어 재요청중입니다 잠시만 기다려주세요');
      const res = await axios({ method, url, params });
      return res;
    } catch (error: any) {
      console.log(error);
      console.log('failed to get token by refresh token');
      window.location.pathname = '/';
      throw Error('failed to get token');
    }
  } else {
    return Promise.reject(error);
  }
};

AxiosInstance.interceptors.response.use(
  (res) => {
    return Responseinterceptor(res, AxiosInstance);
  },
  async (err) => {
    return await ErrorReponseInterceptor(err, AxiosInstance);
  },
);

prodAxiosInstance.interceptors.response.use(
  (res) => {
    return Responseinterceptor(res, prodAxiosInstance);
  },
  async (err) => {
    return await ErrorReponseInterceptor(err, prodAxiosInstance);
  },
);
