import React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';

type CompanyListComboboxProps = {
  width: number;
  defaultValue: string;
  companyList: string[];
  onSelectionChanged: Function;
}

const CompanyListCombobox = (props: CompanyListComboboxProps) => {
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return props.companyList && props.companyList.length > 0 ? (
    <Box sx={{ width: props.width }}>
      <FormControl fullWidth>
        <InputLabel variant="standard">
          Company
        </InputLabel>
        <NativeSelect
          defaultValue={props.defaultValue}
          onChange={(e) => props.onSelectionChanged(e.target.value)}
        >
          {
            props.companyList && props.companyList.length > 0 ? (
              props.companyList.map((company: string) => {
                return (
                  <option key={company} value={company}>{company}</option>
                );
              })
            ) : ''
          }
        </NativeSelect>
      </FormControl>
    </Box>
  ) : (<></>);
};

export default CompanyListCombobox;