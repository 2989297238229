export const PLICAR_ZERO_URL = `${process.env.REACT_APP_PLICAR_ZERO_PROTOCOL}://${process.env.REACT_APP_PLICAR_ZERO_DOMAIN}${
  process.env.REACT_APP_PLICAR_ZERO_PORT == '3000' ? ':' + process.env.REACT_APP_PLICAR_ZERO_PORT : ''
}`;
export const PLICAR_ZERO_ARGATEWAY_URL = process.env.REACT_APP_PLICAR_ZERO_ARGATEWAY_URL;
export const STATIC_FILES_URL = `https://${process.env.REACT_APP_ASSET_DOMAIN}`;

export const plicarButtonImgUrl = {
  primary: `${STATIC_FILES_URL}/images/button/PlicarZeroARProductViewButtonBasic.png`,
  primaryEn: `${STATIC_FILES_URL}/images/button/PlicarZeroARProductViewButtonBasicEn.png`,
  secondary: `${STATIC_FILES_URL}/images/button/PlicarZeroARProductViewButtonBasic2.png`,
  secondaryEn: `${STATIC_FILES_URL}/images/button/PlicarZeroARProductViewButtonBasicEn2.png`,
  tertiary: `${STATIC_FILES_URL}/images/button/PlicarZeroARProductViewButtonBasic3.png`,
  tertiaryEn: `${STATIC_FILES_URL}/images/button/PlicarZeroARProductViewButtonBasicEn3.png`,
};
