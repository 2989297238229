import React, { useEffect, useState } from 'react';
import { getEmailDetail, getEmailEvent, getEmailList } from '../../../../../api/3dpresso/email';
import { Stage } from '../../../../../types';
import TanstackTable from '../../../../../components/TanstackTable';
import { List, ListItem } from '@mui/material';
import { ModalMaker, RDSButton } from '@reconlabs/reconlabs-fe-components';
import { ColumnDef } from '@tanstack/react-table';
import { User } from '../../../../../api/3dpresso/user';
import UserListTable from '../Bean/User/UserListTable';

const EmailHistory = ({
  stage,
  applyEmail,
  onClickCancel,
}: {
  stage: Stage;
  applyEmail: Function;
  onClickCancel: Function;
}) => {
  const [emailSentList, setEmailSentList] = useState<any[]>([{ id: '', title: '', content: '' }]);
  const [selectedEmail, setSelectedEmail] = useState('');
  const [onClickSearchAsUser, setOnClickSearchAsUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  useEffect(() => {
    (async function () {
      const emails = await getEmailList(stage);
      console.log(emails);

      setEmailSentList(emails);
    })();
  }, [stage]);

  const columns = React.useMemo<ColumnDef<User>[]>(
    () => [
      {
        header: 'Info',
        footer: (props) => props.column.id,
        columns: [
          {
            accessorKey: 'user_email',
            cell: (info) => info.getValue(),
            footer: (props) => props.column.id,
          },
          {
            accessorKey: 'user_name',
            cell: (info) => info.getValue(),
            // header: () => <span>user_name/span>,
            footer: (props) => props.column.id,
          },
          {
            header: '이벤트 확인',
            accessorFn: (row) => row,
            cell: (info) => (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <RDSButton
                  type="solid"
                  size="xsmall"
                  onClick={() => {
                    setSelectedUser(info.getValue());
                  }}
                >
                  이메일 이벤트 확인
                </RDSButton>
              </div>
            ),
            footer: (props) => props.column.id,
          },
        ],
      },
    ],
    [],
  );

  return (
    <div style={{ width: '700px' }}>
      <ModalMaker isVisible={selectedEmail ? true : false} setIsVisible={setSelectedEmail}>
        <EmailHistoryInfo
          stage={stage}
          emailId={selectedEmail}
          applyEmail={applyEmail}
          onClickCancel={() => {
            setSelectedEmail('');
          }}
        />
      </ModalMaker>
      <ModalMaker isVisible={selectedUser ? true : false} setIsVisible={setSelectedEmail}>
        <UserEmailEvent
          stage={stage}
          user={selectedUser}
          onClickCancel={() => {
            setSelectedUser(null);
          }}
        />
      </ModalMaker>
      <div style={{ marginBottom: '10px' }}>EMAIL HISTORY</div>
      <RDSButton
        size="small"
        onClick={() => {
          setOnClickSearchAsUser(!onClickSearchAsUser);
        }}
      >
        {onClickSearchAsUser ? `이메일별 이벤트 확인` : `유저별 이메일 이벤트 확인`}
      </RDSButton>
      <br />
      <div style={{ height: '360px', overflow: 'scroll', border: '1px solid' }}>
        {onClickSearchAsUser ? (
          <UserListTable stage={stage} columns={columns} />
        ) : (
          <div>
            하단 이메일 리스트를 클릭한 후 '이메일 템플릿 적용'을 클릭하시면 해당 템플릿을 가져올 수 있어요!
            <List>
              {emailSentList.map((value) => (
                <ListItem
                  onClick={() => {
                    setSelectedEmail(value.id);
                  }}
                  sx={{ ':hover': { backgroundColor: 'lightblue', cursor: 'pointer' } }}
                >
                  {value.send_date}
                  <br /> {value.title}
                </ListItem>
              ))}
            </List>
          </div>
        )}
      </div>
      *상하 스크롤 가능
      <p />
      <div style={{ display: 'flex', justifyContent: 'right' }}>
        <RDSButton size="small" onClick={onClickCancel}>
          취소
        </RDSButton>
      </div>
    </div>
  );
};

export default EmailHistory;

const EmailHistoryInfo = ({
  stage,
  emailId,
  applyEmail,
  onClickCancel,
}: {
  stage: Stage;
  emailId: string;
  applyEmail: Function;
  onClickCancel: Function;
}) => {
  const defaultEmailData = {
    title: '',
    send_date: '',
    recipient: '',
    event: '',
  };
  const [emailData, setEmailData] = useState<any>(defaultEmailData);

  useEffect(() => {
    if (emailId == '') return;
    (async function () {
      const data = await getEmailDetail(emailId, stage);
      setEmailData(data);
      console.log(data);
    })();
  }, []);

  return (
    <div style={{ width: '400px', wordBreak: 'break-all' }}>
      <div>
        <div>제목</div>
        {emailData.title}
      </div>
      <div>
        <div>보낸 날짜</div>
        {emailData.send_date}
      </div>
      <div>
        <div>수신자</div>
        {JSON.stringify(emailData.recipient)}
      </div>
      <div>
        <div>수신, 확인 및 클릭 여부</div>
        {emailData.event && (
          <div>
            송신: {emailData.event.Send}
            <br />
            도달: {emailData.event.Delivery}
            <br />
            열기: {emailData.event.Open}
            <br />
            클릭: {emailData.event.Click}
          </div>
        )}
      </div>
      <div style={{ display: 'flex', height: '30px', gap: '10px', justifyContent: 'right' }}>
        <RDSButton size="xsmall" onClick={onClickCancel}>
          취소
        </RDSButton>
        <RDSButton
          size="xsmall"
          onClick={() => {
            console.log(emailData.design);
            applyEmail(emailData.design);
            onClickCancel();
          }}
        >
          해당 이메일 템플릿 적용
        </RDSButton>
      </div>
    </div>
  );
};

const UserEmailEvent = ({ stage, user, onClickCancel }: { stage: Stage; user: any; onClickCancel: Function }) => {
  const [emailEvents, setEmailEvents] = useState<any>([]);
  useEffect(() => {
    (async function () {
      const data = await getEmailEvent(user.user_email, stage);
      console.log(data);
      setEmailEvents(data);
    })();
  }, []);

  return (
    <div>
      {emailEvents.length == 0 ? (
        <div>이메일 이벤트가 발생되지 않았습니다.</div>
      ) : (
        <List>
          {emailEvents.map((value: any) => (
            <ListItem>
              {value.title}
              <br /> {value.send_date}
              <br /> {JSON.stringify(value.event)}
            </ListItem>
          ))}
        </List>
      )}
      <RDSButton size="xsmall" onClick={onClickCancel}>
        취소
      </RDSButton>
    </div>
  );
};
