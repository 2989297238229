/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import Select from 'react-select';

type SelectBoxProps = {
  inspectionType: { value: string; label: string; } | undefined;
  setInspectionType: Function
}

const InspectionTypeSelectBox = (props: SelectBoxProps) => {
  useEffect(() => {
    // MUI Select box css 수정
    document.querySelector('.css-1s2u09g-control')?.setAttribute('style', 'border-color: #00000099');
    document.querySelector('.css-1okebmr-indicatorSeparator')?.setAttribute('style', 'margin: 0; background: #00000099');
    document.querySelector('.css-tlfecz-indicatorContainer')?.setAttribute('style', 'color: #00000099');
    /* Select box 커서 제거 : chrome 에서는 readonly 속성을 줌으로써 해결됨
       input 태그의 id 는 지속적(탭 변경시)으로 변하므로 부모 div 를 css로 select */
    document.querySelector('.css-6j8wv5-Input')?.children[0]?.setAttribute('readonly', 'true');
  }, []);
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <Select
      css={{ width: '141px' }}
      defaultValue={{ value: 'emergency', label: '긴급 점검' }}
      options={[
        { value: 'emergency', label: '긴급 점검' },
        { value: 'temp', label: '임시 점검' },
        { value: 'notice', label: '공지 사항' },
        { value: 'periodic', label: '정기 점검' },
      ]}
      value={props.inspectionType}
      onChange={(e) => props.setInspectionType(e!)}
    />
  );
};

export default InspectionTypeSelectBox;