import React, { useState } from 'react';
import { RDSDropdown, RDSOption } from '@reconlabs/reconlabs-fe-components';
import { Tab, Tabs } from '@mui/material';
import Bean, { TabPanel } from './Bean/Bean';
import Showcase from './Showcase';
import EmailService from './email/EmailService';
import PopupService from './popup/PopupService';
import { Stage } from '../../../../types';
import { useSearchParams } from 'react-router-dom';

const Main3Dpresso = () => {
  const [params, setParams] = useSearchParams();
  const [stage, setStage] = useState<Stage>('prod');
  let initialTabValue = 0;
  switch (params.get('tab')) {
    case 'bean':
      initialTabValue = 0;
      break;
    case 'showcase':
      initialTabValue = 1;
      break;
    case 'email':
      initialTabValue = 2;
      break;
    case 'popup':
      initialTabValue = 3;
      break;
  }
  const [tabValue, setTabValue] = useState(initialTabValue);

  return (
    <div style={{ margin: '10px' }}>
      <RDSDropdown
        size="small"
        value={stage}
        onChange={(value: Stage) => {
          setStage(value);
        }}
      >
        <RDSOption value="dev">dev</RDSOption>
        <RDSOption value="stag">stag</RDSOption>
        <RDSOption value="qa">qa</RDSOption>
        <RDSOption value="prod">prod</RDSOption>
      </RDSDropdown>
      <Tabs
        value={tabValue}
        onChange={(event: React.SyntheticEvent, newTabValue: number) => {
          params.set('tab', event.currentTarget.id);
          setParams(params);
          setTabValue(newTabValue);
        }}
      >
        <Tab label="Bean" id={'bean'} />
        <Tab label="쇼케이스" id={'showcase'} />
        <Tab label="이메일" id={'email'} />
        <Tab label="팝업" id={'popup'} />
        {/* <Tab label="점검" /> */}
      </Tabs>
      <TabPanel value={tabValue} index={0}>
        <Bean stage={stage}></Bean>
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <Showcase stage={stage}></Showcase>
      </TabPanel>
      <TabPanel value={tabValue} index={2}>
        <EmailService stage={stage} />
      </TabPanel>
      <TabPanel value={tabValue} index={3}>
        <PopupService stage={stage} />
      </TabPanel>
    </div>
  );
};

export default Main3Dpresso;
