import React, { createContext, useState } from 'react';

export const UploadProgressContext = createContext<{
  uploadFiles: { user: any; file: File; progress: number }[];
  setUploadFiles: Function;
}>({ uploadFiles: [], setUploadFiles: () => {} });

const UploadProgressContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [uploadFiles, setUploadFiles] = useState([]);
  return (
    <UploadProgressContext.Provider value={{ uploadFiles, setUploadFiles }}>{children}</UploadProgressContext.Provider>
  );
};

export default UploadProgressContextProvider;
