import React, { useEffect, useState } from 'react';
import { EmailGroup, EmailSelectParam, deleteEmailGroup, getEmailGroupList } from '../../../../../api/3dpresso/email';
import { Stage } from '../../../../../types';
import CSVSelector from '../../../../../components/CSVselector';
import { Button, Card, CardContent, Chip, TextField } from '@mui/material';
import { User } from '../../../../../api/3dpresso/user';
import { ModalMaker } from '@reconlabs/reconlabs-fe-components';
import GroupUser from './GroupUser';
import SelectUserModal from './SelectUserModal';

export const defaultSelectedUserEmail: EmailSelectParam = {
  select_all: false,
  selected_users: [],
  excluded_users: [],
  filter: {},
};

const EmailGroupManagement = ({ stage, setSelectedGroup }: { stage: Stage; setSelectedGroup: Function }) => {
  const [emailGroupList, setEmailGroupList] = useState<EmailGroup[]>([]);
  const [userEmailList, setUserEmailList] = useState<EmailSelectParam>(defaultSelectedUserEmail);
  const [openSelectUserModal, setOpenSelectUserModal] = useState(false);
  const [editGroup, setEditGroup] = useState<EmailGroup | undefined>(undefined);

  useEffect(() => {
    (async function () {
      await refreshEmailGroupList();
    })();
  }, []);
  
  useEffect(() => {
    setOpenSelectUserModal(false);
  }, [userEmailList]);

  const refreshEmailGroupList = async () => {
    const data = await getEmailGroupList({ stage });
    console.log(data);
    setEmailGroupList(data.groups);
  };

  return (
    <div>
      <ModalMaker
        isVisible={userEmailList.selected_users.length > 0 || userEmailList.select_all === true}
        setIsVisible={(bool: boolean) => {
          bool == false && setUserEmailList(defaultSelectedUserEmail);
        }}
      >
        <GroupUser
          stage={stage}
          userEmailList={userEmailList}
          selectedGroup={editGroup}
          onClickCancel={() => {
            setUserEmailList(defaultSelectedUserEmail);
            setEditGroup(undefined);
          }}
          onClickSave={() => {
            refreshEmailGroupList();
            setUserEmailList(defaultSelectedUserEmail);
          }}
        />
      </ModalMaker>
      <ModalMaker isVisible={openSelectUserModal} setIsVisible={setOpenSelectUserModal}>
        <SelectUserModal setSelectedEmail={setUserEmailList} stage={stage} />
      </ModalMaker>
      <div style={{ padding: '20px', width: '700px', maxHeight: '700px', overflow: 'scroll' }}>
        <h2>Groups</h2>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '20px',
            marginBottom: '20px',
          }}
        >
          <h6>ADD Group</h6>
          <Button
            variant={'outlined'}
            onClick={() => {
              setOpenSelectUserModal(true);
            }}
          >
            From User List
          </Button>
          <Button variant={'outlined'}>
            CSV import
            <CSVSelector
              onChange={(data) => {
                const user_email_arr: string[] = [];
                let user_email_idx: number | undefined;
                for (const row of data) {
                  // find user_email column from the first row
                  if (user_email_idx == undefined) {
                    row.forEach((value, idx) => {
                      if (value == 'user_email' || value == 'email') user_email_idx = idx;
                    });
                    continue;
                  }
                  // user_email_arr.push({ user_email: row[user_email_idx] });
                  row[user_email_idx] && user_email_arr.push(row[user_email_idx]);
                  // if cannot find user_email continue to next row
                }
                console.log(user_email_arr);
                setUserEmailList({ select_all: false, selected_users: user_email_arr, excluded_users: [], filter: {} });
              }}
            />
          </Button>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', maxHeight: '500px', overflow: 'scroll' }}>
          {emailGroupList.map((value, idx) => (
            <div key={idx}>
              <Card
                sx={{
                  width: '100%',
                  cursor: 'pointer',
                  backgroundColor: '#eef7fa',
                  ':hover': { backgroundColor: '#d1f4ff' },
                }}
                onClick={() => {
                  setSelectedGroup(value);
                }}
              >
                <CardContent>
                  <div>{value.group_name}</div>
                  <div>
                    {' '}
                    &#183; {value.select_all ? '전체선택' : '일부선택'} <br />
                    &#183; {value.select_all ? '추가' : '선택'}된 유저 : {value.selected_users.length} 명<br />
                    &#183; 필터링 : {JSON.stringify(value.filter)}
                    <br />
                    &#183; 제외된 유저 : {value.excluded_users.length}명
                  </div>
                </CardContent>
              </Card>

              <Button
                variant={'outlined'}
                onClick={(e) => {
                  e.preventDefault();
                  setEditGroup(value);
                  setUserEmailList(value);
                  // setUserEmailArr(value.emails.map((v) => ({ user_email: v })));
                }}
              >
                Edit
              </Button>
              <Button
                variant={'outlined'}
                onClick={async (e) => {
                  e.preventDefault();
                  await deleteEmailGroup({ stage, group_id: value.group_id });
                  await refreshEmailGroupList();
                }}
              >
                Delete
              </Button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EmailGroupManagement;
