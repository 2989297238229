import { BrowserRouter, Route, Routes } from 'react-router-dom';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { Authenticator } from '@aws-amplify/ui-react';
import { Amplify, Auth } from 'aws-amplify';
import { QueryClient, QueryClientProvider } from 'react-query';
import HttpClient from './lib/http-client';
import { awsconfig } from './constants/awsConfig';
import UploadProgressContextProvider from './context/UploadProgressContextProvider';
import Layout from './pages/admin/Layout';
import Dashboard from './pages/admin/dashboard/Dashboard';
import Payment from './pages/admin/dashboard/payment/Payment';
import ServiceDetail from './pages/admin/dashboard/plicarzero/ServiceDetail';
import Main3Dpresso from './pages/admin/dashboard/3dpresso/Main3Dpresso';
import Signedin from './pages/Signedin';
import Login from './pages/Login';

Amplify.configure(awsconfig);
Auth.configure(awsconfig);

const httpClient = new HttpClient();
const queryClient = new QueryClient();
const App = () => {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <Authenticator.Provider>
          <UploadProgressContextProvider>
            <Routes>
              <Route path="/admin" element={<Layout httpClient={httpClient}></Layout>}>
                <Route path="/admin/dashboard" element={<Dashboard httpClient={httpClient}></Dashboard>}></Route>
                <Route path="/admin/dashboard/payment" element={<Payment httpClient={httpClient}></Payment>}></Route>
                <Route
                  path="/admin/dashboard/detail"
                  element={<ServiceDetail httpClient={httpClient}></ServiceDetail>}
                ></Route>
                <Route path="/admin/dashboard/3dpresso" element={<Main3Dpresso />}></Route>
              </Route>
              <Route path="/signedin" element={<Signedin httpClient={httpClient}></Signedin>}></Route>
              <Route path="*" element={<Login httpClient={httpClient}></Login>}></Route>
              <Route path="/" element={<Login httpClient={httpClient}></Login>}></Route>
            </Routes>
          </UploadProgressContextProvider>
        </Authenticator.Provider>
      </QueryClientProvider>
    </BrowserRouter>
  );
};

export default App;
