/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import SearchInput from './SearchInput';
import { ScheduledInspectionItem, PostEmailItem, PopupItem } from '../types';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

type ServiceHistoryProps = {
	type: 'inspection' | 'email' | 'popup';
	inspectionItems?: Array<ScheduledInspectionItem>;
	emailItems?: Array<PostEmailItem>;
	popupItems?: Array<PopupItem>;
	templateApply: Function;
	search: Function;
}

const ServiceHistory = (props: ServiceHistoryProps) => {
	/*----------------------------------------
										 Data
	 ----------------------------------------*/
	const [searchWord, setSearchWord] = useState<string>('');
	/*----------------------------------------
								 Business Logic
	 ----------------------------------------*/
	const getTitle = () => {
		return (
			props.type === 'inspection' ? '점검' :
				props.type === 'email' ? '메일' :
					props.type === 'popup' ? '팝업' : ''
		);
	};

	const getDateTitleType = () => {
		return (
			props.type === 'inspection' ? '점검' :
				props.type === 'email' ? '발송' :
					props.type === 'popup' ? '게시' : ''
		);
	};
	/*----------------------------------------
									Event Handler
	 ----------------------------------------*/
	const historySearch = () => {
		props.search(searchWord);
	};

	const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		if (e.nativeEvent.key === 'Enter') {
			historySearch();
		}
	};
	/*----------------------------------------
								Default Template
	 ----------------------------------------*/
	return (
		<div
			css={{
				margin: '15px',
				height: 'calc(100% - 30px)',
			}}
		>
			<div css={{
				height: '45px',
			}}
			>
				<div
					css={{
						display: 'inline-block',
						height: '45px',
						fontFamily: 'Noto Sans, sans-serif',
						fontStyle: 'normal',
						fontWeight: '500',
						fontSize: '20px',
						lineHeight: '45px',
						letterSpacing: '0.15px',
						color: '#000000',
					}}
				>
					{getTitle()} 히스토리
				</div>
				<SearchInput searchWord={searchWord} setSearchWord={setSearchWord} handleKeyUp={handleKeyUp} historySearch={historySearch} />
			</div>
			<div
				css={{
					marginTop: '24px',
					height: 'calc(100% - 45px)',
				}}
			>
				<div
					css={{
						height: '22px',
						fontFamily: 'Noto Sans, sans-serif',
						fontSize: '14px',
						fontWeight: '500',
						lineHeight: '22px',
						letterSpacing: '0.10px',
						textAlign: 'center',
					}}
				>
					<div
						css={{
							display: 'inline-block',
							width: '100px',
						}}
					>
						{getDateTitleType()} 날짜
					</div>
					<div
						css={{
							display: 'inline-block',
							width: '100px',
							marginLeft: '20px',
						}}
					>
						유형
					</div>
					<div
						css={{
							display: 'inline-block',
							width: '100px',
							marginLeft: '20px',
						}}
					>
						양식 적용
					</div>
				</div>
				{(props.inspectionItems && props.inspectionItems.length > 0) ? (
					props.inspectionItems.map(item => {
						return (
							<div
								css={{
									height: '24px',
									marginTop: '15px',
									fontFamily: 'Noto Sans, sans-serif',
									fontSize: '14px',
									fontWeight: '500',
									lineHeight: '22px',
									letterSpacing: '0.10px',
									textAlign: 'center',
									color: '#00000099',
								}}
								key={item.inspectionId}
							>
								<div
									css={{
										display: 'inline-block',
										width: '100px',
									}}
								>
									{item.inspectionStartTime.substring(0, item.inspectionStartTime.length - 6)}
								</div>
								<div
									css={{
										display: 'inline-block',
										width: '100px',
										marginLeft: '20px',
										textDecorationLine: 'underline',
									}}
								>
									{item.typeLabel}
								</div>
								<div
									css={{
										display: 'inline-block',
										width: '100px',
										marginLeft: '20px',
									}}
								>
									<ContentCopyIcon sx={{ color: '#00000061', cursor: 'pointer' }} onClick={() => props.templateApply(item)} />
								</div>
							</div>
						);
					})
				) : props.popupItems && props.popupItems.length > 0 ? (
					props.popupItems.map(item => {
						return (
							<div
								css={{
									height: '24px',
									marginTop: '15px',
									fontFamily: 'Noto Sans, sans-serif',
									fontSize: '14px',
									fontWeight: '500',
									lineHeight: '22px',
									letterSpacing: '0.10px',
									textAlign: 'center',
									color: '#00000099',
								}}
								key={item.popupId}
							>
								<div
									css={{
										display: 'inline-block',
										width: '100px',
									}}
								>
									{item.createDate.substring(0, item.createDate.length - 6)}
								</div>
								<div
									css={{
										display: 'inline-block',
										width: '100px',
										marginLeft: '20px',
										textDecorationLine: 'underline',
									}}
								>
									{item.typeLabel}
								</div>
								<div
									css={{
										display: 'inline-block',
										width: '100px',
										marginLeft: '20px',
									}}
								>
									<ContentCopyIcon sx={{ color: '#00000061', cursor: 'pointer' }} onClick={() => props.templateApply(item)} />
								</div>
							</div>
						);
					})
				) : props.emailItems && props.emailItems.length > 0 ? (
					props.emailItems.map(item => {
						return (
							<div
								css={{
									height: '24px',
									marginTop: '15px',
									fontFamily: 'Noto Sans, sans-serif',
									fontSize: '14px',
									fontWeight: '500',
									lineHeight: '22px',
									letterSpacing: '0.10px',
									textAlign: 'center',
									color: '#00000099',
								}}
								key={item.emailId}
							>
								<div
									css={{
										display: 'inline-block',
										width: '100px',
									}}
								>
									{item.postEmailTime.substring(0, item.postEmailTime.length - 9)}
								</div>
								<div
									css={{
										display: 'inline-block',
										width: '100px',
										marginLeft: '20px',
										textDecorationLine: 'underline',
									}}
								>
									{item.typeLabel}
								</div>
								<div
									css={{
										display: 'inline-block',
										width: '100px',
										marginLeft: '20px',
									}}
								>
									<ContentCopyIcon sx={{ color: '#00000061', cursor: 'pointer' }} onClick={() => props.templateApply(item)} />
								</div>
							</div>
						);
					})
				) : ''}
			</div>
		</div>
	);
};

export default ServiceHistory;