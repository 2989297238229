/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import '@aws-amplify/ui-react/styles.css';
import './RDSAuthenticator.css';
// Amplify
import { Amplify, I18n } from 'aws-amplify';

// Amplify UI
import { Authenticator } from '@aws-amplify/ui-react';
import { translations } from '@aws-amplify/ui-react';

/**
 * 언어 텍스트 설정
 * https://docs.amplify.aws/lib/utilities/i18n/q/platform/js/
 * https://ui.docs.amplify.aws/react/connected-components/authenticator/customization#labels--text
 *
 * AWS Amplify Configure 관련
 * https://docs.amplify.aws/lib/auth/start/q/platform/js/#configure-your-application
 *
 * Override Function Calls (회원가입, 로그인 로직 구체적으로 덮어쓰기 위함)
 * https://ui.docs.amplify.aws/react/connected-components/authenticator/customization#override-function-calls
 */
export type AWSAmplifyConfigure = {
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId?: string; //'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

    // REQUIRED - Amazon Cognito Region
    region: string; //'XX-XXXX-X',

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    identityPoolRegion?: string; //'XX-XXXX-X',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId?: string; //'XX-XXXX-X_abcd1234',

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId?: string; //'a1b2c3d4e5f6g7h8i9j0k1l2m3',

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn?: boolean;

    // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
    // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
    signUpVerificationMethod?: 'code' | 'link'; // 'code' | 'link'

    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    cookieStorage?: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain: string; //'.yourdomain.com',
      // OPTIONAL - Cookie path
      path?: string; // '/',
      // OPTIONAL - Cookie expiration in days
      expires?: number; //365,
      // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
      sameSite?: 'strict' | 'lax';
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      secure?: boolean; // true
    };

    // OPTIONAL - customized storage object
    storage?: any; //'MyStorage',

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType?: 'USER_PASSWORD_AUTH';

    // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    clientMetadata?: { myCustomKey: 'myCustomValue' };

    // OPTIONAL - Hosted UI configuration
    oauth?: {
      domain: string; // 'your_cognito_domain',
      scope: string[]; // ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: string; // 'http://localhost:3000/',
      redirectSignOut: string; // 'http://localhost:3000/',
      responseType: 'code' | 'token'; // or 'token', note that REFRESH token will only be generated when the responseType is code
    };
  };
};

// type CSSColorProperty = {
//   'button-primary-background-color': CSSColor;
//   'button-primary-hover-background-color': CSSColor;
//   'tabs-item-active-border-color': CSSColor;
//   'image-height': string;
//   'fieldcontrol-border-color': CSSColor;
//   'textfield-border-color': CSSColor;
//   'button-border-color': CSSColor;
//   'authenticator-router-border-color': CSSColor;
// };

type PolicyCheckBox = {
  name: string;
  label: string;
  link?: string;
  required: boolean;
};

const RDSAuthenticator = (props: {
  awsconfigure: AWSAmplifyConfigure;
  children: any;
  colorProperty?: any;
  policyCheckBoxes?: PolicyCheckBox[];
}) => {
  useEffect(() => {
    if (props.colorProperty) {
      (document.querySelector('body') as HTMLElement).style.setProperty(
        '--amplify-components-button-primary-background-color',
        props.colorProperty['button-primary-background-color'] || '#0071FF',
      );
      (document.querySelector('body') as HTMLElement).style.setProperty(
        '--amplify-components-button-primary-hover-background-color',
        props.colorProperty['button-primary-hover-background-color'] || '#0071FF',
      );
      (document.querySelector('body') as HTMLElement).style.setProperty(
        '--amplify-components-tabs-item-active-border-color',
        props.colorProperty['tabs-item-active-border-color'] || '#0071FF',
      );
      (document.querySelector('body') as HTMLElement).style.setProperty(
        '--amplify-components-image-heigh',
        props.colorProperty['image-height'] || ' 50px',
      );
      (document.querySelector('body') as HTMLElement).style.setProperty(
        '--amplify-components-fieldcontrol-border-color',
        props.colorProperty['fieldcontrol-border-color'] || '#ccc',
      );
      (document.querySelector('body') as HTMLElement).style.setProperty(
        '--amplify-components-textfield-border-color',
        props.colorProperty['textfield-border-color'] || '#ccc',
      );
      (document.querySelector('body') as HTMLElement).style.setProperty(
        '--amplify-components-button-border-color',
        props.colorProperty['button-border-color'] || '#ccc',
      );
      (document.querySelector('body') as HTMLElement).style.setProperty(
        '--amplify-components-authenticator-router-border-color',
        props.colorProperty['authenticator-router-border-color'] || '#f1f1f1',
      );
    } else {
      (document.querySelector('body') as HTMLElement).style.setProperty(
        '--amplify-components-button-primary-background-color',
        '#0071FF',
      );
      (document.querySelector('body') as HTMLElement).style.setProperty(
        '--amplify-components-button-primary-hover-background-color',
        '#0071FF',
      );
      (document.querySelector('body') as HTMLElement).style.setProperty(
        '--amplify-components-tabs-item-active-border-color',
        '#0071FF',
      );
      (document.querySelector('body') as HTMLElement).style.setProperty('--amplify-components-image-heigh', ' 50px');
      (document.querySelector('body') as HTMLElement).style.setProperty(
        '--amplify-components-fieldcontrol-border-color',
        '#ccc',
      );
      (document.querySelector('body') as HTMLElement).style.setProperty(
        '--amplify-components-textfield-border-color',
        '#ccc',
      );
      (document.querySelector('body') as HTMLElement).style.setProperty(
        '--amplify-components-button-border-color',
        '#ccc',
      );
      (document.querySelector('body') as HTMLElement).style.setProperty(
        '--amplify-components-authenticator-router-border-color',
        '#f1f1f1',
      );
    }
  }, [props.colorProperty]);

  Amplify.configure(props.awsconfigure);
  I18n.putVocabularies(translations);
  I18n.putVocabularies({
    kr: {
      'Sign In': '로그인', // Tab header
      'Sign in': '로그인', // Button label
      'Sign in to your account': '환영합니다!',
      Username: '아이디를 입력해주세요', // Username label
      Email: '이메일을 입력해주세요', // Email label
      Password: '비밀번호를 입력해주세요', // Password label
      'Forgot your password?': '비밀번호를 잊으셨나요?',
      'Sign In with Google': 'Google 로그인',
      'Sign Up with Google': 'Google 회원가입',
      // 'Configuration error (see console) – please contact the administrator': '설정 오류'
      // 'Attributes did not conform to the schema: name: The attribute is required': '뭘까',
      Name: '이름 또는 단체명',
      'We Sent A Code': '인증번호를 보내드렸어요',
      'Your code is on the way. To log in, enter the code we sent you. It may take a minute to arrive.':
        '입력하신 이메일로 인증번호를 보내드렸어요. 이메일을 확인해주세요.',
    },
    en: {
      'Sign In': 'Login', // Tab header
      'Sign in': 'Log in', // Button label
      'Sign in to your account': 'Welcome Back!',
      Username: 'Enter your username', // Username label
      Password: 'Enter your password', // Password label
      'Forgot your password?': 'Reset Password',
      'Sign Up with Google': 'Sign Up with Google',
    },
  });
  I18n.setLanguage('kr');

  return (
    <Authenticator
      loginMechanisms={['email']} // 이메일을 통한 로그인
      socialProviders={['google']} // 구글로그인 활성화 // https://docs.amplify.aws/lib/auth/social/q/platform/js/#oauth-and-federation-overview
      signUpAttributes={['name']} // 회원가입시 필요한 이름 입력란
      components={{
        /** 회원가입 컴포넌트 수정 */
        // https://ui.docs.amplify.aws/react/connected-components/authenticator/customization#headers--footers
        // https://ui.docs.amplify.aws/react/connected-components/authenticator/customization#sign-up-fields
        SignUp: {
          FormFields() {
            return (
              <>
                <Authenticator.SignUp.FormFields />
                <div
                  css={{
                    marginTop: '1rem',
                    marginBottom: '1rem',
                    display: 'flex',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    flexDirection: 'column',
                    gap: '0.5rem',
                  }}
                >
                  {props.policyCheckBoxes ? (
                    props.policyCheckBoxes.map((checkBox) => {
                      return (
                        <div
                          key={checkBox.name}
                          css={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <input
                            css={{
                              // transform: "scale(1.5)"
                              width: '1.3rem',
                              height: '1.3rem',
                            }}
                            required={checkBox.required}
                            type="checkbox"
                            id={checkBox.name}
                            name={checkBox.name}
                          ></input>
                          <label htmlFor={checkBox.name} css={{ marginLeft: '5px' }}>
                            {checkBox.link ? (
                              <a href={checkBox.link} css={{ color: '#0071FF' }} rel="noreferrer" target={'_blank'}>
                                {checkBox.label}
                              </a>
                            ) : (
                              checkBox.label
                            )}
                          </label>
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </div>
              </>
            );
          },
        },
      }}
    >
      {({ signOut, user }) => {
        return <main className="RDSAuthenicator-main">{props.children}</main>;
      }}
    </Authenticator>
  );
};

export default RDSAuthenticator;
