/** @jsxImportSource @emotion/react */
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ServiceDetailTapbar from '../../../../components/ServiceDetailTapbar';
import { RDSButton, RDSCheckbox, RDSDropdown, RDSOption } from '@reconlabs/reconlabs-fe-components';
import { Stage } from '../../../../types';
import UploadProgress from '../../../../components/UploadProgress';
import { UploadProgressContext } from '../../../../context/UploadProgressContextProvider';
import ServiceStatus from '../../../../components/status/ServiceStatus';
import ServiceMaintenance from '../../../../components/matintenance/ServiceMaintenance';
import ServiceSendEmail from '../../../../components/email/ServiceSendEmail';
import { userDataColumn } from '../../../../constants/admintoolConfig';
import ServicePostPopup from '../../../../components/popup/ServicePostPopup';
import CustomerManagement from '../../../../components/user/CustomerManagement';
import Showcase from '../3dpresso/Showcase';

const ServiceDetail = (props: any) => {
  /*----------------------------------------
										 Data
	 ----------------------------------------*/
  const location = useLocation();
  const queryParams = location.search.split('?')[1];
  const serviceName = queryParams.split('=')[1];
  const [selectedTap, setSelectedTap] = useState<string>('status');
  const [stage, setStage] = useState<Stage>('dev');
  const [openProgressBar, setOpenProgressBar] = useState(false);
  const { uploadFiles } = useContext(UploadProgressContext);

  const getContents = () => {
    let result;

    if (selectedTap === 'status') {
      result = <ServiceStatus />;
    } else if (selectedTap === 'maintenance') {
      result = <ServiceMaintenance httpClient={props.httpClient} stage={stage} />;
    } else if (selectedTap === 'email') {
      result = (
        <ServiceSendEmail httpClient={props.httpClient} userDataColumn={userDataColumn[serviceName]} stage={stage} />
      );
    } else if (selectedTap === 'popup') {
      result = <ServicePostPopup httpClient={props.httpClient} stage={stage} />;
    } else if (selectedTap === 'user') {
      result = (
        <CustomerManagement httpClient={props.httpClient} userDataColumn={userDataColumn[serviceName]} stage={stage} />
      );
    } else if (selectedTap === 'showcase') {
      result = <Showcase stage={stage}></Showcase>;
    }

    return result;
  };

  useEffect(() => {
    if (uploadFiles.length > 0) setOpenProgressBar(true);
  }, [uploadFiles]);

  /*----------------------------------------
								Default Template
	 ----------------------------------------*/
  return (
    <div className="admin-tool_body" css={{ padding: '24px', background: '#ffffff !important' }}>
      <div
        style={{
          position: 'fixed',
          padding: '5px',
          backgroundColor: 'black',
          color: 'white',
          textAlign: 'center',
          zIndex: 1000,
          bottom: 0,
          right: openProgressBar ? 0 : -380,
        }}
      >
        <RDSButton
          color="white"
          onClick={() => {
            setOpenProgressBar(!openProgressBar);
          }}
          customStyle={{ height: '10px' }}
        >
          <div>{openProgressBar ? '>' : '<'}</div>
        </RDSButton>
        <UploadProgress></UploadProgress>
      </div>
      <h4
        css={{
          fontSize: '34px',
          fontWeight: '400',
          fontStyle: 'normal',
          fontFamily: 'Noto Sans, sans-serif',
          lineHeight: '123.5%',
          letterSpacing: '0.25px',
          color: 'rgba(0, 0, 0, 0.87)',
        }}
      >
        {serviceName}
      </h4>
      <div style={{ display: 'flex', gap: '30px' }}>
        <RDSDropdown
          value={stage}
          onChange={(value: Stage) => {
            setStage(value);
          }}
        >
          <RDSOption value="dev">dev</RDSOption>
          <RDSOption value="stag">stag</RDSOption>
          <RDSOption value="qa">qa</RDSOption>
          <RDSOption value="prod">prod</RDSOption>
        </RDSDropdown>
      </div>
      <div
        css={{
          marginTop: '38px',
          height: 'calc(100% - 150px)',
        }}
      >
        <ServiceDetailTapbar selectedTab={selectedTap} tapClicked={setSelectedTap} />
        <div css={{ height: 'calc(100% - 70px)' }}>{getContents()}</div>
      </div>
    </div>
  );
};

export default ServiceDetail;
