import { RDSButton, RDSIconButton, RDSInputField, RemoveOutlinedIcon } from '@reconlabs/reconlabs-fe-components';
import { ColumnDef, TableOptions, getCoreRowModel, getPaginationRowModel, useReactTable } from '@tanstack/react-table';
import React, { useEffect, useMemo, useState } from 'react';
import { User } from '../../../../../api/3dpresso/user';
import TanstackTable from '../../../../../components/TanstackTable';
import { EmailGroup, EmailSelectParam, postEmailGroup, putEmailGroup } from '../../../../../api/3dpresso/email';
import { Stage } from '../../../../../types';

const GroupUser = ({
  stage,
  userEmailList,
  onClickCancel,
  onClickSave,
  selectedGroup,
}: {
  stage: Stage;
  // userEmailArr: { user_email: string }[] & Partial<User>[];
  userEmailList: EmailSelectParam;
  onClickCancel?: Function;
  onClickSave?: Function;
  selectedGroup?: EmailGroup;
}) => {
  const initialExcludeUserEmail: Record<string, true> = {};
  for (const email of userEmailList.excluded_users) {
    initialExcludeUserEmail[email] = true;
  }
  console.log(initialExcludeUserEmail);
  const [excludeEmails, setExcludeEmails] = useState<Record<string, true>>(initialExcludeUserEmail);
  const [addEmails, setAddEmails] = useState<string[]>(userEmailList.select_all ? userEmailList.selected_users : []);
  const [addEmailInputValue, setAddEmailInputValue] = useState('');
  const [groupName, setGroupName] = useState(selectedGroup?.group_name || '');

  const onClickSaveButton = async () => {
    if (!groupName) {
      alert('input group name pls');
      return;
    }
    const body: { stage: Stage } & Partial<EmailGroup> = {
      stage,
      group_name: groupName,
      select_all: userEmailList.select_all,
      selected_users: [],
      excluded_users: [],
      filter: userEmailList.filter,
    };

    //전체선택이 아닌경우에는 제외된유저는 선택된유저에서 직접 빼주고 전체선택인경우에는 서버에서빼줌
    if (excludeEmails) {
      !userEmailList.select_all
        ? (body.selected_users = userEmailList.selected_users.filter((user) => !excludeEmails[user]))
        : (body.excluded_users = Object.keys(excludeEmails));
    }
    body.selected_users = body.selected_users && body.selected_users.concat(addEmails);
    selectedGroup ? await putEmailGroup({ ...body, group_id: selectedGroup.group_id }) : await postEmailGroup(body);
    setGroupName('');
    setAddEmailInputValue('');
    onClickSave && onClickSave();
  };

  const columns = React.useMemo<ColumnDef<User>[]>(
    () => [
      {
        header: 'Info',
        footer: (props) => props.column.id,
        columns: [
          {
            header: 'user_email',
            accessorFn: (row) => row,
            // accessorKey: 'user_email',
            cell: (info) => (
              <div style={{ maxWidth: '300px', minWidth: '300px', overflow: 'scroll' }}>{info.getValue()}</div>
            ),
            footer: (props) => props.column.id,
          },
          {
            // accessorFn: (row) => row.user_email,
            header: '제외',
            accessorFn: (row) => row,
            cell: (info) => {
              return (
                !userEmailList.select_all && (
                  <RDSIconButton
                    icon={<RemoveOutlinedIcon />}
                    color={excludeEmails[info.getValue()] ? 'grey' : 'primary'}
                    type="solid"
                    size="xsmall"
                    onClick={() => {
                      const clicked_email = info.getValue();
                      const updatedExcludedEmails: Record<string, true> = { ...excludeEmails, [clicked_email]: true };
                      excludeEmails[clicked_email] && delete updatedExcludedEmails[clicked_email];
                      setExcludeEmails(updatedExcludedEmails);
                    }}
                  ></RDSIconButton>
                )
              );
            },
            footer: (props) => props.column.id,
          },
        ],
      },
    ],
    [excludeEmails],
  );

  const tableConfig: TableOptions<any> = useMemo(
    () => ({
      data: userEmailList.select_all
        ? [
            `전체선택 


             ${Object.keys(userEmailList.filter).length > 0 ? `필터링: ${JSON.stringify(userEmailList.filter)}` : ''}`,
          ]
        : userEmailList.selected_users,
      columns,
      getCoreRowModel: getCoreRowModel(),
      getPaginationRowModel: getPaginationRowModel(), // If only doing manual pagination, you don't need this
      debugTable: true,
    }),
    [excludeEmails],
  );
  const table = useReactTable(tableConfig);

  return (
    <div style={{ padding: '10px', backgroundColor: 'white' }}>
      <h2>Group Customer</h2>
      <div style={{ display: 'flex', gap: '50px' }}>
        <TanstackTable table={table} />

        <div>
          <div>제외 고객</div>
          <div style={{ maxHeight: '400px', width: '200px', maxWidth: '200px', overflow: 'scroll' }}>
            {/* 전체선택 시에는 selected User가 제외된 고객, 아닌 경우에는 selected user가 보낼 고객, excludeEMails가 제외할 고객 */}
            {Object.keys(excludeEmails).map((user_email, idx) => (
              <div key={idx}>{user_email}</div>
            ))}
          </div>
        </div>

        <div>
          <div>
            <div>추가 고객</div>
            <div style={{ maxHeight: '400px', width: '200px', maxWidth: '200px', overflow: 'scroll' }}>
              {addEmails.map((v, idx) => (
                <div key={idx}>{v}</div>
              ))}
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <RDSInputField.Text
            value={addEmailInputValue}
            title={'고객 추가'}
            handleInputChange={(e) => {
              setAddEmailInputValue(e.target.value);
            }}
          ></RDSInputField.Text>
          <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
            <RDSButton
              customStyle={{ width: '100px' }}
              onClick={() => {
                const emailRegex = new RegExp('[a-z0-9]+@[a-z]+.[a-z]{2,3}');
                if (emailRegex.test(addEmailInputValue)) {
                  addEmails.push(addEmailInputValue);
                  setAddEmails(addEmails);
                  setAddEmailInputValue('');
                } else {
                  alert('invalid email format');
                }
              }}
            >
              추가
            </RDSButton>
          </div>
          <RDSInputField.Text
            title={'Group Name'}
            value={groupName}
            handleInputChange={(e) => {
              setGroupName(e.target.value);
            }}
          />
          <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
            <RDSButton
              type="outline"
              customStyle={{ width: '100px' }}
              onClick={() => {
                onClickCancel && onClickCancel();
              }}
            >
              취소
            </RDSButton>
            <RDSButton customStyle={{ width: '100px' }} onClick={onClickSaveButton}>
              저장
            </RDSButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GroupUser;
