/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { PopupItem } from './PopupService';

type PostingPopupProps = {
  postingItems: PopupItem[];
  selectedItem: PopupItem | undefined;
  postConfirmed: Function;
  itemSelected: Function;
  setOpenDeleteModal: Function;
  setOpenPreviewModal: Function;
};

const PopupCurrentStatus = (props: PostingPopupProps) => {
  const columns: ColumnDef<PopupItem>[] = [
    {
      header: '팝업 현황',
      footer: (props) => props.column.id,
      columns: [
        {
          header: '팝업 만료 시각',
          accessorKey: 'endDate',
          cell: (info) => info.getValue(),
          footer: (props) => props.column.id,
        },
        {
          header: '사유',
          accessorKey: 'type',
          cell: (info) => info.getValue(),
          footer: (props) => props.column.id,
        },
        {
          header: '상태',
          accessorKey: 'state',
          cell: (info) => (info.getValue() && info.getValue() === 'on' ? <>게시중</> : <>대기중</>),
          footer: (props) => props.column.id,
        },
        {
          header: '게시 확정',
          accessorFn: (row) => row,
          cell: (info) => {
            return info.getValue() && info.getValue().state === 'off' ? (
              <Button
                size="small"
                variant={'outlined'}
                onClick={() => {
                  onConfirmButtonClicked(info.getValue());
                }}
              >
                게시 확정
              </Button>
            ) : (
              <>-</>
            );
          },
          footer: (props) => props.column.id,
        },
        {
          header: '미리보기',
          accessorFn: (row) => row,
          cell: (info) => (
            <VisibilityIcon css={{ cursor: 'pointer' }} onClick={() => onPreviewHover(info.getValue())} />
          ),
          footer: (props) => props.column.id,
        },
        {
          header: '수정',
          accessorFn: (row) => row,
          cell: (info) => <EditIcon css={{ cursor: 'pointer' }} onClick={() => onEditButtonClicked(info.getValue())} />,
          footer: (props) => props.column.id,
        },
        {
          header: '삭제',
          accessorFn: (row) => row,
          cell: (info) => (
            <DeleteIcon css={{ cursor: 'pointer' }} onClick={() => onDeleteButtonClicked(info.getValue())} />
          ),
          footer: (props) => props.column.id,
        },
        {
          header: '태그',
          accessorKey: 'tag',
          cell: (info) => info.getValue(),
          footer: (props) => props.column.id,
        },
      ],
    },
  ];

  const table = useReactTable({
    data: props.postingItems,
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    debugTable: true,
  });
  const [state, setState] = React.useState(table.initialState);

  /**
   * 게시확정 버튼 클릭 이벤트 핸들러
   * state 를 on 으로 수정
   * @param item: PopupItem
   */
  const onConfirmButtonClicked = (item: PopupItem) => {
    const body = {
      ...item,
      popupId: Number(item.popupId),
      state: 'on',
    };
    props.postConfirmed(body);
  };

  const onEditButtonClicked = (item: PopupItem) => {
    props.itemSelected(item);
  };

  const onDeleteButtonClicked = (item: PopupItem) => {
    props.itemSelected(item);
    props.setOpenDeleteModal(true);
  };

  const onPreviewHover = (item: PopupItem) => {
    props.setOpenPreviewModal(true);
    props.itemSelected(item);
  };
  /*----------------------------------------
                Default Template
   ----------------------------------------*/
  return (
    <div>
      <table style={{ width: '600px', textAlign: 'center' }}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => {
            return (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => {
                  return <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="h-2" />
    </div>
  );
};

export default PopupCurrentStatus;
