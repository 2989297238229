import { Stage } from '../../types';
import { AxiosInstance, getAuthorization } from '../AxiosInstance';

export type User = {
  idx: string;
  uid?: string;
  user_email: string;
  user_name: string;
  display_name: string;
  user_staus: string;
  free_beans: number;
  paid_beans: number;
};

export type TableFetchOptions = {
  searchStr?: string[];
  searchOption?: 'and' | 'or';
  range: [number, number];
  sortColumn?: string;
  sortOrder?: 'asc' | 'desc' | '';
  where?: string;
  externalInternalppl?: boolean;
  filter?: Record<string, any>;
};

export const getUserList = async (
  stage: Stage,
  options: TableFetchOptions,
): Promise<{ users: User[]; count: number }> => {
  await getAuthorization();
  const res = await AxiosInstance.get(`/3dpresso/user/list`, {
    params: {
      stage,
      range: `[${options.range}]`,
      searchStrs: JSON.stringify(options.searchStr),
      sortColumn: options.sortColumn || '',
      sortOrder: options.sortOrder || '',
      where: options.where,
      excludeInternalppl: options.externalInternalppl || false,
      filter: options.filter || {},
    },
  });
  return res.data;
};

export const getAnalyzeUserList = async (
  stage: Stage,
  user_idx_arr: any[],
): Promise<{ idx: number; user_email: string }[]> => {
  await getAuthorization();
  const res = await AxiosInstance.get(`/3dpresso/analyze/user/list`, {
    params: {
      stage,
      user_idx_arr: JSON.stringify(user_idx_arr),
    },
  });
  return res.data.data;
};

export type Payment = {
  bean_type: string;
  bean_amount: string;
  action_type: 'givefree' | 'charge' | 'refund' | 'use' | 'restore' | 'provide';
  action_detail: any;
  transaction_date: string;
};

// user_idx: { req: true, type: 'Int', desc: 'user_idx' },
// range: { req: false, type: 'Int[]', desc: '요청할 데이터 범위([시작 index, 끝 index])' },
// type: { req: false, type: 'string', desc: 'purchase / usage' },
// isApi: { req: false, type: 'string', desc: 'api 필터링 옵션(true/false)' },
export const getPaymentlistByUser = async (user_email: string): Promise<any> => {
  await getAuthorization();

  const res = await AxiosInstance.get(`/3dpresso/payment/list`, {
    params: {
      user_email,
    },
  });
  return res.data;
};
export const getPaymentlist = async (stage: Stage, options: any): Promise<{ payments: Payment[]; count: number }> => {
  await getAuthorization();

  const res = await AxiosInstance.get(`/3dpresso/payment/list`, {
    params: {
      stage,
      range: `[${options.range}]`,
      transaction_type: JSON.stringify(options.transaction_type),
      transaction_detail: options.transaction_detail,
    },
  });
  return res.data;
};

export const getUserSubscription = async (userEmail: any): Promise<any> => {
  await getAuthorization();

  const res = await AxiosInstance.get(`/3dpresso/user/subscription`, {
    params: {
      user_email: userEmail,
    },
  });
  return res.data;
};

// stage: { req: true, type: 'String', desc: '유저 리스트를 가져올 stage' },
// payment_idx: { req: true, type: 'Int', desc: 'payment idx' },
// reason: { req: true, type: 'Int', desc: 'cancel reason' },
export const refundPayment = async (
  chargeId: string,
  reason: string,
): Promise<{ payments: Payment[]; count: number }> => {
  const res = await AxiosInstance.post(`/3dpresso/payment/refund`, {
    chargeId,
    reason,
  });
  return res.data;
};

export const changeShowcaseOrder = async (stage: Stage, newModelList: Array<Object>) => {
  const res = await AxiosInstance.put(`3dpresso/showcase/order`, { stage, newModelList });
  return res.data;
};

export const deleteShowcaseModel = async (stage: Stage, seq: string) => {
  const res = await AxiosInstance.delete(`/3dpresso/showcase/model`, { params: { stage, seq } });
  return res.data;
};
