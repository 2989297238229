import { PostEmailItem, Stage } from '../../types';
import { AxiosInstance, PROD_SERVER_DOMAIN, prodAxiosInstance } from '../AxiosInstance';
import * as dataConverter from '../../lib/data-converter';
import { AxiosRequestConfig } from 'axios';

export async function getEmailList(
  // searchWord: string | undefined,
  stage?: Stage,
): Promise<any[]> {
  const res = await AxiosInstance.get('/3dpresso/email/list', {
    params: {
      stage,
      // searchStr: searchWord,
    },
  });
  const data = res.data.data;
  return data;
}

export async function getEmailDetail(
  emailId: string,
  stage?: Stage,
): Promise<{ title: string; typeValue: string; typeLabel: string; content: string; design: any }> {
  const res = await AxiosInstance.get('/3dpresso/email', {
    params: {
      emailId: emailId,
      stage: stage,
    },
  });
  return res.data.data;
}

export async function getEmailEvent(
  user_email: string,
  stage?: Stage,
): Promise<{ title: string; typeValue: string; typeLabel: string; event: string[] }[]> {
  const res = await AxiosInstance.get('/3dpresso/email/event', {
    params: {
      user_email,
      stage: stage,
    },
  });
  return res.data.emailsEvent;
}

export async function postEmail(
  body: {
    type: string;
    title: string;
    template_name: string;
    group_id?: string;
    content: string;
    design: Object;
    stage: Stage;
    zero?: Boolean;
  } & Partial<EmailSelectParam>,
): Promise<number> {
  const axiosInstacneByStage = body.stage == 'prod' ? prodAxiosInstance : AxiosInstance;
  const res = await axiosInstacneByStage.post('/3dpresso/email', body);
  return res.status;
}

export async function postEmailGroup(
  body: {
    stage?: Stage;
  } & Partial<EmailGroup>,
): Promise<any> {
  const config: AxiosRequestConfig = {};
  if (body.stage == 'prod') config.baseURL = PROD_SERVER_DOMAIN;
  const res = await AxiosInstance.post('/3dpresso/email/group', body, config);
  return res.data;
}

export async function putEmailGroup(
  body: {
    stage?: Stage;
  } & Partial<EmailGroup>,
): Promise<any> {
  const config: AxiosRequestConfig = {};
  if (body.stage == 'prod') config.baseURL = PROD_SERVER_DOMAIN;
  const res = await AxiosInstance.put('/3dpresso/email/group', body, config);
  return res.data;
}

export async function getEmailGroupList(params: { stage?: Stage }): Promise<{ groups: EmailGroup[]; Count: number }> {
  const config: AxiosRequestConfig = { params };
  if (params.stage == 'prod') config.baseURL = PROD_SERVER_DOMAIN;
  const res = await AxiosInstance.get('/3dpresso/email/group/list', config);
  return res.data;
}

export async function deleteEmailGroup(params: { stage?: Stage; group_id: string }): Promise<any> {
  const config: AxiosRequestConfig = { params };
  if (params.stage == 'prod') config.baseURL = PROD_SERVER_DOMAIN;
  const res = await AxiosInstance.delete('/3dpresso/email/group', config);
  return res.data;
}

export type EmailGroup = {
  group_id: string;
  group_name: string;
} & EmailSelectParam;

export type EmailSelectParam = {
  selected_users: string[];
  excluded_users: string[];
  select_all: boolean;
  filter: Record<string, any>;
};
