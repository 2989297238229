import React, { useEffect, useState } from 'react';
import { Stage } from '../../../../../../types';
import { getBeanAnalyzeList } from '../../../../../../api/3dpresso/bean';
import TankstackChart from '../../../../../../components/TankstackChart';
import { getAnalyzeUserList } from '../../../../../../api/3dpresso/user';
import { RDSDropdown, RDSOption } from '@reconlabs/reconlabs-fe-components';
import LoadingInterface from '../../../../../../components/LoadingInterface';

const BeanDashbaord = ({ stage }: { stage: Stage }) => {
  const [beanList, setBeanList] = useState<BeanRow[]>([]);

  // typeData: [{ usage: '', count: 0 }],
  // engineData: [{ engineType: '', count: 0 }],
  // userData: [{ label: 'processing', data: [{ user_email: '', count: 0 }] }],
  // freeBeanData: [{ usage: '', count: 0 }],
  // paidBeanData: [{ usage: '', count: 0 }],
  const [data, setData] = useState<any>({});

  const [groupByUserCount, setGroupByUserCount] = useState<Record<string, Record<string, number>>>({});
  const [sortBy, setSortBy] = useState('processing');
  const [howManyUserShow, setHowManyUserShow] = useState(10);

  const [month, setMonth] = useState(new Date().getMonth() + 1);

  const months = [];
  for (let month = 1; month <= new Date().getMonth() + 1; month++) {
    months.push(month);
  }
  const types = [
    'usage',
    'processing',
    'processing_objaverse',
    'processing_copy',
    'download',
    'restore',
    'purchase',
    'givefree',
    'set',
  ];
  const engine_types = ['metarecon3', 't3', 'salad', 'lasd'];

  useEffect(() => {
    (async function () {
      setData({});
      const res = await getBeanAnalyzeList(stage, month);
      setBeanList(res.data);
    })();
  }, [month, stage]);

  useEffect(() => {
    (async function () {
      console.log('sort', sortBy);
      const sorted = groupByUserCount[sortBy]
        ? Object.entries(groupByUserCount[sortBy])
            .sort(([, a], [, b]) => b - a)
            .slice(0, howManyUserShow)
        : [];
      // result = [ [user_idx, 10] , [user_idx, 20] ,... ]

      // need to make [{label:"processing",data:[{user_idx:1,count:10},...]} , {label:""}]

      if (!sorted[0]) {
        return;
      }

      const groupByUserChartData = [];
      const user_idx_arr = sorted.map((v) => v[0]);
      const user_arr = await getAnalyzeUserList(stage, user_idx_arr);
      const user_idx_email_matcher: Record<string, string> = {};
      user_arr.map((user) => (user_idx_email_matcher[user.idx] = user.user_email));
      for (const type in groupByUserCount) {
        // list 10 person
        const data = [];
        for (const user_idx of user_idx_arr) {
          data.push({ user_email: user_idx_email_matcher[user_idx], count: groupByUserCount[type][user_idx] || 0 });
        }
        groupByUserChartData.push({ label: type, data });
      }
      setData({ ...data, userData: groupByUserChartData });
    })();
  }, [sortBy, howManyUserShow]);

  useEffect(() => {
    (async function () {
      const groupByEngineCount: Record<string, number> = {};

      const groupByTypeCount: Record<string, number> = {};

      const groupByFreeBeanCount: Record<string, number> = {};
      const groupByFreeBeanAmount: Record<string, number> = {};
      const groupByPaidBeanCount: Record<string, number> = {};
      const groupByPaidBeanAmount: Record<string, number> = {};

      // {processing:{user_idx:10, user_idx:32, ...}, download: {user_idx:3, ...}}
      const groupByUserCount: Record<string, Record<string, number>> = {};

      for (const beanRow of beanList) {
        // type
        addCount(groupByTypeCount, beanRow.transaction_type);

        // bean_type
        beanRow.bean_type == 'free'
          ? (addCount(groupByFreeBeanCount, beanRow.transaction_type),
            addCount(groupByFreeBeanAmount, beanRow.transaction_type, beanRow.bean_amount))
          : (addCount(groupByPaidBeanCount, beanRow.transaction_type),
            addCount(groupByPaidBeanAmount, beanRow.transaction_type, beanRow.bean_amount));

        // user
        !groupByUserCount[beanRow.transaction_type] && (groupByUserCount[beanRow.transaction_type] = {});
        addCount(groupByUserCount[beanRow.transaction_type], String(beanRow.user_idx));

        if (beanRow.transaction_type === 'usage') {
          // type
          addCount(groupByTypeCount, beanRow.transaction_detail.type);

          // bean_type
          beanRow.bean_type == 'free'
            ? (addCount(groupByFreeBeanCount, beanRow.transaction_detail.type),
              addCount(groupByFreeBeanAmount, beanRow.transaction_detail.type, beanRow.bean_amount))
            : (addCount(groupByPaidBeanCount, beanRow.transaction_detail.type),
              addCount(groupByPaidBeanAmount, beanRow.transaction_detail.type, beanRow.bean_amount));

          // user
          !groupByUserCount[beanRow.transaction_detail.type] &&
            (groupByUserCount[beanRow.transaction_detail.type] = {});
          addCount(groupByUserCount[beanRow.transaction_detail.type], String(beanRow.user_idx));

          // engine
          beanRow.transaction_detail.engine_type &&
            addCount(groupByEngineCount, beanRow.transaction_detail.engine_type);
        }
      }

      const groupByUserChartData = [];
      const groupByTypeChartData = [];
      const groupByEngineChartData = [];
      const groupByFreeBeanChartData = [];
      const groupByPaidBeanChartData = [];
      const groupByFreeBeanAmountChartData = [];
      const groupByPaidBeanAmountChartData = [];

      for (const type of types) {
        groupByTypeChartData.push({ type: type, count: groupByTypeCount[type] || 0 });
        groupByFreeBeanChartData.push({ type: type, count: groupByFreeBeanCount[type] || 0 });
        groupByPaidBeanChartData.push({ type: type, count: groupByPaidBeanCount[type] || 0 });
        groupByFreeBeanAmountChartData.push({ type: type, count: groupByFreeBeanAmount[type] || 0 });
        groupByPaidBeanAmountChartData.push({ type: type, count: groupByPaidBeanAmount[type] || 0 });
      }

      for (const engineType of engine_types) {
        groupByEngineChartData.push({ engineType: engineType, count: groupByEngineCount[engineType] || 0 });
      }

      const sorted = groupByUserCount[sortBy]
        ? Object.entries(groupByUserCount[sortBy])
            .sort(([, a], [, b]) => b - a)
            .slice(0, howManyUserShow)
        : [];
      // result = [ [user_idx, 10] , [user_idx, 20] ,... ]

      // need to make [{label:"processing",data:[{user_idx:1,count:10},...]} , {label:""}]

      if (!sorted[0]) {
        return;
      }
      const user_idx_arr = sorted.map((v) => v[0]);
      const user_arr = await getAnalyzeUserList(stage, user_idx_arr);
      const user_idx_email_matcher: Record<string, string> = {};
      user_arr.map((user) => (user_idx_email_matcher[user.idx] = user.user_email));
      for (const type of types) {
        // list 10 person
        const data = [];
        for (const user_idx of user_idx_arr) {
          data.push({
            user_email: user_idx_email_matcher[user_idx],
            count: (groupByUserCount[type] && groupByUserCount[type][user_idx]) || 0,
          });
        }
        groupByUserChartData.push({ label: type, data });
      }
      setData({
        typeData: groupByTypeChartData,
        engineData: groupByEngineChartData,
        userData: groupByUserChartData,
        freeBeanData: groupByFreeBeanChartData,
        freeBeanAmountData: groupByFreeBeanAmountChartData,
        paidBeanData: groupByPaidBeanChartData,
        paidBeanAmountData: groupByPaidBeanAmountChartData,
      });
      setGroupByUserCount(groupByUserCount);
    })();
  }, [beanList]);

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <div>Month Select</div>
        <RDSDropdown
          size="small"
          value={month}
          onChange={(value) => {
            setMonth(Number(value));
          }}
        >
          {months.map((month) => (
            <RDSOption key={month} value={month}>
              {month}
            </RDSOption>
          ))}
        </RDSDropdown>
      </div>
      {Object.keys(data).length > 0 ? (
        <div style={{ display: 'flex', width: '1200px', flexWrap: 'wrap', gap: '30px' }}>
          <div>
            <h3>total count</h3>
            <TankstackChart width={400} x_axis="type" y_axis="count" data={[{ label: 'type', data: data.typeData }]} />
          </div>
          <div>
            <h3>engine type</h3>
            <TankstackChart x_axis="engineType" y_axis="count" data={[{ label: 'engine', data: data.engineData }]} />
          </div>
          <div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
              <h3>user</h3>
              <div>
                select order
                <RDSDropdown
                  size="small"
                  value={sortBy}
                  onChange={(value) => {
                    setSortBy(value);
                  }}
                >
                  {types.map((type) => (
                    <RDSOption key={type} value={type}>
                      {type}
                    </RDSOption>
                  ))}
                </RDSDropdown>
              </div>
              <div>
                how many user
                <RDSDropdown
                  size="small"
                  value={howManyUserShow}
                  onChange={(value) => {
                    setHowManyUserShow(Number(value));
                  }}
                >
                  {[10, 15, 20, 25, 30].map((number) => (
                    <RDSOption key={number} value={number}>
                      {number}
                    </RDSOption>
                  ))}
                </RDSDropdown>
              </div>
            </div>
            <TankstackChart width={1000} height={600} x_axis="user_email" y_axis="count" data={data.userData} />
          </div>
          <section style={{ display: 'flex', gap: '30px' }}>
            <div>
              <h3>free bean</h3>
              <TankstackChart x_axis="type" y_axis="count" data={[{ label: 'type', data: data.freeBeanData }]} />
            </div>
            <div>
              <h3>free bean Amount</h3>
              <TankstackChart x_axis="type" y_axis="count" data={[{ label: 'type', data: data.freeBeanAmountData }]} />
            </div>
            <div>
              <BeanAmountAnalyzeTable datas={data.freeBeanAmountData} />
            </div>
          </section>
          <section style={{ display: 'flex', gap: '30px' }}>
            <div>
              <h3>paid bean</h3>
              <TankstackChart x_axis="type" y_axis="count" data={[{ label: 'type', data: data.paidBeanData }]} />
            </div>
            <div>
              <h3>paid bean Amount</h3>
              <TankstackChart x_axis="type" y_axis="count" data={[{ label: 'type', data: data.paidBeanAmountData }]} />
            </div>
            <div>
              <BeanAmountAnalyzeTable datas={data.paidBeanAmountData} />
            </div>
          </section>
        </div>
      ) : (
        <LoadingInterface.EyeRolling></LoadingInterface.EyeRolling>
      )}
    </div>
  );
};

const addCount = (object: Record<string, number>, key: string, count: number = 1) => {
  object[key] == undefined ? (object[key] = count) : (object[key] += count);
};

export default BeanDashbaord;

export type BeanRow = {
  idx: number;
  uid: string;
  user_idx: number;
  bean_type: 'free' | 'paid';
  bean_amount: number;
  transaction_type: 'restore' | 'givefree' | 'purchase' | 'usage' | 'refund';
  transaction_detail: {
    seq: string;
    type: 'processing' | 'download' | 'processing_objaverse' | 'processing_copy';
    is_api: boolean;
    bean_amount: number;
    engine_type: string;
    transaction_uid: string;
  };
  transaction_date: string;
};

// {user_idx:1, processing:10}, {}
// {user_idx}

// const BeanAmountAnalyzeTable = ({ datas }: { datas: { type: string; count: number }[] }) => {
const BeanAmountAnalyzeTable = ({ datas }: { datas: any[] }) => {
  console.log(datas);
  const getBeanAmountByType = (type: string) => datas && datas.find((data) => data.type === type).count;
  return (
    <table style={{ textAlign: 'center' }}>
      <thead>
        <tr>
          <th>type</th>
          <th>bean amount</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td scope="row">usage</td>
          <td>{getBeanAmountByType('usage')}</td>
        </tr>
        <tr>
          <td scope="row">givefree</td>
          <td>{getBeanAmountByType('givefree')}</td>
        </tr>
        <tr>
          <td scope="row">purchase</td>
          <td>{getBeanAmountByType('purchase')}</td>
        </tr>
        <tr>
          <td scope="row">usage - givefree</td>
          <td>{getBeanAmountByType('usage') - getBeanAmountByType('givefree')}</td>
        </tr>
        <tr>
          <td scope="row">purchase - usage</td>
          <td>{getBeanAmountByType('purchase') - getBeanAmountByType('usage')}</td>
        </tr>
      </tbody>
    </table>
  );
};
