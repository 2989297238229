/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import {
  RDSDataTable,
  RDSIconDropdown,
  RDSOption,
  RDSButton,
  RDSModal,
  RDSInputField,
  RDSToast,
} from '@reconlabs/reconlabs-fe-components';
import { PaginationOption } from '../../../../types';
import { createPayment, getPaymentList, refreshPaymentList } from '../../../../lib/payment';
import {
  PAYMENT_BEFORE,
  PAYMENT_BILLING,
  PAYMENT_BILLING_CANCEL,
  PAYMENT_BILLING_END,
  PAYMENT_BILLING_FAIL,
} from '../../../../constants/admintoolConfig';

const MoreVertOutlined = () => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5 8C13.6 8 14.5 7.1 14.5 6C14.5 4.9 13.6 4 12.5 4C11.4 4 10.5 4.9 10.5 6C10.5 7.1 11.4 8 12.5 8ZM12.5 10C11.4 10 10.5 10.9 10.5 12C10.5 13.1 11.4 14 12.5 14C13.6 14 14.5 13.1 14.5 12C14.5 10.9 13.6 10 12.5 10ZM12.5 16C11.4 16 10.5 16.9 10.5 18C10.5 19.1 11.4 20 12.5 20C13.6 20 14.5 19.1 14.5 18C14.5 16.9 13.6 16 12.5 16Z"
        fill="white"
      />
    </svg>
  );
};

const Payment = (props: any) => {
  /*----------------------------------------
								    Data
	 ----------------------------------------*/
  const [customerKey, setCustomerKey] = useState('');
  const [amount, setAmount] = useState<string>('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState('');
  const [selectedDropdown] = useState<string>('');
  const [openCreatePaymentModal, setOpenCreatePaymentModal] = useState<boolean>(false);
  const [openCreateCompleteModal, setOpenCreateCompleteModal] = useState<boolean>(false);
  const [paymentList, setPaymentList] = useState<any[]>([]);
  const [columns] = useState<any[]>([
    {
      name: '결제 번호',
      props: 'payment_no',
      selector: (row: any) => row.idx,
      width: '100px',
      center: true,
      sortable: true,
    },
    {
      name: '담당자 이름',
      props: 'name',
      selector: (row: any) => row.administrator,
      width: '200px',
      center: true,
      sortable: true,
    },
    {
      name: '링크 생성 일시',
      props: 'create_data',
      selector: (row: any) => row.registered_date,
      width: '200px',
      center: true,
      sortable: true,
    },
    {
      name: '금액',
      props: 'amount',
      selector: (row: any) => row.amount,
      width: '180px',
      center: true,
      sortable: true,
    },
    {
      name: '상태',
      props: 'status',
      selector: (row: any) => row.status,
      width: '180px',
      center: true,
      sortable: true,
    },
    {
      name: '영수증 링크',
      props: 'link',
      selector: (row: any) => row.receipt,
      width: '200px',
      center: true,
      sortable: true,
      cell: (row: any) => {
        return (
          <div className="DropDownWrapper" css={{ position: 'relative' }}>
            <RDSIconDropdown
              icon={<MoreVertOutlined />}
              value={selectedDropdown}
              onChange={(value) => onDropdownChange(row, value)}
              customStyles={{
                '& .RDSDropdown__list': {
                  width: '214px',
                },
              }}
            >
              <RDSOption value="copyLink">결제 링크 복사</RDSOption>
              <RDSOption value="showLink">결제 링크 보기</RDSOption>
              <RDSOption value="showReceipt">영수증 보기</RDSOption>
              <RDSOption value="moveToss">토스페이먼츠 이동하기</RDSOption>
            </RDSIconDropdown>
          </div>
        );
      },
    },
  ]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [paginationOption, setPaginationOption] = useState<PaginationOption>({
    offset: 0,
    page: 1,
    range: [1, 10],
    rowsPerPage: 10,
    searchText: '',
    currentSort: { props: 'name', type: 'asc' },
  });
  const httpClient = props.httpClient;
  /*----------------------------------------
								  Life Cycle
	 ----------------------------------------*/
  useEffect(() => {
    // DataTable Search filter area style
    const el: HTMLElement = document.querySelector('.RDSDataTable > header')!;
    el.style.display = 'block';
    el.style.position = 'static';
    el.style.width = '400px';

    // table body style
    const tableBody = document.querySelector('.rdt_Table')?.parentElement?.parentElement;
    if (tableBody) {
      tableBody.style.height = '317px';
      tableBody.style.overflow = 'auto';
    }

    //결제 목록 받아오기
    (async () => {
      const resData = await getPaymentList([0, 19]);
      const paymentList = resData.payments.map(formatPaymentList);
      setTotalCount(resData.count);
      setPaymentList(paymentList);
    })();
  }, []);
  /*----------------------------------------
								  Event Handler
	 ----------------------------------------*/
  const handlePaginationOption = (e: any) => {
    setPaginationOption(e);
  };

  const onDropdownChange = async (row: any, value: string) => {
    const paymentURL = `${process.env.REACT_APP_PAYMENT_DOMAIN}?customerKey=${row.customer_key}`;
    switch (value) {
      case 'copyLink':
        await navigator.clipboard.writeText(paymentURL);
        break;
      case 'showLink':
        window.open(paymentURL, '_blank');
        break;
      case 'showReceipt':
        window.open(row.receipt, '_blank', 'height=800,width=500');
        break;
      case 'moveToss':
        break;
      default:
        break;
    }
    console.log('onDropdownChange value : ', value);
  };

  const handleCreatePayment = async () => {
    const loginResult = await httpClient.getLoginInfo();
    const administrator = loginResult.name;
    setOpenCreatePaymentModal(false);
    const res = await createPayment(parseInt(amount), description, administrator);
    setCustomerKey(res?.customerKey);
    await onRefresh(); //결제 목록 refetch
    setOpenCreateCompleteModal(true);
  };

  const onRefresh = async () => {
    setLoading('loading');
    await refreshPaymentList();
    console.log('refresh');
    const resData = await getPaymentList([0, 9]);
    const paymentList = resData.payments.map(formatPaymentList);
    setPaymentList(paymentList);
    setAmount('');
    setDescription('');
    setLoading('');
  };
  /*----------------------------------------
								Default Template
	 ----------------------------------------*/
  return (
    <>
      <RDSModal
        open={openCreatePaymentModal}
        onClose={() => {
          setAmount('');
          setDescription('');
          setOpenCreatePaymentModal(false);
        }}
        enableCloseButton={false}
        title={'커스텀 정기결제 생성하기'}
        supportingText={<div>토스페이먼츠 정기결제를 이용하여 아래의 금액으로 커스텀 정기결제 링크를 생성합니다.</div>}
        additionalContents={
          <div
            css={{
              fontFamily: 'Pretendard',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '14px',
              lineHeight: '26px',
            }}
          >
            <div style={{ margin: '15px 0 5px 0' }}>
              생성을 원하는 금액을 설정해주세요. 현재는 월간 정기 결제 금액만 지원합니다.
            </div>
            <RDSInputField.Text
              value={amount}
              handleInputChange={(e) => {
                setAmount(e.target.value);
              }}
              placeholder={'정기결제 금액 (원)'}
            />
            <div style={{ marginTop: '40px' }}>
              고객에게 전달할 상세 내용을 아래에 작성해주세요. 고객이 결제할 페이지에 표시됩니다.
            </div>
            <RDSInputField.Box
              value={description}
              handleInputChange={(e) => {
                setDescription(e.target.value);
              }}
              height="200px"
              placeholder={'제품 상세 내용 (ex, 월간 뷰수, 트래픽 등)'}
            />
          </div>
        }
        buttonType="solid"
        button1Label="결제 생성하기"
        button1Fn={handleCreatePayment}
      />
      <RDSModal
        open={openCreateCompleteModal}
        onClose={() => setOpenCreateCompleteModal(false)}
        enableCloseButton={false}
        title={'결제 링크 생성 완료'}
        supportingText={<div>커스텀 정기결제 링크가 생성되었습니다. 아래의 링크를 복사하여 고객에게 전달해주세요.</div>}
        linkText={`${process.env.REACT_APP_PAYMENT_DOMAIN}?customerKey=${customerKey}`}
        buttonType="solid"
        button1Label="링크 복사"
        button1Fn={async () => {
          await navigator.clipboard.writeText(`${process.env.REACT_APP_PAYMENT_DOMAIN}?customerKey=${customerKey}`);
        }}
        button2Label="닫기"
        button2Fn={() => setOpenCreateCompleteModal(false)}
      />
      <RDSToast.Overlay
        type="loadingSpinner"
        openedToastOverlay={loading}
        toastOverlayId="loading"
        setOpenedToastOverlay={setLoading}
      />
      <div css={{ height: '100%', padding: '25px' }}>
        <div
          css={{
            fontSize: '34px',
            fontWeight: '400',
            fontStyle: 'normal',
            fontFamily: 'Noto Sans, sans-serif',
            lineHeight: '123.5%',
            letterSpacing: '0.25px',
            color: 'rgba(0, 0, 0, 0.87)',
          }}
        >
          커스텀 결제
        </div>
        <div css={{ border: '1px solid black', marginTop: '20px', height: 'calc(100% - 60px)', padding: '15px' }}>
          <div
            css={{
              fontFamily: 'Noto Sans, sans-serif',
              fontStyle: 'normal',
              fontWeight: '500',
              fontSize: '20px',
              lineHeight: '32px',
              color: '#000000DE',
              letterSpacing: '0.15px',
            }}
          >
            전체 커스텀 결제 : {totalCount}
            <div style={{ display: 'flex', gap: '15px' }}>
              <RDSButton
                size="xsmall"
                customStyle={{ marginTop: '10px' }}
                onClick={() => setOpenCreatePaymentModal(true)}
              >
                신규 결제
              </RDSButton>
              <RDSButton size="xsmall" type="outline" customStyle={{ marginTop: '10px' }} onClick={() => onRefresh()}>
                새로고침
              </RDSButton>
            </div>
            <div
              css={{
                width: '100%',
                height: '100%',
                marginTop: '24px',
              }}
            >
              <RDSDataTable
                data={paymentList}
                columns={columns}
                searchInput={'default'}
                searchFilterKeyNames={[
                  // TODO : column 명 바인딩
                  'clientCompany',
                  'clientJobPosition',
                  'name',
                  'email',
                  'user_name',
                  'user_email',
                  'tags',
                ]}
                customStyle={{
                  width: '100%',
                }}
                dataTableOptions={{
                  paginationPerPage: 10,
                  paginationRowsPerPageOptions: [10, 30, 50, 100, 300],
                }}
                sortServer={true}
                pagination={true}
                paginationServer={true}
                paginationTotalRows={totalCount}
                actionButton={<></>}
                onChangePaginationOption={handlePaginationOption}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const formatPaymentList = (payment: any) => {
  payment.registered_date = payment.registered_date.split(' ')[0].replace('-', '.').replace('-', '.');
  payment.amount = payment.amount.toLocaleString() + '원';
  payment.status = convertPaymentStatus(payment.status);
  return payment;
};

const convertPaymentStatus = (status: string) => {
  switch (status) {
    case PAYMENT_BEFORE:
      return '결제 전';
    case PAYMENT_BILLING:
      return '결제 완료(정기 결제 중)';
    case PAYMENT_BILLING_FAIL:
      return '결제 실패(정기 결제 중)';
    case PAYMENT_BILLING_CANCEL:
      return '결제 취소(정기 결제 중)';
    case PAYMENT_BILLING_END:
      return '정기 결제 해지';
  }
};

export default Payment;
