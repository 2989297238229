import { plicarButtonImgUrl, PLICAR_ZERO_ARGATEWAY_URL, STATIC_FILES_URL } from "../constants/urls";

export const getARGatewayUrl = (viewerUid: string) => {
  return `${PLICAR_ZERO_ARGATEWAY_URL}/${viewerUid}`;
};
export const getPublicModelViewerScript = (viewerUid: string) => {
  return viewerUid
    ? `<script type='module' src='${getModelViewerScriptSource()}'></script> <plicarzero-viewer viewer-uid=${viewerUid} camera-controls exposure='1' environment-image='neutral' style="touch-action: none;"> </plicarzero-viewer>`
    : ''; // 스크롤 방지용 touch-action:none 속성
};
export const getModelViewerScript = (viewerData: string) => {
  return viewerData
    ? `<script type='module' src='${getModelViewerScriptSource()}'></script> <plicarzero-viewer viewer-data=${viewerData} camera-controls exposure='1' environment-image='neutral' style="touch-action: none;"> </plicarzero-viewer>`
    : ''; // 스크롤 방지용 touch-action:none 속성
};
export const getModelViewerScriptSource = () => {
  return `${STATIC_FILES_URL}/script/plicar-viewer.min.js`;
};
// 스크립트 로드 버튼(구 AR다이렉트 버튼) 복사하기
export type buttonValueTypes = 'primary' | 'secondary' | 'tertiary';
export const getARDirectButtonScript = (viewerUid: string, colorType: buttonValueTypes) =>
  `<script src="${STATIC_FILES_URL}/script/plicarzero-button.min.js"></script><plicarzero-button viewer-uid="${viewerUid}" color-type="${colorType}"></plicarzero-button>`;
  
// ARGateway 경유 버튼(구 AR링크 버튼) 복사하기
export const getARGatewayButtonScript = (viewerUid: string, colorType: buttonValueTypes) =>{
  return `<a href="${PLICAR_ZERO_ARGATEWAY_URL}/${viewerUid}?access_type=AR_Button_Link"><img src="${plicarButtonImgUrl[colorType]}"/></a>`;
}